import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap"
import styles from "./style.module.css"
import { DatePicker } from "rsuite"
import { format } from "date-fns"
import axiosService from "../../utils/axios.service";
import _ from "lodash"
import { Spin, Select, message, Table, Pagination, Input, Button } from "antd"
import StorageService from "../../utils/storage.service";
import "./report.scss"
import role from '../../enums/role.enum';

const DetailQrCode = () => {
    const [dateReport, setDateReport] = useState(new Date())

    const [listStore, setListStore] = useState([])
    const [storeId, setStoreId] = useState({value: StorageService.getItem("currentStore"), label: StorageService.getItem("currentNameStore")})

    const [loading, setLoading] = useState(true);
    const [userAdmin, setUserAdmin] = useState(JSON.parse(StorageService.getItem("user_info")))
    const [limit, setLimit] = useState(10)
    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(0)
    const [list, setList] = useState([])

    const [type, setType] = useState((userAdmin.role == role.LT) ? {value: 1, label: "Cơ Sở"} : (userAdmin.role == role.SALE) ? {value: 2, label: "Sale Online"} : {value: 0, label: "Tất cả"})
    const [listType, setListType] = useState([])
    
    const [numberPhone, setNumberPhone] = useState('')
    const [orderId, setOrderId] = useState('')

    const columns = [
        {
            title: 'Trạng thái',
            dataIndex: 'status_pay_os',
            key: "status_pay_os",
            align: "center",
            render: (data) => {
                return (
                    <div className="value">{data == 1 ? <span className="qr-success">Đã thanh toán</span> : <span className="qr-pending">Chưa xác nhận</span>}</div>
                )
            },
        },
        {
            title: 'Mã đơn hàng',
            key: "order_code",
            align: "center",
            render: (data) => {
                return (
                    <>
                        {data.order.order_code} <br/>
                        {format(new Date(data.created_at), "dd-MM-yyyy HH:mm")}
                    </>
                )
            },
        },
        {
            title: 'Khách hàng',
            dataIndex: 'order',
            key: "customers",
            align: "center",
            render: (data) => {
                return (
                    <div className="value">
                        {data.customers.full_name} {data.customers?.identifier === 1 ? <span className="identifier-customer"><img src="/icons/verified.png" /></span> : ""}<br/>
                        {data.customers.mobile}
                    </div>
                )
            },
        },
        {
            title: 'Số tiền thanh toán',
            key: "amount",
            align: "center",
            render: (data) => {
                return (
                    <>
                        {convertCurrency(data.paid_amount)}
                    </>
                )
            },
        },
        {
            title: 'Mã giao dịch',
            dataIndex: 'transaction_code',
            key: 'transaction_code',
            align: "center",
            render: (data) => {
                return (    
                    <div className="value">{data}</div>
                )
            },
        },
        {
            title: 'STK',
            key: "pay_account_number",
            dataIndex: 'pay_account_number',
            align: "center",
            render: (data) => {
                let dataAccount = data?.split(" - ")
                return (
                    dataAccount?.length > 0 ?
                        <div className="value">
                            {dataAccount[0]}<br/>
                            {dataAccount[2]}<br/>
                            {dataAccount[1]}
                        </div>
                    :null
                )
            },
        },
        {
            title: 'Loại thanh toán',
            key: "status",
            dataIndex: 'status',
            align: "center",
            render: (data) => {
                return (
                    <div className="value">{data == 1 ? "Thanh toán order" : "Trả nợ"}</div>
                )
            },
        },
        {
            title: 'Cơ sở',
            dataIndex: 'store',
            key: "store",
            align: "center",
            render: (data) => {
                return (
                    <div className="value">{data.name_store}</div>
                )
            },
        },
        {
            title: 'Link QR',
            key: "banking_type",
            align: "center",
            render: (data) => {
                return (
                    <div className="value">{data.banking_type == 2 ? <a href={`${process.env.REACT_APP_QR_LINK}${data.uuid}`} target="_blank">Link</a> : ""}</div>
                )
            },
        },
    ];

    function convertCurrency(data) {
        let res = new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(data)
        return res;
    };

    useEffect(async () => {
        fetchData(1, 10)
        getListStore()
        if (userAdmin.role == role.ADMIN) {
            setListType([
                {value: 0, label: "Tất cả"},
                {value: 1, label: "Cơ Sở"},
                {value: 2, label: "Sale Online"}
            ])
        }
        if (userAdmin.role == role.LT) {
            setListType([
                {value: 1, label: "Cơ Sở"},
            ])
        }
        if (userAdmin.role == role.SALE) {
            setListType([
                {value: 2, label: "Sale Online"},
            ])
        }
    }, [])

    const getListStore = async () => {
        let response = await axiosService("api/stores", "GET");
        const selectDataStore = [{value: 0, label: "Tất cả"}]
        if (response.data.code == 200) {
            if (response.data.data !== null) {
                for (const key in response.data.data) {
                    selectDataStore.push({
                        value: response.data.data[key].id,
                        label: response.data.data[key].name_store
                    })
                }

                setListStore(selectDataStore)
            }
        }
    }

    const fetchData = async (current_page = null, current_limit = null) => {
        try {
            let payload = {
                limit: current_limit ?? limit,
                page: current_page ?? page,
                storeId: storeId.value,
                dateReport: dateReport ? format(dateReport, 'yyyy-MM-dd') : null,
                type: type.value,
                numberPhone: numberPhone,
                orderId: orderId
            }
            const res = await axiosService(`api/transaction/get-list-qr-code`, "POST", payload,);
            if (res.data.success) {
                const { data } = res.data
                setList(data.results)
                setTotal(data.pagination.total)
            } else {
                message.error("Có lỗi xảy ra")
            }
        } catch (error) {
            console.error(error)
            message.error("Có lỗi xảy ra")
        }
        setLoading(false)
    }

    const onChangeType = async (value) => {
        setType(value);
    }

    const onChangeStore = (value) => {
        setStoreId(value);
    };

    const onChangeDate = (value) => {
        setDateReport(value);
    };

    const onChangeNumber = (value) => {
        setNumberPhone(value.target.value)
    }

    const onChangeOrderId = (value) => {
        setOrderId(value.target.value)
    }

    const searchData = () => {
        setPage(1)
        setLoading(true)
        fetchData(1, limit)
    }

    const handleChangePage = (page, pageSize) => {
        setPage(page)
        setLimit(pageSize)

        setLoading(true)
        fetchData(page, pageSize)
    }

    return (
        <Spin spinning={loading}>
            <div className={`${styles.backgroundQR} container-fluid report-page report-qrcode`}>
                <Row>
                    <Col lg={12} xl={4}></Col>
                    <Col xs={6} md={4} lg={3} xl={1}>
                        Mã hoá đơn
                        <Input
                            className="w-100"
                            onChange={onChangeOrderId}
                        />
                    </Col>  
                    <Col xs={6} md={4} lg={3} xl={1}>
                        SĐT
                        <Input
                            className="w-100"
                            onChange={onChangeNumber}
                        />
                    </Col>  
                    <Col xs={6} md={4} lg={3} xl={1}>
                        Nguồn QR code
                        <Select
                            className="w-100"
                            labelInValue
                            value={type}
                            defaultValue={type}
                            onChange={onChangeType}
                            options={listType}
                        />
                    </Col>  
                    <Col xs={6} md={4} lg={3} xl={2}>
                        Cơ sở
                        <Select
                            className="w-100"
                            labelInValue
                            value={storeId}
                            defaultValue={storeId}
                            onChange={onChangeStore}
                            options={listStore}
                        />
                    </Col>
                    <Col xs={6} md={4} lg={3} xl={2}>
                        Thời gian
                        <DatePicker
                            value={dateReport}
                            defaultValue={dateReport}
                            oneTap
                            format={"yyyy-MM-dd"}
                            className="w-100"
                            onChange={onChangeDate}
                            size="sm"
                        />
                    </Col>
                    <Col xs={6} md={4} lg={3} xl={1}>
                        <button onClick={searchData} type="button" className="w-100 btn-primary" style={{marginTop: '22px', height: '28px', lineHeight: '28px', padding: '0px', border: 'none', borderRadius: '4px'}}><i className="fa fa-filter"></i> Tìm kiếm</button>
                    </Col>  
                </Row>
                <Row className="pt-2">
                    <Col xs={12}>
                        <div className="d-flex justify-content-end w-100 mt-2">
                            <p>Đang hiển thị <span style={{ color: "blue" }}>{list.length}</span> trên tổng số <span style={{ color: "red" }}>{total}</span> kết quả</p>
                        </div>
                        <Table
                            className="w-100 text-center"
                            pagination={false}
                            columns={columns} dataSource={list} />
                        <div className={`d-flex justify-content-end w-100 mt-4 pt-2 pb-2 mr-5 ${styles.fixedPagination}`}>
                            <Pagination current={page} total={total} pageSize={limit} showSizeChanger showQuickJumper onChange={handleChangePage} />
                        </div>
                    </Col>
                </Row>
            </div>
        </Spin>
    )
}
export default DetailQrCode