import React, { useState, useEffect } from 'react'
import { Table, Dropdown, Button, Checkbox, Modal } from 'rsuite'
import './scss/membercards.scss'
import { memberCardsData } from './fakeData'
import MemberItem from './MemberItem'
import ModalRenewCard from './ModalRenewCard'
import { Link } from 'react-router-dom'
import { axiosGet } from "../../utils/axios.service";
import { DatePicker, Pagination } from 'rsuite';
import { startOfDay, endOfDay, addDays, subDays } from "date-fns";
import Select from "react-select";
import DateRangePicker from "rsuite/DateRangePicker";
import { packageStatus } from './constant/ConstantPackage';
import PackageHistory from './PackageHistory'
import Delete from './Delete'
import Edit from './Edit'
import { formatValue } from 'react-currency-input-field';
import { Spin } from "antd"
import { debounce } from "lodash";
import axiosService from "../../utils/axios.service";
import _ from 'lodash';

var _state = {}
var isLoading = false;
var isLoadStore = false;

async function requestDataStore(userAdmin = null) {
  if (_state.setSelectDataStore === undefined) return ''
  let selectDataStore = []

  if (userAdmin && userAdmin.role == 4) {
    for (const key in userAdmin.stores) {
      selectDataStore.push({
        value: userAdmin.stores[key].id,
        label: userAdmin.stores[key].name_store
      })
    }
  } else {
    let response = await axiosGet("api/stores");
    selectDataStore.push({ value: '0', label: 'Tất cả các chi nhánh' })

    if (response.data.code == 200) {
      if (response.data.data !== null) {
        for (const key in response.data.data) {
          selectDataStore.push({
            value: response.data.data[key].id,
            label: response.data.data[key].name_store
          })
        }
      }
    }
  }

  _state.setSelectDataStore(selectDataStore)
  // isLoadStore = false;
}

async function requestDataPackage(userAdmin = null, currentStore) {
  if (_state.setPackage === undefined) return ''
  isLoading = true;
  _state.setShowLoading(true)
  
  if (userAdmin && userAdmin.role == 4 && _state.param.length == 0) {
    _state.param.push({key: 'store_id', value: currentStore})
  }
  var url = "api/package/?"
  if (_state.param[0]) {
    _state.param.map((item) => {
      url = url + item.key + "=" + item.value + "&"
    })
  }
  let response = await axiosGet(url);
  if (response.data.code == 200) {
    _state.setPackage(response.data.data.data)
    _state.setResponse(response.data.data)
  }
  _state.setShowLoading(false)
  isLoading = false;
}


const MemberCards = () => {
  const [isShowModalRenewCard, setIsShowModalRenewCard] = useState(false)
  const [showHistory, setShowHistory] = useState(false)
  const [showDelete, setShowDelete] = useState(false)
  const [showEdit, setShowEdit] = useState(false)
  const [dataPackageDetail, setDataPackageDetail] = useState({})
  const handleClose = () => setIsShowModalRenewCard(false)
  const [dataPackage, setPackage] = useState({})
  const [dataResponse, setResponse] = useState({})
  const [storeId, setStoreId] = useState({})
  const [activePage, setActivePage] = useState(1);
  const [page, setPage] = useState(1)
  const [param, setParam] = useState([])
  const [paramString, setParamString] = useState([])
  const [selectDataStore, setSelectDataStore] = useState({})
  const [date, setDate] = useState([])
  const [showLoading, setShowLoading] =  useState(true)
  const [userAdmin, setUserAdmin] = useState(JSON.parse(localStorage.getItem("user_info") ?? null))
  const [currentStore, setCurrentStore] = localStorage.getItem("currentStore")

  _state.dataPackage = dataPackage
  _state.setPackage = setPackage
  _state.dataResponse = dataResponse
  _state.setResponse = setResponse
  _state.param = param
  _state.setParam = setParam
  _state.paramString = paramString
  _state.setParamString = setParamString
  _state.selectDataStore = selectDataStore
  _state.setSelectDataStore = setSelectDataStore

  _state.showLoading = showLoading
  _state.setShowLoading = setShowLoading

  useEffect(() => {
    requestDataStore(userAdmin)
    requestDataPackage(userAdmin, currentStore)
  }, []);

  let packages = _state.dataPackage
  if (packages === undefined) return ''

  // if (!isLoadStore) requestDataStore()

  // useEffect(() => {
  //   requestDataStore()
  // },[]);
  // useEffect(() => {
  //   requestDataPackage()
  // },[]);

  const changePage = (event) => {
    setActivePage(event)
    setPage(event)
    handleFilter("page", event)
  }

  const handleFilter = (key, val) => {
    var paramNew = param
    var filter = { key: key, value: val }
    var status = false
    paramNew.map((item) => {
      if (item.key == key) {
        item.value = val
        status = true
      }
      if (item.key == "page" && key != "page") {
        item.value = 1
      }
    })
    if (status == false) {
      paramNew.push(filter)
    }
    if (key !== "page") {
      setActivePage(1)
    }
    setParam(paramNew)
    requestDataPackage()

  }

  const onChangeFilterCardKeyword = debounce((event) => {
    var value = event.target.value
    handleFilter("keyword_card", value)
  }, 700)

  const onChangeFilterCustomer = debounce((event) => {
    var value = event.target.value
    handleFilter("keyword_customer", value)
  }, 700)

  const onChangeStoreId = (event) => {
    var value = event.value
    handleFilter("store_id", value)
  }

  const handleExportPackage = (event) => {
    setShowLoading(true)
    var url = "api/export/package?"
    if (_state.param[0]) {
      _state.param.map((item) => {
        url = url + item.key + "=" + item.value + "&"
      })
    }
    axiosService(url, "GET")
    .then((res) => {
        if (res.data.code == 200) {
            window.location.href = res.data.data.url
            setShowLoading(false)
        }
    }).catch((error) => {
        setShowLoading(false)
    });
  }

  let defaultRangeDate = [new Date(), addDays(new Date(), 1)]
  let ranges = [
    {
      label: "30 ngày trước",
      value: [startOfDay(subDays(new Date(), 30)), endOfDay(new Date())],
    },
    {
      label: "7 ngày trước",
      value: [startOfDay(subDays(new Date(), 7)), endOfDay(new Date())],
    },
    {
      label: "Hôm qua",
      value: [
        startOfDay(addDays(new Date(), -1)),
        endOfDay(addDays(new Date(), -1)),
      ],
    },
    {
      label: "Hôm nay",
      value: [startOfDay(new Date()), endOfDay(new Date())],
    },
    {
      label: "Ngày mai",
      value: [startOfDay(new Date()), endOfDay(addDays(new Date(), 1))],
    },
    {
      label: "3 ngày tới",
      value: [startOfDay(new Date()), endOfDay(addDays(new Date(), 3))],
    },
    {
      label: "7 ngày tới",
      value: [startOfDay(new Date()), endOfDay(addDays(new Date(), 7))],
    },
    {
      label: "30 ngày tới",
      value: [startOfDay(new Date()), endOfDay(addDays(new Date(), 30))],
    },
  ]
  const hiddenPackageHistory = (event) => {
    setShowHistory(false)
  }
  const changeDateRange = (event) => {
    let startDate = ''
    let endDate = ''
    if (event) {
      startDate = [event[0].getFullYear(), ("0" + (event[0].getMonth() + 1)).slice(-2), ("0" + event[0].getDate()).slice(-2)].join('-');
      endDate = [event[1].getFullYear(), ("0" + (event[1].getMonth() + 1)).slice(-2), ("0" + event[1].getDate()).slice(-2)].join('-');
    }
    setDate([startDate, endDate])
    handleFilter("start", startDate)
    handleFilter("end", endDate)
  }

  const onChangePackageStatus = (event) => {
    var value = event.value
    handleFilter("status", value)
  }

  const hiddenShowDelete = (event) => {
    setShowDelete(false)
  }

  const getDefaultStores = () => {
    let defaultChoose = _.find(selectDataStore, function(o) { return o.value == currentStore; });

    return defaultChoose
  }

  return (
    <div className="admin-page orders member-card">
      <div className="page-title">
        <div className="title-page">
          <i className="fa-solid fa-credit-card"></i>
          <span className="ml-1">Danh sách khách hàng dùng thẻ</span>
        </div>
        <div className="header-right">
          <div className="button-cent blue-green-bg">
            <Link to={{ pathname: '/cards' }}>
              <span>
                <i className="fa-solid fa-credit-card"></i>
              </span>
              Các loại thẻ
            </Link>
          </div>
          {/* <div className="button-cent">
            <Link onClick={() => setIsShowModalRenewCard(true)}>
              <span>
                <i className="fa-solid fa-user-plus"></i>
              </span>
              Cấp thẻ mới
            </Link>
          </div> */}
          <div className="button-cent" onClick={handleExportPackage}>
              <span>
                <i className="fa-solid fa-file-excel"></i>
              </span>
              Xuất excel
          </div>
          {userAdmin && userAdmin.role !== 4 ?
            <div className="button-cent">
              <Link to={{ pathname: '/pos' }}>
                <span>
                  <i className="fa-solid fa-file-excel"></i>
                </span>
                Nhập từ excel
              </Link>
            </div>
          : null}
        </div>
      </div>
      <div className="pt-1 mr-5 ml-5 pb-2 search-section row">
        <div className="col-3"></div>
        <div className="col-3">
          <div className="report-summary-block bg-blue">
            <label className="pull-left ng-binding">Số khách hàng dùng thẻ</label>
            <span className="text-white ng-binding">{_state.dataResponse.countCustomerUsing}</span>
          </div>
        </div>
        <div className="col-3">
          <div className="report-summary-block bg-blue">
            <label className="pull-left ng-binding">
              Tổng giá trị trong thẻ tiền
            </label>{' '}
            <span
              className="text-white ng-isolate-scope"
              currency-filter=""
              value="99006000">
              <span className="cash-vnd text-white">
              {_state.dataResponse.totalMonyCard ? formatValue({
                    value: String(_state.dataResponse.totalMonyCard),
                    groupSeparator: ',',
                    decimalSeparator: '.',
                  }): '0'} đ
              </span>
            </span>
          </div>
        </div>
        <div className="col-3">
          <div className="report-summary-block bg-blue">
            <label className="pull-left ng-binding">
              Tổng giá trị trong thẻ lần
            </label>
            <span
              className="text-white ng-isolate-scope"
              currency-filter=""
              value="24334287138.048912">
              <span className="cash-vnd text-white">
                {_state.dataResponse.totalTimesCard ? formatValue({
                    value: String(_state.dataResponse.totalTimesCard),
                    groupSeparator: ',',
                    decimalSeparator: '.',
                  }): '0'} đ
              </span>
            </span>
          </div>
        </div>
      </div>

      <div className="content-wrapper">
        <div className="panel panel-flat max-height height-750">
          <div className="panel-body">
            <div className="row">
              <li className="col-2 filter-item">
                <div className="ng-binding">Tìm kiếm nhanh</div>
                <div>
                  <input
                    onChange={onChangeFilterCardKeyword}
                    className="form-control input-xs ng-pristine ng-untouched ng-valid ng-empty"
                    placeholder="Tìm mã thẻ, tên thẻ"
                    aria-invalid="false"
                  />
                </div>
              </li>
              <li className="col-3 filter-item">
                <div className="ng-binding">Khách hàng</div>
                <div>
                  <div
                    className="customer-block-filter">
                    <input
                      onChange={onChangeFilterCustomer}
                      id="autocomplete-search-sustomer-input"
                      className="form-control input-xs ng-pristine ng-untouched ng-valid ng-empty"
                      placeholder="Tìm khách hàng theo tên, điện thoại hoặc email"
                    />
                    <ul
                      className="suggest autocompletList ng-hide"
                      role="button"
                    >
                      <li
                        className="ng-hide">
                        <span className="text-primary">Đang tìm kiếm....</span>
                      </li>
                      <li>
                        <span className="text-primary">
                          Không tìm thấy dữ liệu phù hợp.
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
              <li className="col-2 filter-item">
                <div className="ng-binding">Chi nhánh</div>
                <>
                  {selectDataStore[0] ? <Select
                    isSearchable={false}
                    className="select"
                    defaultValue={(userAdmin && userAdmin.role != 4) ? selectDataStore[0] : getDefaultStores}
                    options={selectDataStore}
                    onChange={onChangeStoreId}
                  /> : null}
                </>
              </li>
              <li className="col-2 filter-item">
                <div className="ng-binding">Ngày cấp</div>
                <div>
                  <DateRangePicker
                    placement={"bottomEnd"}
                    size="sm"
                    style={{ height: 32, float: "right" }}
                    // defaultValue={defaultRangeDate}
                    format={"dd-MM-yyyy"}
                    ranges={ranges}
                    onChange={changeDateRange}
                  />
                </div>
              </li>
              {/* <li className="col-1 filter-item">
                <div className="ng-binding">Loại thẻ</div>
                <div>
                  <select className="select2 ng-pristine ng-untouched ng-valid ng-isolate-scope ng-empty select2-hidden-accessible">
                    <option value="" selected="selected">
                      Tất cả
                    </option>
                    <option value="MONEY">Thẻ tiền</option>
                    <option value="USING">Thẻ lần</option>
                  </select>
                </div>
              </li> */}
              <li className="col-2 filter-item">
                <div className="ng-binding">Tình trạng</div>
                <div>
                  <Select
                    isSearchable={false}
                    className="select"
                    defaultValue={packageStatus[0]}
                    options={packageStatus}
                    onChange={onChangePackageStatus}
                  />
                </div>
              </li>
            </div>

            {/* <div className="alert-warning text-center m-10"> */}
              {/* Hiện tại đang có
              <span  className="text-bold text-blue-royal-icing">
                <span className="font-16 text-blue-royal-icing ng-binding">16</span>{' '}
                thẻ sắp hết hạn trong 1 tháng
              </span>
              và
              <span  className="text-bold text-blue-royal-icing">
                <span className="font-16 text-blue-royal-icing ng-binding">11</span>{' '}
                thẻ sẽ hết hạn trong 1 tuần tới. Bấm vào để xem.
              </span> */}
            {/* </div> */}

            <div className="table-container-wraper">
             <Spin spinning={showLoading}>
              <div className="table-container">
                <div className="customer">Khách hàng</div>
                <div className="card-info-member">
                  Tên thẻ
                </div>
                <div className="provided-day">Ngày cấp</div>
                <div className="out-date-time">Hết hạn</div>
                <div className="nearest-item-used">Gần nhất</div>
                <div className="used-time">Lần sử dụng</div>
                <div className="money-remain">Số tiền con thẻ</div>
                <div className="status">Tình trạng</div>
                {/* <div className="select-member-checkbox"><Checkbox /></div> */}
                <div className="history-used-th">
                  <div className="history-used">
                    {/* <div className="history-used-title">Gia hạn thẻ</div>
                    <Dropdown title={<></>} placement="bottomEnd" icon={<i className="fa-solid fa-angle-down"></i>} >
                      <Dropdown.Item>
                        <i className="fa-solid fa-pen"></i>
                        <span className="pl-2">Gia hạn thẻ</span>
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <i className="fa-solid fa-lock"></i>
                        <span className="pl-2">Khoá thẻ</span>
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <i className="fa-solid fa-ban"></i>
                        <span className="pl-2">Thu hồi thẻ</span>
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <i className="fa-solid fa-trash-can"></i>
                        <span className="pl-2">Xoá thẻ</span>
                      </Dropdown.Item>
                    </Dropdown> */}
                  </div>
                </div>
              </div>
              <div className="table-item-container">
             
                {packages[0] ?
                  packages.map((item) => (
                    <MemberItem userAdmin={userAdmin} item={item} show={setShowHistory} showDelete={setShowDelete} getDataPackageDetail={setDataPackageDetail} showEdit={setShowEdit} key={item.id} />
                  )) : null}
              </div>
              <div className="dataTables_paginate paging_first_last_numbers" id="DataTables_Table_0_paginate">
                <Pagination
                  prev
                  last
                  next
                  first
                  size="xs"
                  total={_state.dataResponse.total}
                  limit={30}
                  activePage={activePage}
                  maxButtons={5}
                  onChangePage={changePage}
                />
              </div>
              </Spin>
            </div>
          </div>
        </div>
      </div>
      {isShowModalRenewCard && (
        <ModalRenewCard open={isShowModalRenewCard} setOpen={setIsShowModalRenewCard} />
      )}

      {showHistory ?
        <div className="order-popup package-history">
          <div className="popup-cent">
            <div className="content-popup fadeInDown package-popup">
              <PackageHistory
                package={dataPackageDetail}
                show={showHistory}
                setShow={setShowHistory}
              ></PackageHistory>

              <div className="bottom-popup">
                <div className="button-container">
                  <div onClick={hiddenPackageHistory} className="button-cent style3">
                    <span><i className="fas fa-times"></i></span>Đóng
                  </div>
                </div>
              </div>
            </div>
            <div onClick={hiddenPackageHistory} className="overlay"></div>
          </div>
        </div>
        : null}
      {showDelete ?
        <Delete
          show={showDelete}
          close={setShowDelete}
          package={dataPackageDetail}
        ></Delete>

        : null}
       {showEdit ?
        <Edit
          show={showEdit}
          close={setShowEdit}
          package={dataPackageDetail}
          listStore={selectDataStore}
        ></Edit>

        : null}
    </div>
  )
}

export default MemberCards
