import React, { useCallback , useState} from 'react'
import { Button, Checkbox, Dropdown } from 'rsuite'
import './scss/cards.scss'
import { formatValue } from 'react-currency-input-field';
import Delete from "./Delete";
import Edit from "./Edit";


function convertDateTime(event) {
  let date = new Date(event),
    month = date.getMonth() + 1,
    day = date.getDate(),
    hours = date.getHours(),
    minus = date.getMinutes();
  if (day < 10) day = '0' + day;
  if (month < 10) month = '0' + month;
  if (hours < 10) hours = '0' + hours;
  if (minus < 10) minus = '0' + minus;
  let text = day + '-' + month + '-' + date.getFullYear();
  text = text + ' ' + hours + ':' + minus;
  return text
};


const CardItem = ({ userAdmin, item, deletePackage, editPackage,products, listSelectGroup, listGroup }) => {

  const [showDelete, setShowDelete] = useState(false)
  const [showEdit, setShowEdit] = useState(false)
  const handleRenderStatus = useCallback((status) => {
    const cardStatus = {
      1: 'level1',
      2: 'level2',
      3: 'level3',
      4: 'level4'
    }

    return <i className={`fa-solid fa-id-card text-red ${cardStatus[status]}`}></i>
  }, [])

  var clickShowDelete  = (item) => {
    setShowDelete(true)
  }

  var clickShowEdit  = (item) => {
    setShowEdit(true)
  }
  var hidePopupEditService = () => {
    setShowEdit(false)
  };

  var hidePopupDeleteService = () => {
    setShowDelete(false)
  };
  const handleRenderCardType = useCallback((type) => {
    const cardType = {
      1: {
        text: 'Thẻ số lần',
        type: 'card-number'
      },
      2: {
        text: 'Thẻ số tiền',
        type: 'card-money'
      }
    }
    return <div className={cardType[type].type}>{cardType[type].text}</div>
  }, [])
  return (
    <div className="card-item">
      {/* <div className="select-card-checkbox">
        <Checkbox />
      </div> */}
      {item.status && (
        <div className="card-status">
          <div>
            <p>{handleRenderStatus(item.status)}</p>
          </div>
        </div>
      )}
      {item.code && (
        <div className="card-info">
          <p>{item.code}</p>
        </div>
      )}
      {item.product_name && (
        <div className="name">
          <span>{item.product_name}</span>
        </div>
      )}
      {item.meta_object.card_type && (
        <div className="type">{handleRenderCardType(Number(item.meta_object.card_type))}</div>
      )}
      {/* {item.price && ( */}
        <div className="price">
          <span>{item.price ? formatValue({
            value: String(item.price),
            groupSeparator: ',',
            decimalSeparator: '.',
          }) : '0'} đ</span>
          <div>{item.meta_object.status_edit_price == "1" ? '(có thể sửa giá khi tính tiền)' : '' }</div>
        </div>
      {/* )} */}
      {item.meta_object.max_used && <div className="number-used"><span>{Number(item.meta_object.max_used)  < 1000 ? Number(item.meta_object.max_used) : "Không giới hạn"}</span></div>}
        {/* <div className="account"><span>{item.account} đ</span></div> */}
      {item.meta_object.use_time_month && <div className="due-date"><span>{item.meta_object.use_time_month} tháng</span></div>}
      <div className="function">{item.description}</div>
      {userAdmin && userAdmin.role != 4 ?
        <div className="action">
          <Button onClick={clickShowEdit} className="edit-button">Sửa</Button>
          <Button onClick={clickShowDelete} className="delete-button">Xóa</Button>
        </div>
      : null}

      <Delete show={showDelete} detailIdService={item} close={hidePopupDeleteService} deletePackage = {deletePackage} editPackage = {editPackage}></Delete>
     
      <Edit open={showEdit}
            setOpen={hidePopupEditService}
            detailCard={item}
            products={products}
            editPackage = {editPackage}
            listSelectGroup= {listSelectGroup}
            listGroup= {listGroup}
            />
    </div>
  )
}

export default CardItem
