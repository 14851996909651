import React, { useEffect, useState, useLayoutEffect } from "react";
import { Row, Col } from "react-bootstrap"
import Header from "./Header"
import styles from "./style.module.css"
import { Tag } from "rsuite"
import { format, startOfMonth } from "date-fns"
import axiosService from "../../utils/axios.service";
import { Bar } from '@ant-design/plots';
import "./report.scss"
import { Table, Spin, Button } from 'antd';
import async from "async"
import 'antd/dist/antd.min.css'
import StorageService from "../../utils/storage.service";
import Loading from "../customer/Loading";

const TabReport = (props) => {
    const [store, setStore] = useState(
        (JSON.parse(StorageService.getItem("user_info")) && JSON.parse(StorageService.getItem("user_info")).role == 3) ?
            JSON.parse(StorageService.getItem("user_info")).stores[0].id
        : parseInt(StorageService.getItem("currentStore")) )
    
    const [userAdmin, setUserAdmin] = useState(JSON.parse(StorageService.getItem("user_info")))
    const [showloading, setShowloading] = useState(false)

    // useEffect(() => {
    //     setTiltle("Theo nhân viên đặt lịch")
    // }, [])

 
    function convertCurrency(data) {
        console.log(data)
        let res = new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(data)
        return res;
    };

    const columns = [
        {
            title: 'Chỉ số',
            dataIndex: 'name',
            // align: "center",
            // width: 150,
            render: (data) => {
                return (
                    <p className="text1">{data}</p>
                )
            },
        },
        {
            title: 'FB',
            // dataIndex: 'fb',
            align: "center",
            // width: 150,
            render: (data, r) => {
                return (
                    <p className="text4">{r['name'] == 'AOV' ?convertCurrency(r['fb']) :  r['fb'] }</p>
                )
            },
        },
        {
            title: 'IG',
            // dataIndex: 'ig',
            align: "center",
            // width: 150,
            render: (data, r) => {
                return (
                    <p className="text4">{r['name'] == 'AOV' ? convertCurrency(r['ig']) :  r['ig'] }</p>
                )
            },
        },
        {
            title: 'Khác',
            // dataIndex: 'ot',
            align: "center",
            // width: 150,
            render: (data, r) => {
                return (
                    <p className="text4">{r['name'] == 'AOV' ? convertCurrency(r['ot']) :  r['ot'] }</p>
                )
            },
        },
        {
            title: 'Tổng',
            align: "center",
            // width: 150,

            render: (data, r) => {
                return (
                    <p className="text1">{r['name'] == 'AOV' ? convertCurrency(Number(r['totalS'])) :  r['totalS'] }</p>
                )
            },
        },
    ];

    const handelExportUser = () => {
        // if (_state.loadingShowClickItem) return
        setShowloading(true)
      
        var url = `api/mysql/export/detail-user?type=1&user_id=${props.userId}&user_name=${props.userName}&start=${props.startDate}&end=${props.endDate}`
        axiosService(url, "GET")
        .then((res) => {
            if (res.data.code == 200) {
                window.location.href = res.data.data.url
                setShowloading(false)
            }
        }).catch((error) => {
            console.log(error)
            setShowloading(false)
        });
    }
  
    return (
        <>
            <div style={{ backgroundColor: "white" }} className="p-2">
                
                <div className="border-bottom w-100 mb-2">
                    <Row>
                        <Col xs={6} className="p-2">
                            <div>
                                Bảng số liệu chi tiết
                            </div>
                        </Col>
                        {/* <Col xs={6} className="p-2">
                            <Button size="middle" type="primary"
                             onClick={handelExportUser}
                            ><i className="fa-solid fa-file-excel mr-2"></i>Xuất Excel
                            </Button>
                        </Col> */}
                    </Row>
                </div>
                    
                <Table 
                    columns={columns} 
                    dataSource={props.table}
                    pagination={{ pageSize: 8 }}
                    scroll={{ x: 600 }}
                    bordered
                />
            </div>
            <Loading
                show={showloading}
            ></Loading>
        </>
    )
}
export default TabReport