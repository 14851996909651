import React, { useEffect, useState, useLayoutEffect } from "react";
import { Row, Col } from "react-bootstrap"
import Header from "./Header"
import styles from "./style.module.css"
import { Tag } from "rsuite"
import { format, startOfMonth } from "date-fns"
import axiosService from "../../utils/axios.service";
import { Bar } from '@ant-design/plots';
import "./report.scss"
import { Table, Spin } from 'antd';
import async from "async"
import 'antd/dist/antd.min.css'
import StorageService from "../../utils/storage.service";
import PopupViewServices from "./PopupViewServices"

const ReportKtvTvvOffline = () => {
    const [title, setTiltle] = useState("Báo cáo tư vấn & phục vụ offline")
    const [startDate, setStartDate] = useState(format(new Date(startOfMonth(new Date())), "yyyy-MM-dd"))
    const [endDate, setEndDate] = useState(format(new Date(), 'yyyy-MM-dd'))
    const [store, setStore] = useState(
        (JSON.parse(StorageService.getItem("user_info")) && JSON.parse(StorageService.getItem("user_info")).role == 3) ?
            JSON.parse(StorageService.getItem("user_info")).stores[0].id
        : parseInt(StorageService.getItem("currentStore")) )
    const [isExport, setIsExport] = useState(false)
    const [loading, setLoading] = useState(true);
    const [isView, setIsView] = useState(false);
    const [totalComKtv, setTotalComKtv] = useState(0);
    const [dataViewService, setDataViewService] = useState([])
    const [chart, setChart] = useState([])
    const [table, setTable] = useState([])
    const [keyword, setKeyword] = useState([])
    const [totalKtv, setTotalKtv] = useState(0)
    const [totalComTvv, setTotalComTvv] = useState(0)
    const [userAdmin, setUserAdmin] = useState(JSON.parse(StorageService.getItem("user_info")))
    const [totalOrderKtv, setTotalOrderKtv] = useState(0)
    const [totalOrderTvv, setTotalOrderTvv] = useState(0)
    const [aov, setAov] = useState(0)

    
    useEffect(() => {
        setTiltle("Báo cáo tư vấn & phục vụ offline")
    }, [])
    const hanldeRangeDate = (sDate, eDate) => {
        setStartDate(sDate)
        setEndDate(eDate)
    }
    const hanldeStore = (x) => {
        setStore(x)
    }
    const fetchData = async (sD, eD, s) => {
        const { users, data } = await async.parallel({
            users: (cb) => {
                axiosService(`api/reports/store/users/${store}`, "GET").then(rs => {
                    const { data } = rs
                    cb(null, data)
                })
            },
            data: (cb) => {
                axiosService(`api/mysql/report-offline-ktv-lt/all?start=${sD}&end=${eD}&store=${store}&keyword=${keyword}`, "GET").then(rs => {
                    console.log(rs)
                    const { data } = rs
                    cb(null, data)
                })
            }
        })
        if (data.code === 200) {
            setLoading(false)
            const {totalKtv, totalComTvv, dataChart, dataTable, aov, totalOrderKtv, totalOrderTvv } = data.data
            // setChart(dataChart)
            setTable(dataTable)
            setTotalKtv(totalKtv)
            setTotalComTvv(totalComTvv)
            setAov(aov)
            setTotalOrderKtv(totalOrderKtv)
            setTotalOrderTvv(totalOrderTvv)
        }

    }
    useLayoutEffect(() => {
        setLoading(true)
        fetchData(startDate, endDate, store)
    }, [startDate, endDate, store, keyword])

    const config = {
        data: chart,
        isStack: true,
        xField: 'value',
        yField: 'name',
        seriesField: 'type',
        label: false,
        xAxis: {
            label: {
                formatter: (v) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
            },
        },
        tooltip: {
            formatter: (data) => {
                return {
                    name: data.name,
                    value: convertCurrency(data.value)
                };
            },
        }
    };
    function convertCurrency(data) {
        let res = new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(data)
        return res;
    };
    const showPopupViewService = (e) => {
        setIsView(!isView)
        setDataViewService(e)
        let totalCom = 0
        for (let item of e) {
            totalCom += item.total
        }
        setTotalComKtv(totalCom)
    }

    const handleIsView = (e) => {
        setIsView(!isView)
    }
    const columns = [
        {
            title: 'Nhân viên',
            dataIndex: 'users_name',
            width: 150,
            fixed: 'left',
            render: (data) => {
                return (
                    <>
                        <p style={{fontWeight: 'bold'}} >{data}</p>
                    </>
                )
            },
            sorter: (a, b) => a.countOrder.length - b.countOrder.length,
        },
        {
            title: 'Số dịch vụ phục vụ',
            dataIndex: 'services',
            // align: "center",
            width: 400,
            render: (data) => {
                return (
                    data.length > 0 ?
                        <p className="text_service" onClick={() => showPopupViewService(data)}>Xem dịch vụ phục vụ ...</p>
                    : <p className="text_service_none">Không có dịch vụ phục vụ</p>
                )
            },
        },
        {
            title: 'Hoa hồng phục vụ',
            dataIndex: 'total_com_ktv',
            align: "center",
            render: (data) => {
                return (
                    <p className="text1">{convertCurrency(Number(data))}</p>
                )
            },
        },
        {
            title: 'Số đơn hàng',
            dataIndex: 'count_order_ktv',
            align: "center",
            sorter: {
                compare: (a, b) => a.countCustomer - b.countCustomer,
                multiple: 3,
            },
        },
        {
            title: 'Doanh số tư vấn',
            dataIndex: 'total_price_order',
            align: "center",
            render: (data) => {
                return (
                    <p className="text1">{convertCurrency(Number(data))}</p>
                )
            },
        },
        {
            title: 'Số tiền thực thu',
            dataIndex: 'total_paid',
            align: "center",
            render: (data) => {
                return (
                    <p className="text1">{convertCurrency(Number(data))}</p>
                )
            },
        }
    ];
    const hanldeExportData = () => {
        setIsExport(true)
        setTimeout(() => {
            setIsExport(false)
        }, 2000)
    }
    const onChangeKeyword = (e) => {
        setKeyword(e)
    }
    return (
        <>
            <div className={`${styles.background}`}>
                <Header startDate={startDate} endDate={endDate}
                    hanldeRangeDate={hanldeRangeDate} store={store}
                    hanldeStore={hanldeStore}
                    title={title} type={"ktv-tvv"}
                    csvData={table} isExport={isExport}
                    hanldeExportData={hanldeExportData}
                    onChangeKeyword={onChangeKeyword}
                    header={
                        [
                            { label: 'Nhân viên', key: "users_name" },
                            { label: "Hoa hồng phục vụ", key: "total_com_ktv" },
                            { label: "Số tiền thực thu", key: "total_paid" },
                        ]
                    }
                    userAdmin={userAdmin}
                />
                <div className="pl-2 pr-2">
                    <Spin spinning={loading} tip="Đang tải...">
                        <Row>
                            <Col xs={12} md={8}>
                                <Row className="h-100">
                                    <Col xs={6} md={4} className="mb-2">
                                        <Tag color="blue" className="w-100 p-2 h-100">
                                            <span className={styles.textTag3}>Tổng hoa hồng phục vụ: </span>
                                            <div className="d-flex justify-content-between">
                                                <div>
                                                    <span className={styles.textTag}> </span>
                                                    <br />
                                                    <br />
                                                
                                                </div>
                                                <div className="ml-3">
                                                    <span className={styles.textTag}>
                                                        {convertCurrency(totalKtv)}</span>
                                                    <br />
                                                </div>
                                            </div>
                                        </Tag>
                                    </Col>
                                    <Col xs={6} md={4}  className="mb-2">
                                        <Tag color="blue" className="w-100 p-2 h-100">
                                            <span className={styles.textTag3}>Tông tiền tư vấn: </span>
                                            <div className="d-flex justify-content-between">
                                                <div>
                                                    <span className={styles.textTag}> </span>
                                                    <br />
                                                    <br />
                    
                                                </div>
                                                <div className="ml-3">
                                                    <span className={styles.textTag}>
                                                        {convertCurrency(totalComTvv)}</span>
                                                    <br />

                                                </div>
                                            </div>
                                        </Tag>
                                    </Col>
                                    <Col xs={6} md={4}  className="mb-2">
                                        <Tag color="blue" className="w-100 p-2 h-100">
                                            <span className={styles.textTag3}>Tỷ lệ chốt đơn: </span>
                                            <div className="d-flex justify-content-between">
                                                <div>
                                                    <span  className={styles.textTag5}> Số đơn Pv:</span>
                                                    <span  className={styles.textTag5}> Số đơn Tv:</span>
                                                    <span className={styles.textTag5}> Tỷ lê:</span>
                    
                                                </div>
                                                <div className="ml-3">
                                                    <span className={styles.textTag}>
                                                   {totalOrderKtv}</span>
                                                    <span className={styles.textTag}>
                                                    {totalOrderTvv}</span>
                                                    <span className={styles.textTag}>
                                                        {aov} %</span>

                                                </div>
                                            </div>
                                        </Tag>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            {/* <Col xs={4} className="p-2">
                                <div style={{ backgroundColor: "white" }} className="p-2">
                                    <div className="border-bottom w-100 mb-2">
                                        Biểu đồ nhân viên đặt lịch
                                    </div>
                                    <Bar {...config} />
                                </div>
                            </Col> */}
                            <Col xs={12}>
                                <div style={{ backgroundColor: "white" }}>
                                    <div className="border-bottom w-100 p-2">
                                        Bảng số liệu chi tiết
                                    </div>
                                    <Table 
                                        columns={columns} 
                                        dataSource={table}
                                        pagination={{ pageSize: 10 }}
                                        scroll={{ x: 1300 }}
                                        bordered
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Spin>
                </div>
            </div>
            {isView ?
               <>
                <p> jkaskdasdka </p>
                <PopupViewServices
                    show={isView}
                    close={handleIsView}
                    dataViewService={dataViewService}
                    totalComKtv={totalComKtv}
                >
                </PopupViewServices>
                </>
            : null}
        </>
    )
}
export default ReportKtvTvvOffline