export const optionsTimeCard = [
    { value: '0', label: '0 phút' },
    { value: '5', label: '5 phút' },
    { value: '10', label: '10 phút' },
    { value: '15', label: '15 phút' },
    { value: '20', label: '20 phút' },
    { value: '25', label: '25 phút' },
    { value: '30', label: '30 phút' },
    { value: '35', label: '35 phút' },
    { value: '40', label: '40 phút' },
    { value: '45', label: '45 phút' },
    { value: '50', label: '50 phút' },
    { value: '55', label: '55 phút' },
    { value: '60', label: '60 phút' },
    { value: '75', label: '75 phút' },
    { value: '90', label: '90 phút' },
    { value: '100', label: '100 phút' },
    { value: '120', label: '120 phút' },
    { value: '150', label: '150 phút' },
    { value: '180', label: '180 phút' },
];

export const optionsHourLast = [
    { value: 9, label: "9 giờ" },
    { value: 10, label: "10 giờ" },
    { value: 11, label: "11 giờ" },
    { value: 12, label: "12 giờ" },
    { value: 13, label: "13 giờ" },
    { value: 14, label: "14 giờ" },
    { value: 15, label: "15 giờ" },
    { value: 16, label: "16 giờ" },
    { value: 17, label: "17 giờ" },
    { value: 18, label: "18 giờ" },
    { value: 19, label: "19 giờ" },
    { value: 20, label: "20 giờ" },
    { value: 21, label: "21 giờ" },
    { value: 22, label: "22 giờ" },
    { value: 23, label: "23 giờ" },
  ]

export const  optionsMinus = [
    { value: 0, label: "0 phút" },
    { value: 5, label: "5 phút" },
    { value: 10, label: "10 phút" },
    { value: 15, label: "15 phút" },
    { value: 20, label: "20 phút" },
    { value: 25, label: "25 phút" },
    { value: 30, label: "30 phút" },
    { value: 35, label: "35 phút" },
    { value: 40, label: "40 phút" },
    { value: 45, label: "45 phút" },
    { value: 50, label: "50 phút" },
    { value: 55, label: "55 phút" },
  ]