import React, { useState, useEffect } from "react";
import axiosService from "../../utils/axios.service";
import Avatar from 'react-avatar';
import CurrencyInput from "react-currency-input-field";
import ReactTooltip from "react-tooltip";
import Select from "react-select";
import { SnippetsOutlined } from '@ant-design/icons';
import { Modal, Input } from 'antd';
import { customDiscount, selectOptionTypePresenter } from "./constant/PosCustomer";
import { notifications } from "../constant/Notify";
import * as convert from "../constant/Convert.helper";
import PosOrderItemListUser from "./PosOrderItemListUser";
import comboPackagesEnum from "../../enums/comboPackages.enum";
const { TextArea } = Input;
let _ = require('lodash');


export default function PosOrderItemList(props) {
  const _props = props;
  const [statusSearchCustomer, setStatusSearchCustomer] = useState(false);
  const [dataSearchCustomer, setDataSearchCustomer] = useState({});
  const [discount, setDiscount] = useState(0);
  const [priceProps, setPriceProps] = useState(_props.item.price);
  const [noteOrderItem, setNoteOrderItem] = useState('');
  const [selectDefault, setSelectDefault] = useState(1);
  const [dataIntroductoryPerson, setDataIntroductoryPerson] = useState({});
  const [getFullName, setFullName] = useState("");
  const [getMobile, setMobile] = useState("");
  const [getCommission, setCommission] = useState(0);
  const [typePresenter, setTypePresenter] = useState(2);
  const [typeDefaultPresenter, setTypeDefaultPresenter] = useState(1);
  const [validate, setValidate] = useState(true);
  // const [presenterId, setPresenterId] = useState(null)
  const [presenterUserId, setPresenterUserId] = useState(null);
  const [presenterCustomerId, setPresenterCustomerId] = useState(null);
  const [priceDiscount, setPriceDiscount] = useState(0);
  // const [priceDiscountTable, setPriceDiscountTable] = useState(0)
  const [quantityInput, setQuantityInput] = useState(_props.item.quantity);
  const [searchOperators, setSearchOperators] = useState("");
  const [packageMaxUsed, setPackageMaxUsed] = useState(null);
  const [note, setNote] = useState("")
  const [show, setShow] = useState(false)
  const [dataCode, setDataCode] = useState(props.item.package_code ?? props.item.code)


  useEffect(() => {
    defaultValueOrderItem();
  }, [props]);
  useEffect(() => {
    setNote(props.item.note ?? "")
  }, [props])
  let item = _props.item;
  item.discount = item.discount ?? 0;

  item["service"] = item.service ?? []
  item["consultant"] = item.consultant ?? [];

  let services = [];
  if (_props.services && _props.services.length > 0) {
    services = _props.services;
  }

  const defaultValueOrderItem = () => {
    var discountInput = 0;
    var selectDefaultDiscount = 0;
    if (_props.item.discount) {
      discountInput =
        (_props.item.discount * 100) /
        (_props.item.price * _props.item.quantity);
      if (!Number.isInteger(discountInput)) {
        discountInput = 0;
      }
    }
    if (!_props.item.discount) {
      _props.item.discount = 0;
    }

    if (_props.item.type_presenter) {
      selectOptionTypePresenter.map((val, key) => {
        if (_props.item.type_presenter == val.value) {
          setTypeDefaultPresenter(key);
          setTypePresenter(_props.item.type_presenter);
        }
      });
    }

    _props.item.type_presenter == 1 ? setTypePresenter(0) : setTypePresenter(2);
    _props.item.presenter_name ? setFullName(_props.item.presenter_name) : setFullName("");
    _props.item.presenter_user_id ? setPresenterUserId(_props.item.presenter_user_id) : setPresenterUserId(null);
    _props.item.presenter_customer_id ? setPresenterUserId(_props.item.presenter_customer_id) : setPresenterUserId(null);
    _props.item.presenter_mobile ? setMobile(_props.item.presenter_mobile) : setMobile("");
    _props.item.commission_presenter ? setCommission(_props.item.commission_presenter) : setCommission(0);
    _props.item.note ? setNoteOrderItem(_props.item.note) : setNoteOrderItem('');

    (_props.item.max_used && Number(_props.item.max_used) < 100 && Number(_props.item.max_used) > 0) ? setPackageMaxUsed(_props.item.max_used) : setPackageMaxUsed(null)

    customDiscount.map((val, key) => {
      if (val.value == discountInput) {
        selectDefaultDiscount = key;
      }
    });
    setPriceProps(_props.item.price)
    setQuantityInput(_props.item.quantity)
    setSelectDefault(selectDefaultDiscount);
    setDiscount(discountInput);
    setPriceDiscount(_props.item.discount);
  };

  if (item.quantity === 0) return null;
  const handleChangeQuantity = (item) => {
    let itemSelected = _props.itemSelected;
    let keySelected = _.findIndex(itemSelected, function (o) { return o.id_item == _props.item.id_item; });
    itemSelected[keySelected].quantity =
      Number(item.target.value); 
    _props.onUpdateItems(itemSelected);
    setQuantityInput(Number(item.target.value));
  };

  const handleButtonQuantity = (itemChange, quantity, evt) => {
    let itemSelected = _props.itemSelected;

    let keySelected = _.findIndex(itemSelected, function (o) { return o.id_item == itemChange.id_item; });

    let dataCombo = []
    if (itemChange.combo_id) {
      dataCombo = _.filter(itemSelected, function (o) { return o.combo_id == itemChange.combo_id; });
    }

    let code = (itemChange.package_code && itemChange.price == 0) ? itemChange.package_code : itemChange.product_code;
    var service = null;
    if (services.length > 0) {
      services.map((val) => {
        if (code == val.package_code) {
          service = val;
        }
      });
    }
    let count_used = 1;
    if (service) {
      count_used = service.max_used - service.count_used - parseInt(itemSelected[keySelected].quantity) - quantity + 1;
      if (count_used < 0) {
        count_used = 0;
      }
    }

    if (count_used > 0) {
      if (!itemChange.combo_id) {
        itemSelected[keySelected].quantity = parseInt(itemSelected[keySelected].quantity) + quantity;
        if (itemSelected[keySelected] && itemSelected[keySelected].discount > 0) {
          itemSelected[keySelected].discount = (itemSelected[keySelected].discount / (itemSelected[keySelected].quantity - quantity)) * itemSelected[keySelected].quantity;
        }
      }
      itemSelected.map((val, key) => {
        if (itemChange.combo_id && itemChange.combo_id == val.combo_id) {
          itemSelected[key].quantity = parseInt(itemSelected[keySelected].quantity) + quantity;
          // if (itemSelected[key] && itemSelected[key].discount > 0) {
          //   itemSelected[key].discount = (itemSelected[key].discount / (itemSelected[key].quantity - quantity)) * itemSelected[key].quantity;
          // }
        }
      });
      _props.onUpdateItems(itemSelected);
      setQuantityInput(itemSelected[keySelected].quantity);
    }

  };

  const addOrderItem = (data) => {
    let itemSelected = _props.itemSelected

    let dataItem = {
      ...data,
      id: null,
      id_item: null
    };
    itemSelected.push(dataItem);
    _props.onUpdateItems(itemSelected);
  }

  const handleRemoveItem = (itemChange) => {
    let itemSelected = _props.itemSelected;
    let keySelected = _.findIndex(itemSelected, function (o) { return o.id_item == itemChange.id_item; });

    itemSelected[keySelected].quantity = 0;
    itemSelected[keySelected].discount = 0;
    _props.onUpdateItems(itemSelected);
    setQuantityInput(itemSelected[keySelected].quantity);
  };

  const changeDataCommission = (type, data, event) => {
    let itemSelected = _props.itemSelected;
    let keySelected = _.findIndex(itemSelected, function (o) { return o.id_item == props.commission; });
    let newItemSelected = itemSelected[keySelected][type] ?? [];


    if (!event.target.className.includes("active")) {
      if (Object.keys(newItemSelected).length < 5)
        newItemSelected = { ...newItemSelected, [data.id]: data };
    } else {
      Object.entries(itemSelected[keySelected][type]).map((item, key) => {
        if (item[0] == data.id) delete newItemSelected[data.id];
      });
    }

    itemSelected[keySelected][type] = newItemSelected;
    _props.onUpdateItems(itemSelected);
  };

  const selectAllDataCommission = (data) => {
    let itemSelected = _props.itemSelected,
      consultant = data.consultant,
      service = data.service;

    Object.entries(itemSelected).map((item) => {
      itemSelected[item[0]] = {
        ...item[1],
        consultant: consultant,
        service: service,
      };
    });

    _props.onUpdateItems(itemSelected);
    notifications({ type: 'success', mess: "Áp dụng cho mọi đơn hàng thành công" });
    props.handleChangeState("", "commission")
  };

  //Action Popup
  const showAction = (e) => {
    let listEl = document.getElementsByClassName(
      "order-details-dropdown-container"
    );
    if (e.target.classList.contains("active"))
      return e.target.classList.remove("active");

    Object.entries(listEl).map(function (item) {
      item[1].classList.remove("active");
    });
    e.target.classList.add("active");
  };

  const saveDataChange = () => {
    let key = _.findIndex(_props.itemSelected, function (o) { return o.id_item == _props.item.id_item; });
    _props.itemSelected[key] = {
      ..._props.itemSelected[key],
      note: noteOrderItem,
      quantity: quantityInput,
      price: Number(priceProps),
      discount: priceDiscount,
      presenter_name: getFullName,
      presenter_mobile: getMobile,
      commission_presenter: getCommission,
      type_presenter: typePresenter,
      presenter_user_id: presenterUserId,
      presenter_customer_id: presenterCustomerId,
      // max_used: packageMaxUsed
    };

    if (packageMaxUsed) _props.itemSelected[key].max_used = packageMaxUsed
    _props.onUpdateItems(_props.itemSelected);
    props.handleChangeState("", "statusActionItem");
  };

  const searchCustomer = (event) => {
    let keyword = event.target.value;

    var url = "api/customer?keyword=" + keyword;
    if (typePresenter == 1) {
      url = "api/users?keyword=" + keyword;
    }

    axiosService(url, "GET")
      .then((res) => {
        const response = res.data;
        var data = response.data.data;
        if (typePresenter == 1) {
          data = response.data;
        }
        setDataSearchCustomer(data ?? []);
        setStatusSearchCustomer(true);
      })
      .catch((error) => console.log(error));
  };

  const getDetailCustomer = (data) => {
    setDataIntroductoryPerson(data);
    setStatusSearchCustomer(false);
    setMobile(data.mobile);
    setValidate(true);
    var fullName = "";
    if (Number(typePresenter) === 1) {
      fullName = data.name;
      setPresenterCustomerId(null);
      setPresenterUserId(data.id);
    }
    if (Number(typePresenter) === 2) {
      fullName = data.full_name;
      setPresenterUserId(null);
      setPresenterCustomerId(data.id);
    }
    setFullName(fullName);

    if (
      (data.full_name && data.full_name.length == 0) ||
      data.mobile.length == 0
    ) {
      setValidate(false);
    }
    if ((data.name && data.name.length == 0) || data.mobile.length == 0) {
      setValidate(false);
    }
  };

  const handleCustomerDiscount = (event) => {
    if (event.value != "none") {
      setDiscount(event.value);
      setPriceDiscount((event.value * (priceProps * quantityInput)) / 100);
    }
    handleGetSelectOptionDiscount(event.value);
  };

  const handleOnChangeDiscount = (event) => {
    var value = event.target.value;
    if (event.target.value > 100) {
      value = 100;
    }
    handleGetSelectOptionDiscount(value);
    setDiscount(value);
    setPriceDiscount((value * (priceProps * quantityInput)) / 100);
  };

  const handleGetSelectOptionDiscount = (value) => {
    var select = 0;
    customDiscount.map((val, key) => {
      if (val.value === Number(value)) {
        select = key;
      }
    });
    if (select) {
      setSelectDefault(select);
    } else {
      setSelectDefault(0);
    }
  };

  const handleHiddenSearch = (value) => {
    setStatusSearchCustomer(false);
  };

  const handleOnChangeFullName = (event) => {
    setFullName(event.target.value);
  };

  const handleOnChangeMobile = (event) => {
    setMobile(event.target.value);
    setValidate(true);
    if (event.target.value.length == 0) {
      setValidate(false);
    }
  };

  const handleOnChangeSommission = (event) => {
    let dataPrice = event.target.value.replaceAll(",", "");
    setCommission(dataPrice);
  };

  const handleSelectTypePresenter = (event) => {
    setTypePresenter(event.value);
  };

  const handleClear = (event) => {
    setFullName("");
    setMobile("");
    setCommission(0);
    setValidate(true);
  };

  const onChangeDiscountPrice = (event) => {
    var value = event.target.value;
    setDiscount(0);
    setSelectDefault(0);
    if (value > priceProps * quantityInput) {
      value = priceProps * quantityInput;
    }
    setPriceDiscount(value);
  };

  const handleChangeQuantityInputPopup = (event) => {
    let value = event.target.value;
    if (!value) {
      value = 1;
    }
    setQuantityInput(Number(value));
    setPriceDiscount((discount * (priceProps * Number(value))) / 100);
  };

  const handleButtonQuantityPopup = (itemCode, quantity, evt) => {
    var value = Number(quantity + quantityInput);
    if (value < 1) {
      value = 1;
    }
    // let itemSelected = _props.itemSelected;
    var service = null;
    if (services.length > 0) {
      services.map((val, key) => {
        if (itemCode == val.package_code) {
          service = val;
        }
      });
    }
    let count_used = 1;
    if (service) {
      count_used = service.max_used - service.count_used - value + 1;
      if (count_used < 0) {
        count_used = 0;
      }
    }
    if (count_used > 0) {
      setQuantityInput(value);
      setPriceDiscount((discount * (priceProps * Number(value))) / 100);
    }
  };

  const changeDataPricePopup = (event) => {
    let dataPrice = event.target.value.replaceAll(",", "");
    setPriceProps(dataPrice);
    setPriceDiscount((discount * (dataPrice * Number(quantityInput))) / 100);
  };

  const changeDataNotePopup = (event) => {
    setNoteOrderItem(event.target.value);
  };

  const showEditOrderItem = (value, key) => {
    props.handleChangeState(value, key)
    setQuantityInput(item.quantity)
    // setPriceProps(item.price)
    // setNoteOrderItem(item.note)
    // setPriceDiscount((discount * (item.price * Number(quantityInput))) / 100);
  }

  const handlePackageMaxUsed = (event) => {
    setPackageMaxUsed(event.target.value)
  }
  const handleNotePackage = () => {
    setShow(true)
  }
  const handleOk = () => {
    const selectI = _props.itemSelected
    const newData = {
      ...selectI[item.code],
      note: note
    }
    setShow(false)
    selectI[item.code] = newData
    _props.onUpdateItems(selectI);
  }
  const handleCancel = () => {
    setShow(false)
  }
  const handleChangeNote = (e) => {
    setNote(e.target.value)
  }
  return (
    <tr onClick={handleHiddenSearch}>
      <Modal title="Tạo ghi chú cho thẻ" open={show} onOk={handleOk} onCancel={handleCancel}>
        <TextArea rows={4} placeholder="Ghi chú cho thẻ" onChange={handleChangeNote} value={note} />
      </Modal>
      <td className="col-full" style={{ cursor: "pointer", position: "relative" }}>
        <div className="item-info">
          <div
            className="avt-item"
            style={{
              backgroundSize: "cover",
              height: "36px",
              marginRight: "10px",
              width: "36px",
              float: "left",
              border: "1px solid rgb(221, 221, 221)",
              backgroundImage: "url(/images/placeholder/placeholder_img_sqr.jpeg)",
            }}
          >
          </div>
          <div>
            <a>
              <span className="ng-binding">
                {[1,2].includes(props?.typeOrder) ? "Nâng cấp " : (item.new_package) ? "Mua " : (item.package_code) ? "Sử dụng " : null} 
                {item.product_name} {item.is_convert_bhvv == 1 || item.package?.is_convert_bhvv == 1 ? " - CD" : ""}</span>
            </a>
            {item.combo_id ?  
            <p>
              <span style={{color: "red"}}> {item.combo_name}</span> 
            </p>
            : ''} 

          </div>
          <div
            className="text-grey font-11 ng-binding ng-scope"
            ng-if="!item.show_use_price d-flex"
          >
            Giá:{" "}
            <span currency-filter="" className="ng-isolate-scope">
              <span className="cash-vnd">
                {convert.convertCurrency(item.price)}
                {[1,2].includes(props?.typeOrder) && (item?.upgrade_by_package != '' && item?.upgrade_by_package)? " / " + item.max_used + " buổi" : null}
              </span>
            </span>

          </div>
          {item.presenter_name ? <div className="cl-red">Người giới thiệu: {item.presenter_name} - {convert.convertCurrency(getCommission)}  </div> : ""}
        </div>
      </td>
      <td className="col-half">
        <div className="input-group">
          {(item.new_package) ?
            <SnippetsOutlined className="note-package"
              onClick={handleNotePackage}
            />
            : null}
          <span className="input-group-btn">
            {[1,2].includes(props?.typeOrder) ?
              <button className="btn left btn-xs btn-default btn-icon" type="button"></button>
            :    
              <button
                className="btn left btn-xs btn-default btn-icon"
                type="button"
                onClick={handleButtonQuantity.bind(this, item, -1)}
              >
                <i className="fa-solid fa-minus"></i>
              </button>
            }
          </span>
          <input
            readOnly
            className="form-control"
            value={item.quantity}
            onChange={handleChangeQuantity}
          />
          {!item.combo_id ?
          <span className="input-group-btn right daddd">
            {item.new_package ?
              [1,2].includes(props?.typeOrder) ?
                <button className="btn right btn-xs btn-default" type="button"></button>
              : 
                <button
                  className="btn right btn-xs btn-default"
                  type="button"
                  onClick={(item.new_package) ? addOrderItem.bind(this, item) : handleButtonQuantity.bind(this, item, 1)}
                >
                  <i className="fa-solid fa-plus"></i>
                </button>
              : item.package?
                !((item.package.parent_package != null) && (!comboPackagesEnum.includes(item.package.product_id)) || (item.package.parent_package == null) && (comboPackagesEnum.includes(item.package.product_id))) ?
                  <button
                    className="btn right btn-xs btn-default"
                    type="button"
                    onClick={(item.new_package) ? addOrderItem.bind(this, item) : handleButtonQuantity.bind(this, item, 1)}
                  >
                    <i className="fa-solid fa-plus"></i>
                  </button>
                : <button
                    className="btn right btn-xs btn-default"
                    type="button"
                  >
                  </button>
                : !((item.parent_package != null) && (!comboPackagesEnum.includes(item.product_id)) || (item.parent_package == null) && (comboPackagesEnum.includes(item.product_id)) ) ?
                  <button
                    className="btn right btn-xs btn-default"
                    type="button"
                    onClick={(item.new_package) ? addOrderItem.bind(this, item) : handleButtonQuantity.bind(this, item, 1)}
                  >
                    <i className="fa-solid fa-plus"></i>
                  </button> :<button
                    className="btn right btn-xs btn-default"
                    type="button"
                  >
                  </button>}
          </span>
          : 
          <span className="input-group-btn right disable-package2">
              <button className="btn right btn-xs btn-default" type="button"><i className="fa-solid fa-plus"></i></button>
          </span>
          }
        </div>
      </td>
      <td className="text-center has-tooltip-info col-half">
        <div className="require-operator operator_avatar_group ng-scope"
          style={{ cursor: "pointer" }}
          onClick={() => props.handleChangeState(item.id_item, "commission")}
          data-tip data-for={"service-user-" + (props.item.package_code ?? props.item.code)}>
          {Object.entries(item.service).length > 0 ?
            <span className="count service">{Object.entries(item.service).length}</span>
            : null}

          {Object.entries(item.service).length > 0 || Object.entries(item.consultant).length > 0 ?
            <Avatar name={
              Object.entries(item.service).length > 0 ? Object.entries(item.service)[0][1].name :
                Object.entries(item.consultant)[0][1].name
            } size="36" textSizeRatio={1.75} round="50%" />
            : <i className="fas fa-question snake-gift"></i>}

          {Object.entries(item.consultant).length > 0 ?
            <span className="count consultant">{Object.entries(item.consultant).length}</span>
            : null}

          {Object.entries(item.service).length > 0 || Object.entries(item.consultant).length > 0 ?
            <ReactTooltip className="tooltip-user" place="bottom" type="dark" id={"service-user-" + (props.item.package_code ?? props.item.code)}>
              {Object.entries(item.service).length > 0 ?
                <div className="item-user">
                  <label>Nhân viên phục vụ</label>
                  {Object.entries(item.service).map((data, key) => (
                    <p key={key}>- {data[1].name}</p>
                  ))}
                </div>
                : null}

              {Object.entries(item.consultant).length > 0 ?
                <div className="item-user">
                  <label>Nhân viên tư vấn</label>
                  {Object.entries(item.consultant).map((data, key) => (
                    <p key={key}>- {data[1].name}</p>
                  ))}
                </div>
                : null}
            </ReactTooltip>
            : null}
        </div>
      </td>
      <td className="col-half">
        <span className="title hide-desktop">Thành tiền</span>
        <span className="text-bold price-subtotal">
          <span className="cash-vnd text-bold pull-right">
            {convert.convertCurrency(
              item.price * item.quantity - item.discount
            )}
          </span>
          {item.discount > 0 ? (
            <p className="show-total-discount">
              {convert.convertCurrency(item.price * item.quantity)}
            </p>
          ) : (
            ""
          )}
        </span>
      </td>
      <td className="col-half">
          <div
            className="order-details-dropdown-container pull-right btn btn-default dropdown-toggle"
            onClick={showAction}
          >
            {![1,2].includes(props?.typeOrder) ?
              <ul className="dropdown-menu">
                {item.new_package ? <li
                  className="action edit"
                  onClick={() => showEditOrderItem(item.id_item, "statusActionItem")}
                >
                  <i className="fas fa-pen"></i>Sửa
                </li> : item.package ?
                  !((item.package.parent_package != null) && (!comboPackagesEnum.includes(item.package.product_id)) || (item.package.parent_package == null) && (comboPackagesEnum.includes(item.package.product_id))) && item.new_package ?
                    <li
                      className="action edit"
                      onClick={() => showEditOrderItem(item.id_item, "statusActionItem")}
                    >
                      <i className="fas fa-pen"></i>Sửa
                    </li>
                    : ""
                  : !((item.parent_package != null) && (!comboPackagesEnum.includes(item.product_id)) || (item.parent_package == null) && (comboPackagesEnum.includes(item.product_id))) ?
                    <li
                      className="action edit"
                      onClick={() => showEditOrderItem(item.id_item, "statusActionItem")}
                    >
                      <i className="fas fa-pen"></i>Sửa
                    </li> : ""}
                <li
                  className="action remove"
                  onClick={() => handleRemoveItem(item)}
                >
                  <i className="fas fa-trash-alt"></i> Xóa
                </li>
              </ul>
            : null}
          </div>
        

      </td>
      {props.commission == item.id_item ? (
        <td className="selected-operator">
          <div className="content-data">
            <div
              className="close"
              onClick={() => props.handleChangeState("", "commission")}
            >
              ×
            </div>
            <div className="content-operator">
              <div className="head-block">
                <div
                  className="select-all"
                  onClick={() => selectAllDataCommission(item)}
                >
                  Áp dụng lựa chọn cho toàn bộ đơn hàng
                </div>
                <div className="title-block">Tìm kiếm</div>
                <div className="input-search">
                  <input
                    type="text"
                    name="selected-operator"
                    defaultValue={searchOperators}
                    onChange={(e) => setSearchOperators(e.target.value)}
                  />
                </div>
              </div>
              <div className="body-operators">
                <div className="top-title">
                  <div className="top-name d-flex justify-content-between">Nhân viên<div style={{ cursor: "pointer" }} onClick={props.handleSortName}>
                    {props.isAscOperator ? <i className="fa-solid fa-arrow-down-wide-short mr-4"></i> : <i className="fa-solid fa-arrow-up-wide-short mr-4"></i>}
                  </div></div>
                  <div className="top-name">Phục vụ</div>
                  <div className="top-name">Tư vấn</div>
                </div>
                <div className="list-operator">
                  {props.listOperator.map((operator, key) => (
                    <PosOrderItemListUser
                      key={key}
                      operator={operator}
                      changeDataCommission={changeDataCommission}
                      item={item}
                      searchOperators={searchOperators}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div
            className="overlay-user-list"
            onClick={() => props.handleChangeState("", "commission")}
          ></div>
        </td>
      ) : null}
      {props.statusActionItem === item.id_item ? (
        <td className="change-price-item">
          <div className="popup-cent update-cart-item">
            <div className="content-popup fadeInDown">
              <div className="head-popup">
                <div className="title">Chỉnh sửa thông tin đơn hàng</div>
                <div className="close" onClick={() => props.handleChangeState("", "statusActionItem")}>×</div>
              </div>
              <div className="body-popup">
                <div className="order-item-details">
                  <div className="order-item-image"></div>
                  <div className="info">
                    <div className="order-item-name">
                      {item.package_code ? "Sử dụng " : ""} {item.product_name}
                    </div>
                    <div className="order-code">{item.code}</div>
                  </div>
                  <div className="pull-right cash-vnd total-price">
                    <div>
                      {convert.convertCurrency(
                        priceProps * quantityInput - priceDiscount
                      )}
                    </div>
                    <div className="show-discount">
                      {convert.convertCurrency(priceDiscount)}
                    </div>
                  </div>
                </div>
                <div className="list-change">
                  <div className="item-input">
                    <div className="label">Giá</div>
                    <div className="value">
                      <CurrencyInput
                        defaultValue={priceProps}
                        groupSeparator={","}
                        decimalSeparator={"."}
                        disabled={!props.isDeposit}
                        onChange={(e) => changeDataPricePopup(e)}
                      />
                      <div className="currency">₫</div>
                    </div>
                    {_props.item.max_used && Number(_props.item.max_used) < 100 && Number(_props.item.max_used) > 0 && priceProps > 0 ?
                      <div className="set-max-used-package">
                        <div className="label">Số lần sử dụng thẻ</div>
                        <div className="input-group pull-left search-show-user value">
                          <input
                            placeholder="số lần sử dụng"
                            type="number"
                            defaultValue={packageMaxUsed}
                            onChange={handlePackageMaxUsed}
                          />
                        </div>
                      </div>
                      : ""}
                  </div>
                  {!props.isDeposit ?
                    <div>
                      <div className="quantity-edit-item item-input">
                        <div className="label">Số lượng</div>
                        <div className="input-group value  select-quantit">
                          <span className="input-group-btn">
                            <button
                              className="btn left btn-xs btn-default btn-icon"
                              type="button"
                              onClick={handleButtonQuantityPopup.bind(this, item.package_code ?? item.code, -1)}
                            >
                              <i className="fa-solid fa-minus"></i>
                            </button>
                          </span>
                          <input
                            className="form-control"
                            readOnly
                            value={quantityInput}
                          // onChange={handleChangeQuantityInputPopup}
                          />
                          <span className="input-group-btn right">
                            <button
                              className="btn right btn-xs btn-default"
                              type="button"
                              onClick={handleButtonQuantityPopup.bind(this, item.package_code ?? item.code, 1)}
                            >
                              <i className="fa-solid fa-plus"></i>
                            </button>
                          </span>
                        </div>
                      </div>
                      <div className="discount-edit-item item-input" style={{display: "none"}}>
                        <div className="label">Giảm giá</div>
                        <div className="input-group value">
                          <Select
                            styles={customDiscount}
                            isSearchable={false}
                            className="select"
                            defaultValue={customDiscount[selectDefault]}
                            options={customDiscount}
                            onChange={handleCustomerDiscount}
                          />
                        </div>
                      </div>
                      <div className="discount-show item-input" style={{display: "none"}}>
                        <div className="label"></div>
                        <div className="value-show-discount">
                          <div className="input-group pull-left mr-5">
                            <input
                              className="input-discount-percent set-border-right input-discount-show"
                              onChange={handleOnChangeDiscount}
                              defaultValue={discount}
                            />
                            <span className="input-group-addon set-border-left">
                              %
                            </span>
                          </div>
                          <div className="input-group pull-left">
                            <input
                              className="input-discount-money set-border-right input-discount-show"
                              onChange={onChangeDiscountPrice}
                              defaultValue={priceDiscount}
                            />
                            <span className="input-group-addon set-border-left">
                              <span className="ng-binding ng-scope">₫</span>
                            </span>
                          </div>

                          {/* <div className="checkbox-all-item pull-left">
                            <input type="checkbox" className="css-checkbox " />
                            <label className="lable-checkbox" >Áp dụng giảm giá cho tất cả sản phẩm</label>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  :
                    <div className="item-input">
                      <div className="label">Ghi chú</div>
                      <div className="value node-cart-coc">
                        <textarea
                          className="form-control input-xs ng-pristine ng-valid ng-binding ng-empty ng-valid-maxlength ng-touched"
                          defaultValue={noteOrderItem}
                          onChange={(e) => changeDataNotePopup(e)}
                        />
                      </div>
                    </div>
                  }
                </div>
                {!props.isDeposit ?
                  <div className="list-change wraper-introductory-person">
                    <div className="item-input wraper-source-search">
                      <div className="label-source"> Người giới thiêu</div>
                      <div className="value-show-discount source-customer-search">
                        <div className="input-group pull-left mr-5 select-option-type-user">
                          <Select
                            styles={selectOptionTypePresenter}
                            isSearchable={false}
                            className="select"
                            defaultValue={
                              selectOptionTypePresenter[typeDefaultPresenter]
                            }
                            options={selectOptionTypePresenter}
                            onChange={handleSelectTypePresenter}
                          />
                        </div>
                        <div className="input-group pull-left search-show-user">
                          <input
                            placeholder="Tìm người giới thiệu theo tên và số điện thoại"
                            type="text"
                            onChange={searchCustomer}
                          />
                        </div>
                        <div className="refresh-button input-group pull-left">
                          <button
                            onClick={handleClear}
                            className="btn btn-block btn-danger btn-xs ng-binding"
                          >
                            Nhập lại
                          </button>
                        </div>
                        {statusSearchCustomer === true ? (
                          <div className="result-search">
                            {dataSearchCustomer.length === 0 ? (
                              <div className="empty-customer">
                                Không tìm thấy dữ liệu phù hợp
                              </div>
                            ) : (
                              <div className="items-customer">
                                {dataSearchCustomer.map((data, key) => (
                                  <div
                                    className="item"
                                    key={key}
                                    onClick={() => {
                                      getDetailCustomer(data);
                                    }}
                                  >
                                    <div className="avatar">
                                      {data.avata_url ? (
                                        <img src={data.avata_url} alt="" />
                                      ) : (
                                        <Avatar
                                          name={
                                            data.full_name
                                              ? data.full_name
                                              : data.name
                                          }
                                          size="46"
                                          textSizeRatio={1.75}
                                          round="50%"
                                        />
                                      )}
                                    </div>
                                    <div className="info">
                                      <div className="name">
                                        {data.full_name ?
                                          <span>
                                            {data.full_name}
                                            {data?.identifier === 1 ? <span className="identifier-customer"><img src="/icons/verified.png" /></span> : ""}
                                          </span>
                                          : data.name}
                                      </div>
                                      <div className="phone">{data.mobile}</div>
                                      <div className="address">
                                        {data.district} - {data.city}
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        ) : ("")}
                      </div>
                    </div>
                    <div className="item-input wraper-show-data">
                      <div className="label"></div>
                      <div className="value-show-discount">
                        <div className="input-group inpurt-full-name pull-left mr-5">
                          <input className="input-discount-percent height-38 input-discount-show" onChange={handleOnChangeFullName} placeholder="Họ tên" value={getFullName} />
                        </div>
                        <div className="input-group input-mobile pull-left">
                          <input
                            className="input-discount-mobile height-38 input-discount-show"
                            onChange={handleOnChangeMobile}
                            placeholder="Số điện thoại"
                            value={getMobile}
                          />
                        </div>
                        <div className="input-group input-consultant pull-left">
                          <CurrencyInput
                            className="input-discount-money height-38 input-discount-show"
                            defaultValue={getCommission}
                            groupSeparator={","}
                            decimalSeparator={"."}
                            onChange={(e) => handleOnChangeSommission(e)}
                          />
                        </div>
                      </div>
                    </div>
                    {!validate ? <div className="validation-error-label ng-binding ng-scope">Vui lòng điền đầy đủ họ tên và số điện thoại người giới thiệu</div> : ""}
                  </div>
                : null}
              </div>
              <div className="bottom-popup">
                <div className="button-container">
                  <div className="button-cent style3" onClick={() => props.handleChangeState("", 'statusActionItem')}>
                    <span><i className="fas fa-times"></i></span>Đóng
                  </div>
                  <div className="button-cent" onClick={saveDataChange}>
                    <span><i className="fas fa-save"></i></span>Lưu thông tin
                  </div>
                </div>
              </div>
            </div>
            <div className="overlay" onClick={() => props.handleChangeState("", 'statusActionItem')}></div>
          </div>
        </td>
      ) : null}
    </tr>
  );
}
