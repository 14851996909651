import React, { useEffect, useState, useLayoutEffect } from "react";
import { Row, Col } from "react-bootstrap"
import Select from 'react-select'
import {DateRangePicker, SelectPicker, Dropdown, Input, DatePicker } from 'rsuite';
import Header from "../Header";
import styles from "./style.module.css"
import { format, startOfMonth } from "date-fns"
import axiosService from "../../utils/axios.service";
import { Bar } from '@ant-design/plots';
import "./report.scss"
import { Table, Spin } from 'antd';
import async from "async"
import 'antd/dist/antd.min.css'
import StorageService from "../../utils/storage.service";
import {
    optionsCountBooking
  } from '../constant/GeneralData'
import _ from "lodash";

const CustomerCountPackageBooking = () => {
    const [title, setTiltle] = useState("Báo cáo tư vấn Online")
    const [startDate, setStartDate] = useState(format(new Date(), "yyyy-MM-dd"))
    const [endDate, setEndDate] = useState(format(new Date(), 'yyyy-MM-dd'))
    const [store, setStore] = useState(
        (JSON.parse(StorageService.getItem("user_info")) && JSON.parse(StorageService.getItem("user_info")).role == 3) ?
            JSON.parse(StorageService.getItem("user_info")).stores[0].id
        : parseInt(StorageService.getItem("currentStore")) )
    const [isExport, setIsExport] = useState(false)
    const [loading, setLoading] = useState(true);
    const [chart, setChart] = useState([])
    const [table, setTable] = useState([])
    const [status, setStatus] = useState(0)
    const [keyword, setKeyword] = useState('')

    const [userAdmin, setUserAdmin] = useState(JSON.parse(StorageService.getItem("user_info")))
    useEffect(() => {
        setTiltle("Báo cáo tư vấn Online")
    }, [])
    const hanldeRangeDate = (sDate, eDate) => {
        setStartDate(sDate)
        setEndDate(eDate)
    }
    const hanldeStore = (x) => {
        setStore(x)
    }

    const fetchData = async (sD, eD, s) => {
        const { users, data } = await async.parallel({
            users: (cb) => {
                axiosService(`api/reports/store/users/${store}`, "GET").then(rs => {
                    const { data } = rs
                    cb(null, data)
                })
            },
            data: (cb) => {
                axiosService(`api/reports/customer/count/package-booking/${store}?start=${sD}&end=${eD}&store_id=${store}&keyword=${keyword}&status=${status}`, "GET").then(rs => {
                    const { data } = rs
                    cb(null, data)
                })
            }
        })
        if (data.code === 200) {
            setLoading(false)
            const { dataChart, dataTable } = data.data
            setChart(dataChart)
            setTable(dataTable)
        }

    }
    useLayoutEffect(() => {
        setLoading(true)
        fetchData(startDate, endDate, store)
    }, [startDate, endDate, store, keyword, status])

    const config = {
        data: chart,
        isStack: true,
        xField: 'value',
        yField: 'name',
        seriesField: 'type',
        label: false,
        xAxis: {
            label: {
                formatter: (v) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
            },
        },
        tooltip: {
            formatter: (data) => {
                return {
                    name: data.name,
                    value: convertCurrency(data.value)
                };
            },
        }
    };
    function convertCurrency(data) {
        let res = new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(data)
        return res;
    };
    const columns = [
        {
            title: 'Khách hàng',
            dataIndex: 'customer_name',
            render: (data) => {
                return (
                    <>
                        <p>{data}</p>
                    </>
                )
            },
            sorter: (a, b) => a.name.length - b.name.length,
        },
        {
            title: 'Số điện thoại',
            dataIndex: 'customer_mobile',
            align: "center",
            sorter: {
                compare: (a, b) => a.countBooking - b.countBooking,
                multiple: 3,
            },
        },
        {
            title: 'Thời gian đặt lịch',
            dataIndex: 'book_date',
            align: "center",
            render: (data) => {
                return (
                    <p className="text4">{data}</p>
                )
            },
        },
        {
            title: 'Triệt',
            dataIndex: 'count_triet',
            align: "center",
            render: (data) => {
                return (
                    <p className="text1">{data}</p>
                )
            },
        },
        {
            title: 'Da',
            dataIndex: 'count_da',
            align: "center",
            render: (data) => {
                return (
                    <p className="text1">{data}</p>
                )
            },
        },
        {
            title: 'Khác',
            dataIndex: 'count_ot',
            align: "center",
            render: (data) => {
                return (
                    <p className="text1">{data}</p>
                )
            },
        },
    ];
    const hanldeExportData = () => {
        setIsExport(true)
        setTimeout(() => {
            setIsExport(false)
        }, 2000)
    }
    const onChangeKeyword = _.debounce((e) => {
        if (e.length > 2 || e.length == 0) {
            setKeyword(e)
        }
    }, 1000)
    const onChangeDate = (e) => {
        if (e) {
            const eDate = format(e[1], 'yyyy-MM-dd')
            const sDate = format(e[0], 'yyyy-MM-dd')
            hanldeRangeDate(sDate, eDate)
        }
    }

    const changeStatusBooking = (e) => {
        setStatus(e.value)
    }
    return (
        <>
            <Header/>
            <div className={`p-2 ${styles.background}`}>
            <div className="search-report pl-2 pr-2">
                <Row>
                    <Col sm={3} xs={6}>
                        <Input data={keyword} placeholder="Tìm kiếm theo keyword" className="w-200" onChange={onChangeKeyword} cleanable={false} />
                    </Col>
                    <Col sm={3} xs={6}>
                        <Select className="select" onChange={changeStatusBooking}  isSearchable={false} defaultValue={optionsCountBooking[0]} options={optionsCountBooking} />
                    </Col>
                    <Col sm={5} xs={6} className="mb-2">
                        <DateRangePicker
                            className="w-100"
                            placement={"bottomEnd"}
                            format="yyyy-MM-dd"
                            onChange={onChangeDate}
                            showOneCalendar={true}
                            // ranges={type != 'detail-ktv-tvv' ? ranges : rangesNv}
                            cleanable={false}
                            defaultValue={[new Date(startDate), new Date(endDate)]}
                        />
                    </Col>

                </Row>
            </div>
            
            <Spin spinning={loading} tip="Đang tải...">
                <Row className="p-2">
                    {/* <Col xs={4} className="p-2">
                        <div style={{ backgroundColor: "white" }} className="p-2">
                            <div className="border-bottom w-100 mb-2">
                                Biểu đồ nhân viên đặt lịch
                            </div>
                            <Bar {...config} />
                        </div>
                    </Col> */}
                    <Col xs={12} className="p-2">
                        <div style={{ backgroundColor: "white" }} className="p-2">
                            <div className="border-bottom w-100 mb-2">
                                Bảng số liệu chi tiết
                            </div>
                            <Table 
                                columns={columns}
                                dataSource={table}
                                pagination={{ pageSize: 10 }} 
                                scroll={{ x: 1300 }}
                                bordered
                            />
                        </div>
                    </Col>
                </Row>
            </Spin>
        </div>

        </>
    )
}
export default CustomerCountPackageBooking