import _ from 'lodash';
import { Row, Col } from "react-bootstrap"
import { Input, Switch, DatePicker, Divider, Select, Button, InputNumber, message, Modal, Spin, Checkbox } from 'antd';
import { useCallback, useEffect, useMemo, useState } from "react";
import { PlusCircleOutlined, CloseOutlined } from "@ant-design/icons"
import moment from "moment"
import { UniqueCodeEnum, ActivesEnum, IfEnum, ThenEnum, ApplyEnum, typConditions, IsForVoucher, typeVoucher, onlyEnum , optionGroupUser, optionRankCustomer } from "../../enums/voucher.enum";
import axiosService from "../../utils/axios.service";
import { create } from "../../redux/reducers/voucher"
import { useDispatch } from "react-redux"
import { Link } from "react-router-dom";
import async from "async"
import warningMessages from "./warning.messages";
import {optionLevel} from "../constant/GeneralData"
import { useTranslation } from 'react-i18next';
const { Option } = Select;

export default function AddNewVoucher({ styles, handleCancelAdd, handleCloseAdd, voucher, updateVoucher }) {
    const dispatch = useDispatch()
    const { t } = useTranslation();
    const [ifC, setIf] = useState(IfEnum.ALL)
    const [thenC, setThen] = useState(ThenEnum.TRUE)
    const [action, setAction] = useState("")
    const [valueAction, setValueAction] = useState(0)
    const [conditions, setConditions] = useState([{
        key: 1,
        select: 0,
        value: null,
        isDone: false,
        count_product: 0,
    }])
    const [preSelect, setPreSelect] = useState({})
    const [active, setActive] = useState(ActivesEnum.isActive)
    const [voucherCode, setVoucherCode] = useState("")
    const [des, setDes] = useState("")
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")
    const [ruleName, setRuleName] = useState("")
    const [isUniqueCode, setIsUniqueCode] = useState(UniqueCodeEnum.isNotUnique)
    const [isDisableCode, setIsDisableCode] = useState(false)
    const [isActiveCustomer, setIsActiveCustomer] = useState(0)
    const [selectRankCustomer, setSelectRankCustomer] = useState([1,2,3,4])

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [services, setServices] = useState([])
    const [isDisableSave, setIsDisableSave] = useState(false)
    const [categories, setCategories] = useState([])
    const [maxAmount, setMaxAmount] = useState(0)
    const [stores, setStores] = useState([])
    const [users, setUsers] = useState([])
    const [selectGroupUser, setSelectGroupUser] = useState([1,2,3,4,5,6])
    const [selectStores, setSelectStores] = useState([])
    const [customers, setCustomers] = useState([])
    const [selectCustomers, setSelectCustomers] = useState([])
    const [isForVoucher, setIsForVoucher] = useState(IsForVoucher.YES)
    const [packages, setPackages] = useState([])
    const [selectPackages, setSelectPackages] = useState([])
    const [month, setMonth] = useState(127)
    const [number, setNumber] = useState(10)
    const [onlyOne, setOnlyOne] = useState(onlyEnum.FALSE)
    const [maxUsed, setVoucherMaxUsed] = useState(99999)
    const [limitPaidOrder, setLimitPaidOrder] = useState(0)
    const [level, setLevel] =  useState(null)
    const [defaultVal, setDefaultVal] = useState(null)
    const [groupVoucher, setGroupVoucher] = useState([])
    const [selectGroupVoucher, setSelectGroupVoucher] = useState([])

    useEffect(async () => {
        try {
            const { getService, getCategories, getStores, getProducts, getGroupVoucher } = await async.parallel({
                getService: (cb) => {
                    axiosService(`api/vouchers/create/find`, "GET").then(res => {
                        const { data } = res
                        cb(null, data)
                    })
                },
                getCategories: (cb) => {
                    axiosService("api/category/for-voucher").then(rs => {
                        const { data } = rs
                        cb(null, data)
                    })
                },
                getStores: (cb) => {
                    axiosService("api/stores").then(rs => {
                        const { data } = rs
                        cb(null, data)
                    })
                },
                getProducts: (cb) => {
                    axiosService("api/product/?type=2").then(rs => {
                        const { data } = rs
                        cb(null, data)
                    })
                },

                getGroupVoucher: (cb) => {
                    axiosService("api/category_voucher").then(rs => {
                        const { data } = rs
                        cb(null, data)
                    })
                },
             
            })
            if (getCategories.code == 200 && getService.code == 200 && getStores.code === 200 && getProducts.code === 200 && getGroupVoucher.code === 200) {
                setCategories([...getCategories.data])
                setStores([...getStores.data])
                setServices([...getService.data])
                setPackages(getProducts.data.data)
                setGroupVoucher(getGroupVoucher.data)
            } else {
                message.error(t("error.occurred"))
            }
        } catch (error) {
            console.error(error)
            message.error(t("error.occurred"))
        }
    }, [])

    useEffect(async () => {
        try {
            let body = {
                ranking: selectRankCustomer,
                isMember: isActiveCustomer
            }
            let rs = await axiosService(`api/customer/by/rule`, "POST", body)
            if (rs.data.code == 200) {
                setCustomers(rs.data.data)
            } else {
                message.error(t("error.occurred"))
            }
        } catch (error) {
            console.error(error)
            message.error(t("error.occurred"))
        }
    }, [selectRankCustomer, isActiveCustomer])

    useEffect(() => {
        if (voucher) {
            const mapConditions = voucher.conditions.map((x, i) => {
                let object = {
                    key: i + 1,
                    isDone: true,
                    select: x.type,
                    count_product: x.count_product ?? 0
                }
                if ([typConditions.ORDERS_CONTAIN, typConditions.ORDERS_NOT_CONTAIN, typConditions.CATEGORIES, typConditions.COMBO, typConditions.NOT_CATEGORIES].includes(x.type)) {
                    const list = x.value.split(',')
                    const covertIntLList = list.map(y => parseInt(y))
                    object = {
                        ...object,
                        value: covertIntLList,
                    }
                } else {
                    object = {
                        ...object,
                        value: parseInt(x.value),
                    }
                }
                return object
            })
            setVoucherCode(voucher.code)
            setDes(voucher.des)
            setActive(voucher.active)
            setOnlyOne(voucher.is_only_one)
            setVoucherMaxUsed(voucher.max_used)
            setStartDate(moment(voucher.start_date).format("YYYY-MM-DD"))
            setEndDate(moment(voucher.end_date).format("YYYY-MM-DD"))
            setConditions([...mapConditions])
            setIf(voucher.if_condition)
            setThen(voucher.then_condition)
            setIsForVoucher(voucher.is_for_voucher)
            setAction(voucher.apply)
            setValueAction(voucher.amount)
            setMonth(voucher.month)
            setRuleName(voucher.rule_name)
            setIsUniqueCode(voucher.unique_code)
            setMaxAmount(voucher.max_amount)
            setIsActiveCustomer(voucher.is_active_customer)
            setLimitPaidOrder(voucher.limit_paid_order)

            if (voucher.level > 0) {
                setLevel(voucher.level)
               
            }
            
            if ((voucher.apply === ApplyEnum.PRODUCT ||  voucher.apply === ApplyEnum.PRODUCT_PERCENT || ApplyEnum.PRODUCT_PRICE) && voucher.packages && voucher.packages.length > 0) {
                setSelectPackages(voucher.packages.split(",").map(x => +x))
            }
            if (voucher.group_users) {
                setSelectGroupUser(voucher.group_users.split(",").map(x => {
                    return +x
                }))
            }

            if (voucher.customer_using) {
                setSelectCustomers(voucher.customer_using.split(",").map(x => {
                    return +x
                }))
            }

            if (voucher.category_vouchers_id) {
                setSelectGroupVoucher(voucher.category_vouchers_id)
            }

            if (voucher.customer_rank) {
                setSelectRankCustomer(voucher.customer_rank.split(",").map(x => {
                    return +x
                }))
            }

            setSelectStores(voucher.stores.split(",").map(x => {
                return +x
            }))
            if (voucher.unique_code === UniqueCodeEnum.isUnique) {
                setIsDisableCode(true)
            }
        } else {
            handleChooseAllStores(stores.data)
        }
    }, [voucher, stores])

    const handleChangeVoucherCode = (x) => {
        setVoucherCode(x.target.value)
    }
    const handleChangeDes = (x) => {
        setDes(x.target.value)
    }
    const onChange = (checked) => {
        const value = checked ? ActivesEnum.isActive : ActivesEnum.isUnActive;
        setActive(value)
    };

    const onChangeActiveCustomer = (checked) => {
        const value = checked ? 1 : 0;
        setIsActiveCustomer(value)
    };

    const onChangeOnlyOne = (checked) => {
        const value = checked ? onlyEnum.TRUE : onlyEnum.FALSE;
        setOnlyOne(value)
    };

    const onChangeMaxUsed = (x) => {
        if (!x.target.value) {
            x.target.value = null
        }
        setVoucherMaxUsed(x.target.value)
    };

    const onChangeLimitPaidOrder = (x) => {
        if (!x.target.value) {
            x.target.value = 0
        }
        setLimitPaidOrder(x.target.value)
    }

    const onChangeUniqueCode = (x) => {
        if (x) {
            setVoucherCode("")
            setIsDisableCode(true)
            setIsUniqueCode(UniqueCodeEnum.isUnique)
        } else {
            setIsDisableCode(false)
            setIsUniqueCode(UniqueCodeEnum.isNotUnique)
        }
    }
    const handleChangeAction = (x) => {
        if (x == ApplyEnum.PRICE) {
            setMaxAmount(0)
        }
        setAction(x)
    }
    const changeValueAction = (x) => {
        const value = x ? x : 0
        setValueAction(value)
    }
    const handleChangeCon = (x) => {
        const findIndex = conditions.findIndex(x => preSelect.key == x.key)
        conditions[findIndex].select = x
        setConditions([...conditions])
    }
    const handleClickCon = async (x) => {
        setPreSelect(x)
    }
    const handleAdd = (y) => {
        const findIndex = conditions.findIndex(x => y == x.key)
        conditions[findIndex].isDone = true
        conditions.push({
            key: y + 1,
            select: 0,
            value: null,
            isDone: false,
            count_product: 0,
        })
        setConditions([...conditions])
    }
    const handleChangeValueCon = (e, y, type) => {
        const findIndex = conditions.findIndex(x => y.key == x.key)
        if (e > 0) {
            conditions[findIndex].isDone = true
        }
        if (type) {
            conditions[findIndex].value = e
        } else {
            conditions[findIndex].count_product = e
        }
        
        setConditions([...conditions])
    }
    const handleCancel = (y) => {
        if (conditions.length == 1) {
            setConditions([{
                key: 1,
                select: 0,
                value: null,
                isDone: false,
                count_product: 0,
            }])
        } else {
            const findIndex = conditions.findIndex(x => y == x.key)
            conditions.splice(findIndex, 1)
            setConditions([...conditions])
        }
    }
    const onChangeValue = (e, y) => {
        const findIndex = conditions.findIndex(x => y.key == x.key)
        if (e > 0) {
            conditions[findIndex].isDone = true
        }
        conditions[findIndex].value = e
        setConditions([...conditions])
    }
    const onChangeStartDate = (x, y) => {
        setStartDate(y)
    }
    const onChangeEndDate = (x, y) => {
        setEndDate(y)
    }
    const handleChangeIf = (x) => {
        setIf(x)
    }
    const handleChangeThen = (x) => {
        setThen(x)
    }
    const resetFields = () => {
        setVoucherCode("")
        setDes("")
        setActive(ActivesEnum.isActive)
        setStartDate("")
        setEndDate("")
        setConditions([{
            key: 1,
            select: 0,
            value: null,
            isDone: false,
            count_product: 0
        }])
        setIf(IfEnum.ALL)
        setThen(ThenEnum.TRUE)
        setAction("")
        setValueAction("")
        setRuleName("")
        setIsUniqueCode(UniqueCodeEnum.isNotUnique)
    }
    const handleReset = () => {
        showModal()
    }
    const handleBack = () => {
        if (!voucher) {
            handleCancelAdd()
            resetFields()
        }
    }

    const validateRequireField = useMemo(() => {
        return !(ruleName.length <= 0 || (voucherCode.length <= 0 && isUniqueCode == UniqueCodeEnum.isNotUnique) || selectStores.length <= 0 || action == "" || startDate == "" || endDate == "" || selectGroupUser.length <= 0 )
    }, [ruleName, voucherCode, isUniqueCode, selectStores, action, valueAction, startDate, endDate, selectGroupUser])

    const validateDate = useMemo(() => {
        return new Date(startDate).getTime() <= new Date(endDate).getTime()
    }, [startDate, endDate])


    const validateLevel = useMemo(() => {
        return level? true :false
    }, [level])

    const validateCondition = useMemo(() => {
        const filterConditionsValid = conditions.filter(x => {
            return x.isDone
        })
        return filterConditionsValid.length > 0
    }, [conditions])

    const checkSubmit = useCallback(() => {
        const objectValidate = {
            validateRequireField,
            validateDate,
            validateCondition,
            level
        }
        for (const key in objectValidate) {
            if (!objectValidate[key]) message.warning(warningMessages[key])
        }

        return validateCondition && validateDate && validateRequireField && level
    }, [validateCondition, validateDate, validateRequireField, level])

    const handleOke = async () => {
        setIsDisableSave(true)
        try {
            if (checkSubmit()) {
                let newConditions = [];
                conditions.map(x => {
                    let newValue = x.value
                    if(x.value[0] && x.value[0] != undefined) {
                        newValue = x.value.sort((a, b) => a - b)
                    }
                    newConditions.push({...x, value: newValue})
                })
                const filter = newConditions.filter(x => {                    
                    return x.isDone
                })

                const newCondition = filter.map(x => {
                    return {
                        value: x.value.toString(),
                        select: x.select,
                        count_product: x.count_product ?? 0
                    }
                })

                let payload = {
                    code: voucherCode,
                    rule_name: ruleName,
                    unique_code: isUniqueCode,
                    des,
                    active: active,
                    is_active_customer: isActiveCustomer,
                    type: typeVoucher.VOUCHER,
                    start_date: startDate,
                    end_date: endDate,
                    max_used: maxUsed,
                    level: level ? level : 0,
                    apply: action,
                    amount: valueAction,
                    if_condition: ifC,
                    then_condition: thenC,
                    conditions: newCondition,
                    is_for_voucher: isForVoucher,
                    max_amount: maxAmount,
                    is_only_one: onlyOne,
                    limit_paid_order: limitPaidOrder,
                    stores: selectStores.toString(),
                    group_users: selectGroupUser.toString(),
                    customer_rank: selectRankCustomer.toString(),
                    customer_using: selectCustomers.toString(),
                    category_vouchers_id: selectGroupVoucher,
                    is_rule_money_cart: 1

                }
                if (ApplyEnum.PRODUCT == action) {
                    payload = {
                        ...payload,
                        max_amount: 0,
                        amount: 0,
                        packages: selectPackages.toString(),
                        month: month
                    }
                }

                if (ApplyEnum.PRODUCT_PERCENT == action || ApplyEnum.PRODUCT_PRICE == action) {
                    payload = {
                        ...payload,
                        amount: valueAction,
                        packages: selectPackages.toString(),
                    }
                }
                if (filter.length === 1 && filter[0].select === typConditions.COMBO) {
                    payload = {
                        ...payload,
                        type: typeVoucher.COMBO
                    }
                }

                if (conditions[0].select == typConditions.SETUP_MONEY_CART) {
                    payload.is_rule_money_cart = 2
                }

                if (voucher) {
                    const res = await axiosService(`api/vouchers/${voucher.id}`, "PUT", payload)
                    const { data } = res
                    if (data.code == 200) {
                        updateVoucher(data.data)
                        message.success("success.update")
                    } else if (data.code === 404) {
                        message.error(data.message)
                    } else {
                        message.error(t("error.update"))
                    }
                } else {
                    if (isUniqueCode === 1) {
                        payload = {
                            ...payload,
                            number: 1
                        }
                    } else {
                        payload = {
                            ...payload,
                            number: number
                        }
                    }
                    dispatch(create(payload)).then(rs => {
                        const { payload } = rs
                        if (payload.code == 200) {
                            resetFields()
                            handleCloseAdd()
                            message.success(t("success.add") + " " + payload.data.length)
                        } else if (payload.code === 404) {
                            message.error(payload.message)
                        } else {
                            message.error(t("error.add"))
                        }
                    })
                }
            }
        } catch (error) {
            console.error(error)
            message.error(t("error.occurred"))
        }
        setIsDisableSave(false)
    }

    const onChangeLevel = (e) => {
        setLevel(e)
    }

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        if (voucher) {
            window.location.reload()
        } else {
            handleCancelModal()
            resetFields()
            setTimeout(() => {
                message.success(t("success.refresh"))
                window.scrollTo(0, 0)
            }, 2000)
        }
    };

    const handleCancelModal = () => {
        setIsModalOpen(false)
    };
    const handleChooseAllStores = (data) => {
        if (!data) data = stores
        const mapStores = data.map(x => {
            return x.id
        })
        setSelectStores([...mapStores])
    }
    const countCondition = useMemo(() => {
        const filter = conditions.filter(x => {
            return x.select != typConditions.COMBO && x.isDone
        })
        return filter.length
    }, [conditions])

    // const countConditionByCat = useMemo(() => {
    //     const filter = conditions.filter(x => {
    //         return x.select != typConditions.CATEGORIES && x.isDone
    //     })
    //     return filter.length
    // }, [conditions])
    return (
        <>
            {isModalOpen && <Modal title={t("reset.confirm")} open={isModalOpen} onOk={handleOk} onCancel={handleCancelModal}>
                <p>{t("reset.title")} 1111</p>
            </Modal>}
            <div>
                <div className="w-100 mb-4">
                    <h5>{t("rule.info")} </h5>
                </div>
                <Col xs={{ span: 12 }} xl={{ span: 10, offset: 1}}>
                    <Row>
                        <div className={`${styles.widthLabel}`}>
                            <span>{t("rule.name")} </span>
                            <span style={{ color: "red" }}>*</span>
                        </div>
                        <div className={`${styles.widthValue}`}><Input status={ruleName.length > 0 ? null : "error"} onChange={(e) => setRuleName(e.target.value)} value={ruleName} /></div>
                        
                    </Row>
                    <Row className="mt-4">
                        <div className={`${styles.widthLabel}`}>
                            <span>{t("voucher.code")}</span>
                            <span style={{ color: "red" }}>*</span>
                        </div>
                        <div className={`${styles.widthValue}`}>
                            <Input status={(voucherCode.length <= 0 && !isDisableCode) ? "error" : null} disabled={isDisableCode} onChange={handleChangeVoucherCode} value={voucherCode} />
                        </div>
                    </Row>
                    <Row className="mt-4">
                        <div className={`${styles.widthLabel}`}>
                        </div>
                        <div className={`${styles.widthValue}`}>
                            <Switch checked={isUniqueCode == 1 ? false : true} className="ml-0" onChange={onChangeUniqueCode} />
                            <span className="ml-3">{t("use.unique.code")}</span>
                        </div>
                    </Row>
                    {
                        (isUniqueCode === 2 && !voucher) &&
                        <Row className="d-flex mt-4">
                            <div className={`${styles.widthLabel}`}>
                                <span>{t("Số lượng")}</span>
                                <span style={{ color: "red" }}></span>
                            </div>
                            <div className={`${styles.widthValue}`}>
                                <InputNumber
                                    style={{ width: "100%" }}
                                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                    onChange={(e) => {
                                        if (e && e >= 1) {
                                            setNumber(e)
                                        }
                                    }}
                                    value={(number && number > 0) ? number : 10}
                                />
                            </div>
                        </Row>
                    }
                    <Row className="mt-4">
                        <div className={`${styles.widthLabel}`}>
                            <span>{t("des")}</span>
                        </div>
                        <div className={`${styles.widthValue}`}><Input onChange={handleChangeDes} value={des} /></div>
                    </Row>
                    <Row className="mt-4">
                        <div className={`${styles.widthLabel}`}>
                            <span>{t("status")}</span>
                            <span style={{ color: "red" }}>*</span>
                        </div>
                        <div className={`${styles.widthValue}`}><Switch checked={active == 2 ? true : false} className="ml-0" onChange={onChange} /></div>
                    </Row>
                    <Row className="mt-4">
                        <div className={`${styles.widthLabel}`}>
                            <span>{t("only.one")}</span>
                        </div>
                        <div className={`${styles.widthValue}`}><Switch checked={onlyOne == onlyEnum.TRUE ? true : false} className="ml-0" onChange={onChangeOnlyOne} /></div>
                    </Row>
                    <Row className="mt-4">
                        <div className={`${styles.widthLabel}`}>
                            <span>{t("max.used")}</span>
                        </div>
                        <div className={`${styles.widthValue}`}>
                            <Input onChange={onChangeMaxUsed} value={maxUsed} placeholder="0 là vô hạn"/>
                        </div>
                        
                    </Row>
                    <Row className="mt-4">
                        <div className={`${styles.widthLabel}`}>
                            <span>{t("voucher.level")}</span>
                            <span style={{ color: "red" }}>*</span>
                        </div>
                        <div className={`${styles.widthValue}`}>
                            <Select
                                className={`${styles.w50}`}
                                optionFilterProp="children"
                                placeholder={t("voucher.level")}
                                options={optionLevel}
                                defaultValue={{}}
                                value={level}
                                onChange={onChangeLevel}
                            />
                        </div>
                    </Row>
                    <Row className="mt-4">
                        <div className={`${styles.widthLabel}`}>
                            <span>{t("limit.paid.order")}</span>
                        </div>
                        <div className={`${styles.widthValue}`}><Input onChange={onChangeLimitPaidOrder}  value={limitPaidOrder} placeholder="%"/></div>
                    </Row>
                    <Row className="mt-4">
                        <div className={`${styles.widthLabel}`}>
                            <span>{t("start.date")}</span>
                            <span style={{ color: "red" }}>*</span>
                        </div>
                        <div className={`${styles.widthValue}`}><DatePicker onChange={onChangeStartDate} value={startDate === "" ? "" : moment(startDate)} className={`${styles.w50}`} /></div>
                    </Row>
                    <Row className="mt-4">
                        <div className={`${styles.widthLabel}`}>
                            <span>{t("end.date")}</span>
                            <span style={{ color: "red" }}>*</span>
                        </div>
                        <div className={`${styles.widthValue}`}><DatePicker onChange={onChangeEndDate} value={endDate === "" ? "" : moment(endDate)} className={`${styles.w50}`} /></div>
                    </Row>
                    <Row className="mt-4">
                        <div className={`${styles.widthLabel}`}>
                            <span>{t("stores")}</span>
                            <span style={{ color: "red" }}>*</span>
                        </div>
                        <div className={`${styles.widthValue}`}>
                            <Select
                                className={`${styles.w50} mr-2`}
                                showSearch
                                optionFilterProp="children"
                                placeholder={t("choose.store")}
                                status={selectStores.length > 0 ? null : 'error'}
                                onChange={(e) => {
                                    setSelectStores(e)
                                }}
                                value={selectStores}
                                mode="multiple"
                            >
                                {
                                    stores.map(x => {
                                        return (
                                            <Option key={x.id} value={x.id} >{`${x.name_store}`}</Option>
                                        )
                                    })
                                }
                            </Select>
                            <Button type="primary" onClick={() => {
                                handleChooseAllStores()
                            }}>{t("select.all")}</Button>
                        </div>
                    </Row>
                    <Row className="mt-4">
                        <div className={`${styles.widthLabel}`}>
                            <span>{t("groupUser")}</span>
                            <span style={{ color: "red" }}>*</span>
                        </div>
                        <div className={`${styles.widthValue}`}>
                            <Select
                                className={`${styles.w50} mr-2`}
                                showSearch
                                optionFilterProp="children"
                                placeholder={t("choose.group")}
                                status={selectGroupUser.length > 0 ? null : 'error'}
                                onChange={(e) => {
                                    setSelectGroupUser(e)
                                }}
                                value={selectGroupUser}
                                mode="multiple"
                            >
                                {
                                    optionGroupUser.map(x => {
                                        return (
                                            <Option key={x.id} value={x.id} >{`${x.name}`}</Option>
                                        )
                                    })
                                }
                            </Select>
                            <Button type="primary" onClick={() => {
                                setSelectGroupUser([1,2,3,4,5,6])
                            }}>{t("select.all")}</Button>
                            </div>
                        
                    </Row>
                    <Row className="mt-4">
                        <div className={`${styles.widthLabel}`}>
                            <span>{t("custmerRank")}</span>
                        </div>
                        <div className={`${styles.widthValue}`}>
                            <Select
                                className={`${styles.w50} mr-2`}
                                showSearch
                                optionFilterProp="children"
                                placeholder={t("choose.rank")}
                                status={selectRankCustomer.length > 0 ? null : 'error'}
                                onChange={(e) => {
                                    setSelectRankCustomer(e)
                                }}
                                value={selectRankCustomer}
                                mode="multiple"
                            >
                                {
                                    optionRankCustomer.map(x => {
                                        return (
                                            <Option key={x.id} value={x.id} >{`${x.name}`}</Option>
                                        )
                                    })
                                }
                            </Select>
                            <Button type="primary" onClick={() => {
                                setSelectRankCustomer([1,2,3,4])
                            }}>{t("select.all")}</Button>
                        </div>
                        
                    </Row>
                    <Row className="mt-4">
                        <div className={`${styles.widthLabel}`}>
                            <span>{t("isActiveCustomer")}</span>
                        </div>
                        <div className={`${styles.widthValue}`}><Switch checked={isActiveCustomer == 1 ? true : false} className="ml-0" onChange={onChangeActiveCustomer} /></div>
                        
                    </Row>
                    <Row className="mt-4">
                        <div className={`${styles.widthLabel}`}>
                            <span>{t("customer.sing")}</span>
                        </div>
                        <div className={`${styles.widthValue}`}>
                            <Select
                                className={`${styles.w50} mr-2`}
                                showSearch
                                optionFilterProp="children"
                                placeholder={t("choose.customer")}
                                onChange={(e) => {
                                    setSelectCustomers(e)
                                }}
                                value={selectCustomers}
                                mode="multiple"
                            >
                                {
                                    customers.map(x => {
                                        return (
                                            <Option key={x.id} value={x.id} >{`${x.full_name}`} - {`${x.mobile}`}</Option>
                                        )
                                    })
                                }
                            </Select>
                            <Button type="primary" onClick={() => {
                                setSelectCustomers([])
                            }}>{t("select.all")}</Button>
                        </div>
                    </Row>
                    <Row className="mt-4">
                        <div className={`${styles.widthLabel}`}>
                            <span>{t("categoryVoucher")}</span>
                        </div>
                        <div className={`${styles.widthValue}`}>
                            <Select
                                className={`${styles.w50} mr-2`}
                                showSearch
                                optionFilterProp="children"
                                placeholder={t("choose.customer")}
                                onChange={(e) => {
                                    setSelectGroupVoucher(e)
                                }}
                                value={selectGroupVoucher}
                                // mode="multiple"
                            >
                                {
                                    groupVoucher.map(x => {
                                        return (
                                            <Option key={x.id} value={x.id} >{`${x.group_name}`}</Option>
                                        )
                                    })
                                }
                            </Select>
                        </div>
                    </Row>
                </Col>
            </div>
            <Divider />
            <div>
                <div className="w-100 mb-4">
                    <h5>{t("conditions")}</h5>
                    <p>{t("condition.title")}</p>
                </div>
                <div className="d-flex w-100">
                    <span className="mr-4">If</span>
                    <Select
                        value={ifC}
                        onChange={handleChangeIf}
                    >
                        <Option value={IfEnum.ALL}>ALL</Option>
                        <Option value={IfEnum.ANY}>ANY</Option>
                    </Select>
                    <span className="mr-4 ml-4">{t("then")}</span>
                    <Select
                        value={thenC}
                        onChange={handleChangeThen}
                    >
                        <Option value={ThenEnum.TRUE}>TRUE</Option>
                        <Option value={ThenEnum.FALSE}>FALSE</Option>
                    </Select>
                </div>
                <div className={`${styles.w50} mt-4`}>
                    {conditions.map((x, i) => {
                        return (
                            <div className="d-flex mb-3 w-100" key={i}>
                                {x.select > 0 ? 
                                <>
                                    {
                                        [typConditions.LESS_TOTAL_ORDER, typConditions.THAN_TOTAL_ORDER, typConditions.SETUP_MONEY_CART].includes(x.select) ?
                                            <>
                                                <span style={{ width: "40%" }}>{x.select == typConditions.THAN_TOTAL_ORDER ? t("condition.value.greater") :
                                                  x.select == typConditions.SETUP_MONEY_CART ? t("condition.setup.money.cart") : 
                                                t("condition.value.less")} </span>
                                                <InputNumber
                                                    style={{ width: "60%" }}
                                                    value={x.value}
                                                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                                    onChange={(e) => { onChangeValue(e, x) }}
                                                />
                                            </>
                                        : ""}
                                    {
                                        ![typConditions.ORDERS_CONTAIN, typConditions.ORDERS_NOT_CONTAIN, typConditions.COMBO].includes(x.select) ? ""
                                            : <>
                                                <span style={{ width: "66%" }}>
                                                    {x.select == typConditions.ORDERS_CONTAIN && t("condition.contain")}
                                                    {x.select == typConditions.ORDERS_NOT_CONTAIN && t("condition.not.contain")}
                                                    {x.select == typConditions.COMBO && t("condition.combo")}
                                                </span>
                                                <Select
                                                    className="w-100"
                                                    showSearch
                                                    optionFilterProp="children"
                                                    placeholder={t("choose.product")}
                                                    onChange={(e) => {
                                                        handleChangeValueCon(e, x, true)
                                                    }}
                                                    value={x.value || []}
                                                    mode="multiple"
                                                >
                                                    {
                                                        services.map(x => {
                                                            return (
                                                                <Option key={x.id} value={x.id} >{`${x.code}-${x.product_name}`}</Option>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                                {x.select === typConditions.COMBO &&
                                                    <Checkbox checked={isForVoucher == 0 ? true : false} style={{
                                                        position: "absolute", marginLeft: "52%"
                                                    }} onChange={(e) => {
                                                        const val = e.target.checked ? 0 : 1
                                                        setIsForVoucher(val)
                                                    }}>{t("apply.voucher")}</Checkbox>
                                                }
                                            </>
                                    }
                                    { 
                                        [typConditions.CATEGORIES].includes(x.select) ?
                                            <div style={{width: "100%"}}>
                                                <div style={{display: "flex"}}>
                                                    <span style={{ width: "66%" }}>
                                                        {t("condition.cate")}
                                                    </span>
                                                    <Select
                                                        className="w-100"
                                                        showSearch
                                                        optionFilterProp="children"
                                                        placeholder={t("choose.cate")}
                                                        onChange={(e) => {
                                                            handleChangeValueCon(e, x, true)
                                                        }}
                                                        value={x.value || []}
                                                        mode="multiple"
                                                    >
                                                        {
                                                            categories.map(x => {
                                                                return (
                                                                    <Option key={x.id} value={x.id} >{x.name}</Option>
                                                                )
                                                            })
                                                        }
                                                    </Select>
                                                </div>
                                                <div style={{display: "flex", marginTop: "15px"}}>
                                                        <span style={{ width: "66%" }}>
                                                            Số lượng tối thiểu
                                                        </span>
                                        
                                                        <InputNumber
                                                            style={{ width: "100%" }}
                                                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                                            onChange={(e) => { handleChangeValueCon(e, x, false) }}
                                                            value={x.count_product}
                                                        />
                                                </div>
                                            </div>
                                        : 
                                            ""
                                    }

                                    { 
                                        [typConditions.NOT_CATEGORIES].includes(x.select) ?
                                            <div style={{width: "100%"}}>
                                                <div style={{display: "flex"}}>
                                                    <span style={{ width: "66%" }}>
                                                        {t("condition.not.cate")}
                                                    </span>
                                                    <Select
                                                        className="w-100"
                                                        showSearch
                                                        optionFilterProp="children"
                                                        placeholder={t("choose.cate")}
                                                        onChange={(e) => {
                                                            handleChangeValueCon(e, x, true)
                                                        }}
                                                        value={x.value || []}
                                                        mode="multiple"
                                                    >
                                                        {
                                                            categories.map(x => {
                                                                return (
                                                                    <Option key={x.id} value={x.id} >{x.name}</Option>
                                                                )
                                                            })
                                                        }
                                                    </Select>
                                                </div>
                                                <div style={{display: "flex", marginTop: "15px"}}>
                                                        <span style={{ width: "66%" }}>
                                                            Số lượng tối thiểu
                                                        </span>
                                        
                                                        <InputNumber
                                                            style={{ width: "100%" }}
                                                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                                            onChange={(e) => { handleChangeValueCon(e, x, false) }}
                                                            value={x.count_product}
                                                        />
                                                </div>
                                            </div>
                                        : 
                                            ""
                                    }


                                </> : <Select
                                    className="w-100"
                                    placeholder={t("choose.condition")}
                                    onChange={handleChangeCon}
                                    onDropdownVisibleChange={() => { handleClickCon(x) }}
                                >
                                    <Option value={typConditions.THAN_TOTAL_ORDER} >{t("condition.value.greater")}</Option>
                                    <Option value={typConditions.LESS_TOTAL_ORDER}>{t("condition.value.less")}</Option>
                                    <Option value={typConditions.ORDERS_CONTAIN}>{t("condition.contain")} [SKU]</Option>
                                    <Option value={typConditions.ORDERS_NOT_CONTAIN}>{t("condition.not.contain")} [SKU]</Option>
                                    <Option value={typConditions.CATEGORIES}>{t("condition.cate")}</Option>
                                    <Option value={typConditions.NOT_CATEGORIES}>{t("condition.not.cate")}</Option>
                                    
                                    {countCondition == 0 &&
                                        <Option value={typConditions.COMBO}>{t("condition.combo")}</Option>
                                    }
                                    <Option value={typConditions.SETUP_MONEY_CART} >{t("condition.setup.money.cart")}</Option>
                                </Select>
                                }

                                {x.isDone &&
                                    <div className="d-flex">
                                        {
                                            x.select !== typConditions.COMBO && <PlusCircleOutlined className="ml-3 mt-1"
                                                style={{ color: "green", fontSize: "18px", cursor: "pointer" }}
                                                onClick={() => { handleAdd(x.key) }}
                                            />
                                        }
                                        <CloseOutlined className="ml-3 mt-1"
                                            style={{ color: "red", fontSize: "18px", cursor: "pointer" }}
                                            onClick={() => { handleCancel(x.key) }}
                                        />
                                    </div>
                                }
                            </div>
                        )
                    })}
                </div>
            </div>
            <Divider />
            <div>
                <div className="w-100 mb-4">
                    <h5>{t("actions")}</h5>
                    <Col xs={{ span: 12 }} xl={{ span: 10, offset: 1}}>
                        <Row className="mt-4">
                            <div className={`${styles.widthLabel}`}>
                                <span>{t("apply")}</span>
                                <span style={{ color: "red" }}>*</span>
                            </div>
                            <div className={`${styles.widthValue}`}>
                                <Select
                                    onChange={handleChangeAction}
                                    className="w-100"
                                    value={action === 0 ? "" : action}
                                >
                                    <Option value={ApplyEnum.PERCENT}>{t("percent")}</Option>
                                    <Option value={ApplyEnum.PRICE}>{t("fix.amount")}</Option>
                                    <Option value={ApplyEnum.PRODUCT}>{t("product")}</Option>
                                    <Option value={ApplyEnum.PRODUCT_PERCENT}>{t("discount.product.percent")}</Option>
                                    <Option value={ApplyEnum.PRODUCT_PRICE}>{t("discount.product.price")}</Option>
                                </Select>
                            </div>
                        </Row>
                        {
                            action === ApplyEnum.PRODUCT ?
                                <>
                                    <Row className="mt-4">
                                        <div className={`${styles.widthLabel}`}>
                                            <span>{t("product")} </span>
                                            <span style={{ color: "red" }}>*</span>
                                        </div>
                                        <div className={`${styles.widthValue}`}>
                                            <Select
                                                className="w-100 ml-4"
                                                showSearch
                                                optionFilterProp="children"
                                                placeholder={t("choose.product")}
                                                status={selectPackages.length > 0 ? null : 'error'}
                                                onChange={(e) => {
                                                    setSelectPackages(e)
                                                }}
                                                value={selectPackages}
                                                mode="multiple"
                                            >
                                                {
                                                    packages.map(x => {
                                                        return (
                                                            <Option key={x.id} value={x.id} >{`${x.code}-${x.product_name}`}</Option>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </div>
                                    </Row>
                                    <Row className="mt-4">
                                        <div className={`${styles.widthLabel}`}>
                                            <span>{t("expired")}({t("month")})</span>
                                            <span style={{ color: "red" }}>*</span>
                                        </div>
                                        <div className={`${styles.widthValue}`}>
                                            <InputNumber
                                                style={{ width: "100%" }}
                                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                                onChange={(e) => {
                                                    setMonth(e)
                                                }}
                                                value={month}
                                            />
                                        </div>
                                    </Row>
                                </>
                                : <>
                                    <Row className="mt-4">
                                        <div className={`${styles.widthLabel}`}>
                                            <span> Discount Amount</span>
                                            <span style={{ color: "red" }}>*</span>
                                        </div>
                                        <div className={`${styles.widthValue}`}>
                                            <InputNumber
                                                style={{ width: "100%" }}
                                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                                onChange={changeValueAction}
                                                value={valueAction == 0 ? "" : valueAction}
                                            />
                                        </div>
                                    </Row>
                                    {action === ApplyEnum.PRODUCT_PRICE || action === ApplyEnum.PRODUCT_PERCENT ?
                                        <Row className="mt-4">
                                            <div className={`${styles.widthLabel}`}>
                                                <span>{t("product.discount")} </span>
                                                <span style={{ color: "red" }}>*</span>
                                            </div>
                                            <div className={`${styles.widthValue}`}>
                                                <Select
                                                    className="w-100 ml-4"
                                                    showSearch
                                                    optionFilterProp="children"
                                                    placeholder={t("choose.product")}
                                                    status={selectPackages.length > 0 ? null : 'error'}
                                                    onChange={(e) => {
                                                        setSelectPackages(e)
                                                    }}
                                                    value={selectPackages}
                                                    mode="multiple"
                                                >
                                                    {
                                                        packages.map(x => {
                                                            return (
                                                                <Option key={x.id} value={x.id} >{`${x.code}-${x.product_name}`}</Option>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </div>
                                        </Row>
                                    : null
                                    }
                                    {
                                        action === ApplyEnum.PERCENT &&
                                        <Row className="mt-4">
                                            <div className={`${styles.widthLabel}`}>
                                                <span>Max amount</span>
                                            </div>
                                            <div className={`${styles.widthValue}`}>
                                                <InputNumber
                                                    style={{ width: "100%" }}
                                                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                                    onChange={(e) => {
                                                        const value = e ? e : 0
                                                        setMaxAmount(value)
                                                    }}
                                                    value={maxAmount == 0 ? "" : maxAmount}
                                                />
                                            </div>
                                        </Row>
                                    }
                                </>
                        }
                    </Col>
                </div>
            </div>
            <Divider />
            <div className={`${styles.fixDiv} p-2`}>
                <div className="d-flex justify-content-end w-100">
                    {
                        voucher ?
                            <Link to={{ pathname: "/vouchers" }}>
                                <Button type="link" style={{ color: "black" }}>
                                    <i className="fa-solid fa-arrow-left mr-1"></i>{t("back")}
                                </Button>
                            </Link>
                            : <Button type="link" style={{ color: "black" }} onClick={handleBack}>
                                <i className="fa-solid fa-arrow-left mr-1"></i>{t("back")}
                            </Button>
                    }
                    <Button type="link" style={{ color: "black" }} onClick={handleReset}>
                        {t("reset")}
                    </Button>
                    <div className={`${styles.saveButton} ${isDisableSave ? styles.disable : ""}`} onClick={handleOke}>
                        {t("save")}
                    </div>
                </div>
            </div>
        </>
    )
}