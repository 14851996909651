import React, { useRef, useState } from "react";
import axiosService from "../../utils/axios.service";
import moment from "moment"
import Select from 'react-select';
import DatePicker from 'rsuite/DatePicker';
import { customStylesOption, customStylesMultiSelect } from '../constant/GeneralData'
import { message, Button, Checkbox } from "antd"
import { CheckOutlined } from "@ant-design/icons"
import * as convert from "../constant/Convert.helper"
import * as generalData from "../constant/GeneralData"
import Avatar from 'react-avatar';
import _ from 'lodash';
import location from "../../context/vietnam.json";
import { optionsDays, optionsMonths } from "../customer/constant/CustomerBirthday";
import { customStylesBirthday } from '../customer/constant/CustomerBirthday'
import comboPackagesEnum from "../../enums/comboPackages.enum";
import PackageFullBody from "./PackageFullBody";
import { idCategory } from './constant/BookingConstant'
import { notifications } from "../constant/Notify";
import PopupSelectAvatarCustomer from '../pos/PopupSelectAvatarCustomer';
import AvatarCustomer from '../pos/AvatarCustomer';
import { ViewImgCustomer } from "../customer/ViewImgCustomer"

function formatGroupLabel(data) {
  return (
    <div className="custom-head">
      <span className="custom-label">{data.label}</span>
      {/* <span className="custom-count">{data.options.length}</span> */}
    </div>
  );
}

class Edit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      code : props.dataEditBooking.book_code,
      status: props.dataEditBooking.status,
      book_status: props.dataEditBooking.book_status,
      source_from: props.dataEditBooking.source_from,
      gender: props.dataEditBooking.customers.gender,
      full_name: props.dataEditBooking.customers.full_name,
      day: props.dataEditBooking.customers.day_birthday,
      month: props.dataEditBooking.customers.month_birthday,
      year: props.dataEditBooking.customers.year_birthday,
      mobile: props.dataEditBooking.customers.mobile,
      zalo_account: props.dataEditBooking.customers.zalo_account,
      city: props.dataEditBooking.customers.city,
      district: props.dataEditBooking.customers.district,
      address: props.dataEditBooking.customers.address,
      book_hour: convert.getHour(props.dataEditBooking.book_date),
      book_minute: convert.getMinute(props.dataEditBooking.book_date),
      book_date: props.dataEditBooking.book_date,
      total_intend_time: props.dataEditBooking.intend_time,
      avata_url: props.dataEditBooking.customers.avata_url,
      description: props.dataEditBooking.description,
      description_internal: props.dataEditBooking.description_internal,
      stores: props.dataEditBooking.stores.id,
      booking_item: props.dataEditBooking.booking_item,
      booking_type: props.dataEditBooking.booking_type,
      oldCustomer: {},
      editOldCustomer: false,
      userAdmin: JSON.parse(localStorage.getItem("user_info")),
      newDataService: props.dataServiceByCustomer,
      countCustomer: props.dataEditBooking.booking_item.length,
      keyCity: null,
      keyDistrict: null,
      dataDistrict: [],
      fullBodies: [],
      item_full_body: [],
      assign_for: props.dataEditBooking.assign_for,
      typeBooking: props.dataEditBooking.booking_type,
      voucher_code:  props.dataEditBooking.voucher_code,
      voucher_id: props.dataEditBooking.voucher_id,
      voucher_name: props.dataEditBooking.voucher_name,
      isAddVoucher: props.dataEditBooking.voucher_code ? true : false,
      listAvatarCustomer: 0,
      selectAvatar: null,
      addressStore: props.dataEditBooking.stores,
      isCopied: false,
      openTypeAvatar: false,
      showImgCustomer: false,
      identifier: props.dataEditBooking.customers.identifier
    };
  }

  selectDistrictRef = null;

  onChangeBookStatus = (event) => {
    if (this.props.dataEditBooking.book_status != 5 && this.props.dataEditBooking.order_status === 1) {
      this.setState({
        book_status: event,
      });
    }
  }
  onChangeSource = (event) => {
    if (this.props.dataEditBooking.book_status != 5 && this.props.dataEditBooking.order_status === 1) {
      this.setState({
        source_from: event
      });
    }
  }
  onChangeGender = (event) => {
    this.setState({
      gender: event.value
    });
  };
  onChangeName = (event) => {
    this.setState({
      full_name: event.target.value
    });
  };
  onChangeMobile = (event) => {
    this.setState({
      mobile: event.target.value
    });
  };
  onChangeZaloAccount = (event) => {
    this.setState({
      zalo_account: event.target.value
    });
  };
  onChangeCity = (event) => {
    this.setState({
      city: event.label,
      dataDistrict: event.districts,
      district: ''
    });
    this.selectDistrictRef.clearValue();
  };
  onChangeDistrict = (event) => {
    if (event) this.setState({ district: event.label });
  };
  onChangeAddress = (event) => {
    this.setState({
      address: event.target.value,
    });
  };
  onChangeHourBooking = (event) => {
    let book_hour = ("0" + event.value).slice(-2),
      book_minute = ("0" + this.state.book_minute).slice(-2),
      book_date = convert.convertDate(this.state.book_date) + " " + [book_hour, book_minute].join(":");
    this.updatePackages(book_date)
    this.setState({
      book_hour: event.value,
    });
  }
  onChangeMinuteBooking = (event) => {
    let book_hour = ("0" + this.state.book_hour).slice(-2),
      book_minute = ("0" + event.value).slice(-2),
      book_date = convert.convertDate(this.state.book_date) + " " + [book_hour, book_minute].join(":");
    this.updatePackages(book_date)
    this.setState({
      book_minute: event.value
    });
  }
  onChangeDateBooking = (event) => {
    let book_hour = ("0" + this.state.book_hour).slice(-2),
      book_minute = ("0" + this.state.book_minute).slice(-2),
      book_date = convert.convertDate(event) + " " + [book_hour, book_minute].join(":");
    this.updatePackages(book_date)
    this.setState({
      book_date: event
    });
  }
  onChangeEstimatedTime = (event) => {
    this.setState({
      total_intend_time: (event.target.validity.valid) ? event.target.value : this.state.total_intend_time
    });
  }
  onChangeNote = (event) => {
    this.setState({
      description: event.target.value
    });
  }

  onChangeNoteInternal = (event) => {
    this.setState({
      description_internal: event.target.value
    });
  }
  
  onChangeStore = (event) => {
    this.setState({
      stores: event.value
    });
  }
  onChangeService = (key, data) => {
    let booking_item = this.state.booking_item,
        item_full_body = this.state.item_full_body,
        new_service = [],
        timeWax = 0,
        timeUsed = 0;

    data.map(function (item) {
      let timePerService = Number(item.time) ?? 0;
      let category_id = item.category_id ?? item.product?.category_id;

      if (category_id == 5) {
        timeWax += timePerService;
      } else if (category_id == 2) {
        timeUsed += (timePerService >= 90) ? timePerService - 15 : timePerService;
      } else {
        timeUsed += timePerService
      }
      if (item.package_code) {
        new_service.push({
          id: item.value,
          product_name: item.product_name ?? item.label,
          package_code: item.package_code ?? '',
          left_use: item.left_use ?? '',
          product_id: item.product_id,
          price: 0,
          category_id: item.product?.category_id,
          parent_package: item.parent_package
        })
      } else {
        new_service.push({
          id: item.value,
          product_name: item.label,
          price: item.price,
          product_id: item.product_id ?? item.value,
          category_id: item.category_id,
          parent_package: item.parent_package || null
        })
      }
    })

    if (timeWax > 0) timeWax += 5;
    timeWax = (timeWax > 60) ? 60 : timeWax;

    booking_item[key].product_ids = new_service;
    booking_item[key].intend_time = timeUsed + timeWax;

    let total_intend_time = Math.max(...booking_item.map(item => item.intend_time))
    total_intend_time += _.sumBy(item_full_body[0], function(o) { return o.intend_time; });
    
    this.setState({
      booking_item: booking_item,
      total_intend_time: total_intend_time
    })
  }

  onChangeItemFullBody = (key, data, indexParent) => {
    try {
      let item_full_body = this.state.item_full_body
      if (!item_full_body[indexParent]) {
        item_full_body[indexParent] = []
        item_full_body[indexParent][key] = data
      } else {
        item_full_body[indexParent][key] = data
      }

      let total_intend_time = Math.max(...this.state.booking_item.map(item => item.intend_time))
      total_intend_time += _.sumBy(item_full_body[0], function(o) { return o.intend_time; });

      this.setState({
        item_full_body: item_full_body,
        total_intend_time: total_intend_time
      })
    } catch (error) {
      console.log(error)
    }
  }
  
  onChangeUser = (key, data) => {
    let booking_item = this.state.booking_item,
        new_user = [],
        assign_for = 0;

    if (Array.isArray(data)) {
      data.map(function (item) {
        new_user.push({
          id: item.value,
          name: item.label,
          image: item.image
        })
      })
      assign_for = new_user[0]?.id ?? 0
    } else {
      new_user.push({
        id: data.value,
        name: data.label,
        image: data.image
      })
      assign_for = data.value
    }

    booking_item[key].user_ids = new_user;
    this.setState({ 
      booking_item: booking_item,
      assign_for: assign_for
    })
  }

  onChangeCountCustomer = (event) => {
    let count = this.state.countCustomer

    if (event.target.validity.valid) count = event.target.value
    if (event.target.value > 20) count = 20;
    this.setState({
      countCustomer: count,
    });
    this.setArrayServiceUser(count)
  }

  addMoreCustomer = () => {
    let count = Number(this.state.countCustomer);
    if (this.state.countCustomer <= 19) {
      this.setState({ countCustomer: count + 1 });
      this.setArrayServiceUser(count + 1)
    }
  }

  changeVoucherCode = (event) => {
    this.setState({
      voucher_code: (event.target.validity.valid) ? event.target.value : this.state.voucher_code
    });
  }

  onSubmitVoucher = async (event) => {
    try {
      const body = {
        // orderAt: props.orderSelected.created_at,
        total: 0,
        products: [],
        store: localStorage.getItem("currentStore"),
        customer: this.state.oldCustomer,
        type_order: 5,
      }
      const res = await axiosService("api/vouchers/check/" + this.state.voucher_code, "POST", body)
      const { data } = res
      if (data.code === 404) {
        message.warning(data.message)
      } else if (data.code === 200) {
        this.setState({
          voucher_name: data.data.name,
          voucher_code:   data.data.code,
          voucher_id:  data.data.id,
          isAddVoucher: true
        })
        message.success(data.data.message)
      } else {
        message.error("Có lỗi xảy ra")
      }
    } catch (error) {
      console.error(error)
      message.error("Có lỗi khi thêm voucher")
    }
  }

  handleClearVoucher = (key) => {
    try {
      this.setState({
        voucher_name: null,
        voucher_code: null,
        voucher_id: null,
        isAddVoucher: false
      })
    } catch (error) {
      console.error(error)
      message.error("Có lỗi khi thêm voucher")
    }
  }


  setArrayServiceUser = (count) => {
    let booking_item = this.state.booking_item;
    if (count > booking_item.length) {
      booking_item = [...booking_item, ...Array(count - booking_item.length).fill({ product_ids: [], user_ids: [], intend_time: 0 })];
    }

    if (count > 0 && count < booking_item.length) booking_item.length = count

    this.setState({
      booking_item: booking_item
    });
  }

  editOldCustomer = (event) => {
    this.setState({
      editOldCustomer: true,
    })
  }

  showListAvatarCustomer = (event) => {
    this.setState({
      listAvatarCustomer: 1,
    })
  }

  saveAvatarCustomer = async (select) => {
    let customer = this.props.dataEditBooking.customers
    let type = 1
    if (customer && customer.avata_url && customer.avata_url.length > 10) {
      type = 2
      var res = await axiosService("api/hanet-ai/update-customer/" + customer.id, "POST", select)
    } else {
      var res = await axiosService("api/hanet-ai/add-customer/" + customer.id, "POST", select)
    }

    if (res?.data?.success) {
      if (res?.data?.data?.returnCode === 1 && (res?.data?.data?.data?.path || res?.data?.data?.data?.file)) {
        this.setState({
          listAvatarCustomer: 2,
        })
  
        customer.avata_url = res?.data?.data?.data?.path ?? res?.data?.data?.data?.file
        this.setState({
          selectAvatar: null,
          avata_url: res.data.data.avata_url
        })
        message.success("Cập nhật avatar của khách thành công")
      } else {
        this.setState({ listAvatarCustomer: 1 })
        message.error(res?.data?.data?.returnCode == "-5011" ? "Vui lòng tải lại trang và thử lại" : res?.data?.data?.returnMessage)
      }
    } else {
      this.setState({ listAvatarCustomer: 1 })
      message.error("cập nhật avatar của khách thất bại")
    }
  }

  handleShowAvatarList = (e) => {
    this.setState({
      listAvatarCustomer: e,
    })
    if (e != 1) {
      this.setState({
        selectAvatar: e
      })
    }
    // if (props.showListHanet) {
    //   props.setOpenPopupHanet()
    //   return
    // }
    let customer = this.props.dataEditBooking.customers
    // console.log(checkChangeOrder,e)
  }
  handleAddAvatarSelect = (e) => {
    this.setState({
      selectAvatar: e
    })
  }

  getCurrentDataService = (key, options, type = null) => {
    let currentData = [];
    let newOptions = [];
    let checkProductId = []
    options.map(item => {
      if (!item.package_type_using) {
        let newLabel = item.label
        if (item.is_convert_bhvv == 1) newLabel += " - CD";
        newOptions.push({...item, label: newLabel})
      } else {
        checkProductId.push(item.id)
      }
    })
    
    let currentService = this.state.booking_item[key].product_ids.filter(x => {
      return x.parent_package == null && !comboPackagesEnum.includes(x.product_id)
    })

    if (type === true) {
      currentService.map((item) => {
        let index = _.findIndex(newOptions, function(o) { return o.package_code == item.id });
        if (index > 0) {
          currentData.push(newOptions[index].label)
        } else {
          currentData.push(item.product_name ?? item.name)
        }
      })
    } else {
      let newCurrentService = {};
      currentService.map((item) => {
        let code;
        
        if (item.package_code && item.package_code !== '') {
          code = item.package_code 
        } else {
          code = item.id ?? item.product_id;
          if (item.we_id) code = item.we_id
        }

        newCurrentService[code] = item
      })

      newOptions.map((item, index) => {
        if (newCurrentService[item.value]) currentData.push(newOptions[index])
      })
    }

    return currentData;
  }

  getCurrentDataUser = (key, options, type = null) => {
    let currentData = [];
    let currentUser = this.state.booking_item[key].user_ids;

    if (type === true) {
      currentUser.map((item, key) => {
        currentData.push(item.name)
      })
    } else {
      currentUser = _.groupBy(currentUser, ({ id }) => id);
      options.map((item, key) => {
        if (currentUser[item.value]) currentData.push(options[key])
      })
    }

    return currentData;
  }

  updateData = () => {
    let book_hour = ("0" + this.state.book_hour).slice(-2),
        book_minute = ("0" + this.state.book_minute).slice(-2),
        book_date = convert.convertDate(this.state.book_date) + " " + [book_hour, book_minute].join(":");
    const fullBodies = this.state.item_full_body
    const bookingItem = this.state.booking_item
    for (let x = 0; x < bookingItem.length; x++) {
      let filter = bookingItem[x].product_ids.filter(z => {
        return !comboPackagesEnum.includes(z.product_id)
      }).filter(z => {
        return z.parent_package == null
      })
      for (let i = 0; i < fullBodies.length; i++) {
        if (x == i) {
          const mapProduct = []
          fullBodies[i].forEach(element => {
            mapProduct.push(...element.product_ids)
          });
          filter = [...filter, ...mapProduct]
        }
      }
      bookingItem[x].product_ids = filter
    }

    const dataUpdate = {
      "id": this.props.dataEditBooking.id,
      "status": this.state.status,
      "book_status": this.state.book_status,
      "source_from": this.state.source_from,
      "intend_time": this.state.total_intend_time,
      "description": this.state.description,
      "description_internal": this.state.description_internal,
      "book_date": book_date,
      "stores": this.state.stores,
      "updated_by": this.state.userAdmin.id ?? '',
      "booking_type": this.state.typeBooking,
      "assign_for": this.state.assign_for,
      "voucher_code": this.state.voucher_name ,
      "voucher_id": this.state.voucher_id,
      "voucher_name": this.state.voucher_name,
      "customers": {
        "id": this.props.dataEditBooking.customers.id ?? '',
        "full_name": this.state.full_name,
        "mobile": this.state.mobile,
        "zalo_account": this.state.zalo_account,
        "gender": this.state.gender,
        "city": this.state.city,
        "district": this.state.district,
        "address": this.state.address,
        "birthday": this.state.day + '/' + this.state.month + '/' + this.state.year,
        "day_birthday": parseInt(this.state.day) || 0,
        "month_birthday": parseInt(this.state.month) || 0,
        "year_birthday": parseInt(this.state.year) || 0,
        "identifier": this.state.identifier
      },
      "booking_item": bookingItem,
    };
    this.props.updateBooking(dataUpdate)
  };

  copyText = () => {
    let product_name = [];
    this.state.booking_item.forEach(async (product) => {
      product.product_ids.forEach(async (productDetail) => {
        product_name.push(productDetail['product_name']);
      });
    });
    let address = this.state.addressStore.address + ", " + this.state.addressStore.district + ", " + this.state.addressStore.city;
    let bookDate = new Date(this.state.book_date);
    const a = `[ĐẶT LỊCH THÀNH CÔNG]
*Cent Beauty xin thông báo đã xác nhận lịch hẹn dịch vụ của bạn.
- Tên khách hàng: ${this.state.full_name}
- Số điện thoại: ${this.state.mobile}
- Mã lịch hẹn: ${this.state.code}
- Dịch vụ: ${product_name}
- Thời gian: ${this.state.book_hour.toString().padStart(2, '0')}:${this.state.book_minute.toString().padStart(2, '0')}
- Ngày: ${convert.convertDateDMY(bookDate, '-')}
- Cơ sở: ${address}

Hẹn gặp lại bạn tại Cent Beauty.`;

      const textarea = document.createElement('textarea');
      textarea.value = a;
  
      document.body.appendChild(textarea);
      textarea.select();
  
      document.execCommand('copy');
  
      document.body.removeChild(textarea);
      notifications({ type: 'success', mess: 'Sao chép nội dung thành công!' });
      this.setState({isCopied: true });
      setTimeout(() => {
        this.setState({ isCopied: false });
      }, 2000);
  };

  deleteData = () => {
    this.props.deleteBooking(this.props.dataEditBooking)
  }

  onChangeDay = (event) => {
    this.setState({
      day: event.value,
    });
  };
  onChangeMonth = (event) => {
    this.setState({
      month: event.value,
    });
  }
  onChangeYear = (event) => {
    this.setState({
      year: event.target.value,
    });
  };
  updatePackages(book_date) {
    let listPackage = this.props.packageByCustomer
    listPackage = listPackage.map(item => {
      const date = new Date(moment(new Date(item.last_used)).startOf('day').format('YYYY-MM-DD HH:mm:ss'))
      let dateDiff = 0
      let isValid = true
      if (item.product) {
        if (item.range_date_new > 0 && item.range_date_new != undefined) {
          item.product.range_date = item.range_date_new
        }
        date.setDate(date.getDate() + (item.product.range_date - 2))
        isValid = date.getTime() <= new Date(book_date).getTime()
        const a = moment(date);
        const b = moment(new Date());
        dateDiff = a.diff(b, 'days')
      }
      return {
        ...item,
        disabled: !isValid,
        checkDate: !isValid,
        dateDiff
      }
    })
    const filter = listPackage.filter(x => x.parent_package != null)
    const filterPackages = listPackage.filter(x => {
      return x.parent_package == null && !comboPackagesEnum.includes(x.product?.id)
    })
    const grouped = _.groupBy(filter, (x) => {
      return x.parent_package
    })
    const newObject = []
    for (const property in grouped) {
      const findIndex = listPackage.findIndex(x => {
        return x.id == property
      })
      this.props.dataEditBooking.booking_item.forEach(x => {
        const index = x.product_ids.findIndex(z => {
          let dataCheck = this.props.dataEditBooking.root ? z.package_code : z.id
          return dataCheck === listPackage[findIndex]?.package_code
        })
        if (index > -1) {
          grouped[property].forEach(y => {
            y.isSelect = true
          })
        } else {
          grouped[property].forEach(y => {
            const index = x.product_ids.findIndex(a => {
              return a.id === y.package_code
            })
            if (index > -1) {
              y.isSelect = true
            } else {
              y.isSelect = false
            }
          })
        }
      })
      if (findIndex > -1) {
        newObject.push({
          label: `${listPackage[findIndex]?.product?.product_name}-${listPackage[findIndex]?.package_code}`,
          options: grouped[property],
          parent: listPackage[findIndex],
          last_used: listPackage[findIndex].left_use,
          package_type_using: listPackage[findIndex].package_type_using
        })
      }
    }
    let listPackageChoose = _.map(this.props.dataEditBooking.booking_item[0].product_ids, function (o) { return o.package_code })

    _.forEach(listPackage, function (value) {
      if (listPackageChoose.includes(value.package_code)) {
      }
    });

    let dataServiceByType = [];
    if (this.state.booking_type == 1) {
      let listPackageBase = filterPackages.filter(x => (x.package_type_using != 1 && x.is_convert_bhvv == 0))
      let listPackageConvert = filterPackages.filter(x => x.is_convert_bhvv == 1)
      let newListPackageConvert = [];
      listPackageConvert.map((item) => {
        newListPackageConvert.push({...item, label: item.label + " - CD"})
      })

      dataServiceByType = [
        { label: 'Thẻ liệu trình', options: listPackageBase },
        { label: "Thẻ chuyển đổi từ BHVV", options: newListPackageConvert },
        { label: 'Dịch vụ', options: this.props.dataServiceByCustomer[1].options }
      ];
    } else {
      this.props.serviceNamigo.map(x => {
        if (x.id == this.state.booking_type) {
          dataServiceByType = [{ label: "Dịch vụ " + x.name, options: x.product }, { label: 'Dịch vụ', options: this.props.dataServiceByCustomer[1].options }]
        }
      })
    }

    this.setState({
      newDataService: dataServiceByType,
      fullBodies: newObject,
    })
  }

  getDetailCustomer = async () => {
    let listPackage = this.props.packageByCustomer
    let book_hour = ("0" + this.state.book_hour).slice(-2),
        book_minute = ("0" + this.state.book_minute).slice(-2),
        book_date = convert.convertDate(this.state.book_date) + " " + [book_hour, book_minute].join(":");
    listPackage = listPackage.map(item => {
      const date = new Date(moment(new Date(item.last_used)).startOf('day').format('YYYY-MM-DD HH:mm:ss'))
      let dateDiff = 0
      let isValid = true
      if (item.product) {
        if (item.range_date_new > 0 && item.range_date_new != undefined) {
          item.product.range_date = item.range_date_new
        }
        date.setDate(date.getDate() + (item.product.range_date - 2))
        isValid = date.getTime() <= new Date(book_date).getTime()
        const a = moment(date);
        const b = moment(new Date());
        dateDiff = a.diff(b, 'days')
      }
      return {
        ...item,
        disabled: !isValid,
        checkDate: !isValid,
        dateDiff
      }
    })
    const filter = listPackage.filter(x => x.parent_package != null)
    const filterPackages = listPackage.filter(x => {
      return x.parent_package == null && !comboPackagesEnum.includes(x.product?.id)
    })
    const grouped = _.groupBy(filter, (x) => {
      return x.parent_package
    })
    const newObject = []
    for (const property in grouped) {
      const findIndex = listPackage.findIndex(x => {
        return x.id == property
      })
      this.props.dataEditBooking.booking_item.forEach(x => {
        const index = x.product_ids.findIndex(z => {
          let dataCheck = this.props.dataEditBooking.root ? z.package_code : z.id
          return dataCheck === listPackage[findIndex]?.package_code
        })
        if (index > -1) {
          grouped[property].forEach(y => {
            y.isSelect = true
          })
        } else {
          grouped[property].forEach(y => {
            const index = x.product_ids.findIndex(a => {
              return a.id === y.package_code
            })
            if (index > -1) {
              y.isSelect = true
            } else {
              y.isSelect = false
            }
          })
        }
      })

      if (findIndex > -1) {
        newObject.push({
          label: `${listPackage[findIndex]?.product?.product_name}-${listPackage[findIndex]?.package_code}`,
          options: grouped[property],
          parent: listPackage[findIndex],
          last_used: listPackage[findIndex].left_use,
          package_type_using: listPackage[findIndex].package_type_using
        })
      }
    }
    let listPackageChoose = _.map(this.props.dataEditBooking.booking_item[0].product_ids, function (o) { return o.package_code })

    _.forEach(listPackage, function (value) {
      if (listPackageChoose.includes(value.package_code)) {
      }
    });

    let dataServiceByType = [];

    if (this.state.booking_type == 1) {
      let listPackageBase = filterPackages.filter(x => (x.package_type_using != 1 && x.is_convert_bhvv == 0))
      let listPackageConvert = filterPackages.filter(x => x.is_convert_bhvv == 1)
      let newListPackageConvert = [];
      listPackageConvert.map((item) => {
        newListPackageConvert.push({...item, label: item.label + " - CD"})
      })

      dataServiceByType = [
        { label: 'Thẻ liệu trình', options: listPackageBase },
        { label: "Thẻ chuyển đổi từ BHVV", options: newListPackageConvert },
        { label: 'Dịch vụ', options: this.props.dataServiceByCustomer[1].options }
      ];
    } else {
      this.props.serviceNamigo.map(x => {
        if (x.id == this.state.booking_type) {
          dataServiceByType = [{ label: "Dịch vụ " + x.name, options: x.product }, { label: 'Dịch vụ', options: this.props.dataServiceByCustomer[1].options }]
        }
      })
    }

    this.setState({
      newDataService: dataServiceByType,
      fullBodies: newObject,
    })
  }

  changeCurrenAvatar = (dataImage) => {
    this.setState({
      avata_url: dataImage,
    })
  }
  onChangeIdentifier = (e) => {
    this.setState({
      identifier: e.target.checked ? 1 : 0,
    })
  }
  async componentDidMount() {
    this.getDetailCustomer()
    let dataLocation = convert.getDistricts(this.props.dataEditBooking.customers.city, this.props.dataEditBooking.customers.district)

    this.setState({
      keyCity: (dataLocation && dataLocation.keyCity >= 0) ? dataLocation.keyCity : null,
      keyDistrict: (dataLocation && dataLocation.keyDistrict >= 0) ? dataLocation.keyDistrict : null,
      dataDistrict: (dataLocation && dataLocation.dataDistrict.length >= 0) ? dataLocation.dataDistrict : []
    })
  }
  render() {
    let date = moment(`${this.state.day}/${this.state.month}/${this.state.year}`)
    return (
      <div className="popup-cent add-booking">
        <div className="content-popup fadeInDown">
          <div className="head-popup">
            <div className="title">
              <span className="text">Lịch hẹn</span>
              <div className="create-at more-info">Tạo lúc {convert.convertDateTime(this.props.dataEditBooking.created_at)}</div>
              <div className="create-by more-info">Tạo bởi: <span>{this.props.nameCreateBy}</span></div>
              <div className="edit-at more-info">Sửa lần cuối lúc {convert.convertDateTime(this.props.dataEditBooking.updated_at)}</div>
              <div className="edit-by more-info">Sửa lần cuối bởi: <span>{this.props.nameUpdatedBy}</span></div>
            </div>
            <div className="close" onClick={this.props.close}>×</div>
          </div>
          <div className="body-popup">
            {this.props.dataUser !== 4 && this.props.dataEditBooking.order_status === 1 && this.props.dataEditBooking.book_status != 5 ?
              <div className="row">
                <div className="col-md-12 col-lg-10">
                  <div className="items-data row">
                    <div className="col-3 col-md-2">Loại lịch</div>
                    <div className="value col-md-3 col-lg-2 code"></div>
                    <div className="value col-md-1 col-lg-2"></div>
                    <div className="value col-md-6 col-lg-6">
                      <div className="booking-type disable">
                        <div className={(this.state.booking_type === 1) ? "item-type active" : "item-type"}>Da - Triệt - PX</div>
                        {idCategory.map((data, key) => (
                          <div key={key} className={(this.state.booking_type === data.id) ? "item-type active" : "item-type"}>{data.label}</div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="items-data row">
                    <div className="col-3 col-md-2">Mã đặt lịch</div>
                    <div className="value col-3 col-md-3 col-lg-2 code" style={{ "color": "#2196F3", "fontSize": "12px" }}>{this.props.dataEditBooking.book_code}</div>
                    <div className="value col-md-1 col-lg-4"></div>
                    <div className="value col-12 col-md-6 col-lg-4">
                      <div className="booking-type disable">
                        <div className={(this.state.status === 1) ? "item-type active" : "item-type"}>Pre Order</div>
                        <div className={(this.state.status === 2) ? "item-type active" : "item-type"}>Đặt lịch trước</div>
                        <div className={(this.state.status === 3) ? "item-type active" : "item-type"}>Đến trực tiếp</div>
                      </div>
                    </div>
                  </div>
                  <div className="items-data row">
                    <div className="title col-3 col-md-2">Khách hàng</div>
                    {this.state.editOldCustomer === true ?
                      <div className="value col-9 col-md-10" style={{ position: "relative", zIndex: 4 }}>
                        <div className="row">
                          <div className="col-6 col-md-2">
                            <Select className="select" isSearchable={false} onChange={this.onChangeGender} styles={customStylesOption} defaultValue={this.props.optionsGender[this.state.gender - 1]} options={this.props.optionsGender} />
                          </div>
                          <div className="col-6 col-md-4">
                            <input onChange={this.onChangeName} defaultValue={this.state.full_name} placeholder="Họ và tên" className="required" type="text" />
                          </div>
                          <div className="col-6 col-md-3">
                            <input disabled defaultValue={this.state.mobile} placeholder="Điện thoại" type="text" />
                          </div>
                          <div className="col-6 col-md-3">
                            <input onChange={this.onChangeZaloAccount} defaultValue={this.state.zalo_account} placeholder="Zalo" type="text" />
                          </div>
                        </div>
                      </div>
                      : <div className="old-customer col-9 col-md-10">
                        <div className="row">
                          <div className="col-3 col-md-2 avatar view-avatar-booking" onClick={() => this.setState({openTypeAvatar: true})}>
                            {this.state.avata_url ?
                              <img src={this.state.avata_url} alt={this.state.full_name} />
                              : <Avatar name={this.state.full_name} size="60" textSizeRatio={1.75} round="50%" />}
                          </div>
                          <div className="col-9 col-md-10 info-customer">
                            <span className="gender">{this.state.gender === 1 ? "Anh" : "Chị"}</span>
                            <span className="name">{this.state.full_name}</span>
                            {this.state.identifier === 1 ? <span className="identifier-customer"><img src="/icons/verified.png" /></span> : ""}
                            <span className="button-edit" onClick={this.editOldCustomer}><i className="fas fa-pencil-alt"></i> Sửa</span>
                            {this.props.dataEditBooking.customers.ranking ?
                              <div className="ranking"><i className="fas fa-trophy"></i>{generalData.optionsRanking[this.props.dataEditBooking.customers.ranking - 1].label}</div>
                            : null}
                            {this.state.mobile ?
                              <div className="phone"><i className="fas fa-phone-square"></i>{this.state.mobile}</div>
                            : null}
                            {this.state.zalo_account ?
                              <div className="zalo"><b>Z </b>{this.state.zalo_account}</div>
                            : null}
                            {this.state.district || this.state.city ?
                              <div className="address"><i className="fas fa-map-marker-alt"></i>
                                {this.state.district}
                                {(this.state.district && this.state.city) ? "-" : null}
                                {this.state.city}
                              </div>
                            : null}

                            <div className="address"><i className="fas fa-birthday-cake"></i>
                              {this.state.day ? ("0" + this.state.day).slice(-2) + "/" : null}
                              {this.state.month ? ("0" + this.state.month).slice(-2) + "/" : null}
                              {this.state.year ? this.state.year : null}
                            </div>

                            {this.props.dataEditBooking.customers.note && this.props.dataEditBooking.customers.note != '' ?
                              <div className="note">{this.props.dataEditBooking.customers.note}</div>
                              : null}
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                  {this.state.editOldCustomer === true ?
                    <>
                      <div className="items-data row">
                        <div className="title col-3 col-md-2">Địa chỉ</div>
                        <div className="value col-9 col-md-10" style={{ position: "relative", zIndex: 3 }}>
                          <div className="row">
                            <div className="col-6 col-md-3">
                              <Select className="select"
                                isSearchable={true}
                                placeholder="Tỉnh/Thành"
                                onChange={this.onChangeCity}
                                styles={customStylesOption}
                                defaultValue={location[this.state.keyCity]}
                                options={location} />
                            </div>
                            <div className="col-6 col-md-4">
                              <Select className="select"
                                ref={ref => { this.selectDistrictRef = ref }}
                                isSearchable={true}
                                placeholder="Quận/Huyện"
                                onChange={this.onChangeDistrict}
                                styles={customStylesOption}
                                defaultValue={this.state.dataDistrict[this.state.keyDistrict]}
                                options={this.state.dataDistrict} />
                            </div>
                            <div className="col-12 col-md-5">
                              <input onChange={this.onChangeAddress} defaultValue={this.state.address} placeholder="Địa chỉ" type="text" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="items-data row">
                        <div className="title col-3 col-md-2">Sinh nhật</div>
                        <div className="value col-9 col-md-10" style={{ position: "relative", zIndex: 2 }}>
                          <div className="row">
                            <div className="col-4">
                              <Select className="select" defaultValue={() => {
                                const findIndex = optionsDays.findIndex(x => x.value == this.state.day)
                                return optionsDays[findIndex]
                              }} onChange={this.onChangeDay} placeholder="Ngày" styles={customStylesBirthday} options={optionsDays} />
                            </div>
                            <div className="col-4">
                              <Select className="select" onChange={this.onChangeMonth}
                                defaultValue={() => {
                                  const findIndex = optionsDays.findIndex(x => x.value == this.state.month)
                                  return optionsDays[findIndex]
                                }}
                                placeholder="Tháng" styles={customStylesBirthday} options={optionsMonths} />
                            </div>
                            <div className="col-4">
                              <input onChange={this.onChangeYear} defaultValue={this.state.year} placeholder="Năm" type="text" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="items-data row">
                        <div className="title col-3 col-md-2">Định danh</div>
                        <div className="value col-9 col-md-10" style={{ position: "relative", zIndex: 2 }}>
                          <div className="row">
                            <div className="col-4">
                              <Checkbox checked={this.state.identifier === 1} onChange={this.onChangeIdentifier}>Có</Checkbox>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                    : ""}
                  <div className="items-data row border-top pdt-10">
                    <div className="title col-3 col-md-2">Giờ hẹn</div>
                    <div className="value col-9 col-md-10">
                      <div className="row">
                        <div className="col-6 col-md-3 col-lg-2">
                          <Select className="select"
                            isSearchable={false} onChange={this.onChangeHourBooking}
                            styles={customStylesOption} options={this.props.optionsHour}
                            defaultValue={this.props.optionsHour[(this.state.book_hour - 9)]} />
                        </div>
                        <div className="col-6 col-md-3 col-lg-2">
                          <Select className="select"
                            isSearchable={false} options={this.props.optionsMinute}
                            onChange={this.onChangeMinuteBooking} styles={customStylesOption}
                            defaultValue={this.props.optionsMinute[(this.state.book_minute / 5)]} />
                        </div>
                        <div className="col-12 col-md-4 col-lg-4">
                          <DatePicker
                            style={{ position: "relative", top: "-2px", zIndex: 1 }}
                            onChange={this.onChangeDateBooking}
                            className="custom-date-picker"
                            oneTap
                            format={'dd-MM-yyyy'}
                            placement={'bottomStart'}
                            defaultValue={new Date(this.props.dataEditBooking.book_date)}
                            size="sm"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="items-data row">
                    <div className="title col-3 col-md-2">Thời gian dự kiến</div>
                    <div className="value col-4 col-md-2">
                      <input className="right-text-input none-border-right" pattern="[0-9]*" onInput={this.onChangeEstimatedTime} defaultValue={this.state.total_intend_time} type="text" />
                      <span className="measure">phút</span>
                    </div>
                  </div>

                  <div className="items-data row">
                    <div className="title col-3 col-md-2">Mã voucher</div>
                    {!this.state.isAddVoucher ?
                        <div className="value col-9 col-md-5">
                        <span className='d-flexs'>
                          <input className="none-border-right" placeholder="Vui lòng nhập một mã voucher" onInput={this.changeVoucherCode} defaultValue={this.state.voucher_code} type="text" />
                          <Button className="ml-4" icon={<CheckOutlined style={{ color: "green" }} onClick={this.onSubmitVoucher} />} />
                        </span>
                      </div>
                    : 
                      <div className="value col-9 col-md-5">
                        <span className='d-flexs'>
                          <span style={{ color: "black", fontSize: "11px" }}>{this.state.voucher_name} </span>
                          (<span style={{ color: "red", cursor: "pointer", textDecoration: "underline", fontSize: "11px"  }} onClick={() => this.handleClearVoucher(this.state.voucher_id)}>Xóa</span>)
                        </span>
                      </div>
                    }
                  </div>
                  <div className="items-data row">
                    <div className="title col-3 col-md-2">Ghi chú khách hàng</div>
                    <div className="value col-9 col-md-10">
                      <textarea onChange={this.onChangeNote} type="text" defaultValue={this.state.description} />
                    </div>
                  </div>
                  <div className="items-data row">
                    <div className="title col-3 col-md-2">Ghi chú nội bộ</div>
                    <div className="value col-9 col-md-10">
                      <textarea onChange={this.onChangeNoteInternal} type="text" defaultValue={this.state.description_internal} />
                    </div>
                  </div>
                  <div className="items-data row">
                    <div className="title col-3 col-md-2">Số lượng khách</div>
                    <div className="value col-9 col-md-2">
                      <div className="row">
                        <div className="col-6">
                          <input className="right-text-input" pattern="[0-9]*" onInput={this.onChangeCountCustomer} defaultValue={this.state.countCustomer} type="text" />
                        </div>
                      </div>
                    </div>
                    <div className="title col-3 col-md-2">Tại chi nhánh</div>
                    <div className="value col-9 col-md-6 col-lg-4">
                      <Select className="select"
                        isSearchable={false}
                        onChange={this.onChangeStore}
                        styles={customStylesOption}
                        defaultValue={this.props.dataStore.map((item, key) => ((this.state.stores === item.value) ? this.props.dataStore[key] : ''))}
                        options={this.props.dataStore} />
                    </div>
                  </div>
                  <div className="items-data row border-top">
                    <div className="title col-3 col-md-2 md-10">
                      <div className="bold">Khách hàng</div>
                      {this.state.typeBooking != 47 ?
                        <div className="add-more" onClick={this.addMoreCustomer}><i className="fas fa-plus-circle"></i>Thêm khách</div>
                      : null}  
                    </div>
                    <div className="title col-6 d-none d-md-block bold">Dịch vụ</div>
                    <div className="title col-4 d-none d-md-block bold">Nhân viên phục vụ</div>
                  </div>
                  {this.state.booking_item.map((item, key) => (
                    <div className="items-data row item-more-customer" key={key}>
                      <div className="value col-12 col-md-2">KHÁCH {key + 1} <span className={(item.intend_time > 0) ? "count-time active" : "count-time"}>(<i className="far fa-clock"></i> {item.intend_time} phút)</span></div>
                      <div className="title col-3 d-block d-md-none bold md-10">Dịch vụ</div>
                      <div className="value col-9 col-md-6 service md-10">
                          <Select className="select booking service"
                            menuPlacement="top"
                            isSearchable={true}
                            isMulti closeMenuOnSelect={false}
                            onChange={(event) => this.onChangeService(key, event)}
                            styles={customStylesMultiSelect}
                            isOptionDisabled={(option) => option.disabled}
                            formatGroupLabel={formatGroupLabel}
                            options={this.state.newDataService}
                            defaultValue={this.getCurrentDataService(key, this.props.totalServiceByCustomer)}
                          />

                        {this.state.fullBodies.length > 0 && key == 0 && this.state.typeBooking != 47 ?
                          this.state.fullBodies.map((x, i) => {
                            return <div key={i}>
                              <PackageFullBody key={i} index={i} indexParent={key} onChangeItemFullBody={this.onChangeItemFullBody} data={x} />
                            </div>
                          })
                        : null}
                      </div>
                      <div className="title col-3 d-block d-md-none bold md-10">Nhân viên phục vụ</div>
                      <div className="value col-9 col-md-4 user md-10">
                        <Select className="select booking user"
                          menuPlacement="top"
                          isSearchable={true}
                          isMulti={this.state.typeBooking != 47 ? true : false} 
                          closeMenuOnSelect={this.state.typeBooking != 47 ? false : true}
                          onChange={(event) => this.onChangeUser(key, event)}
                          styles={customStylesMultiSelect}
                          options={this.props.dataOperators}
                          defaultValue={this.getCurrentDataUser(key, this.props.dataOperators)} />
                      </div>
                    </div>
                  ))}
                </div>
                <div className="col-md-12 col-lg-2">
                  <div className="item-sidebar status">
                    <div className="label-sidebar">Trạng thái</div>
                    <div className="value-sidebar edit-popup">
                      <div className={"list-items"}>
                        {this.state.userAdmin.role == 5 ? 
                          ![3,4,6,7].includes(this.state.book_status) ? 
                            this.props.optionsStatusBookingForCS.map((data, key) => (
                              <div key={key} className={(this.state.book_status === data.value) ? "item active" : "item"} onClick={() => this.onChangeBookStatus(data.value)}>
                                <span className={"small-circle status-" + data.value}></span>{data.label}
                              </div>
                            ))
                          : 
                            <div className="item active">
                              <span className={"small-circle status-" + this.state.book_status}></span>{this.props.optionsStatusBooking[this.state.book_status - 1].label}
                            </div>
                        : 
                          this.props.optionsStatusBooking.map((data, key) => (
                            <div key={key} className={(this.state.book_status === data.value) ? "item active" : "item"} onClick={() => this.onChangeBookStatus(data.value)}>
                              <span className={"small-circle status-" + data.value}></span>{data.label}
                            </div>
                          ))
                        }
                      </div>
                    </div>
                  </div>
                  <div className="item-sidebar source">
                    <div className="label-sidebar">Đặt lịch từ</div>
                    <div className={"value-sidebar"}>
                      {this.props.optionsSourceBooking.map((data, key) => (
                        <div key={key} className={(this.state.source_from === data.value) ? "item active" : "item"} onClick={() => this.onChangeSource(data.value)}>{data.label}</div>
                      ))}
                    </div>
                  </div>
                  <div className="item-sidebar source">
                    <button className={`status-${this.state.isCopied ? '4' : ''}`} onClick={this.copyText}>
                    {this.state.isCopied && <span><i className="fas fa-check"></i></span>} Sao chép nội dung tin nhắn xác nhận
                      </button>
                  </div>
                </div>
              </div>
              :
              <div className="row">
                <div className="col-md-12 col-lg-10">
                  <div className="items-data row">
                    <div className="col-3 col-md-2">Mã đặt lịch</div>
                    <div className="value col-3 col-md-3 col-lg-2 code" style={{ "color": "#2196F3", "fontSize": "12px" }}>{this.props.dataEditBooking.book_code}</div>
                    <div className="value col-md-1 col-lg-4"></div>
                    <div className="value col-12 col-md-6 col-lg-4">
                      <div className="booking-type disable">
                        <div className={(this.state.status === 1) ? "item-type active" : "item-type"}>Pre Order</div>
                        <div className={(this.state.status === 2) ? "item-type active" : "item-type"}>Đặt lịch trước</div>
                        <div className={(this.state.status === 3) ? "item-type active" : "item-type"}>Đến trực tiếp</div>
                      </div>
                    </div>
                  </div>
                  <div className="items-data row">
                    <div className="title col-3 col-md-2">Khách hàng</div>
                    {this.state.editOldCustomer === true ?
                      <div className="value col-9 col-md-10">
                        <div className="row">
                          <div className="col-6 col-md-2">
                            <Select className="select" isSearchable={false} onChange={this.onChangeGender} styles={customStylesOption} defaultValue={this.props.optionsGender[this.state.gender - 1]} options={this.props.optionsGender} />
                          </div>
                          <div className="col-6 col-md-5">
                            <input onChange={this.onChangeName} defaultValue={this.state.full_name} placeholder="Họ và tên" className="required" type="text" />
                          </div>
                          <div className="col-6 col-md-5">
                            <input onChange={this.onChangeMobile} defaultValue={this.state.mobile} placeholder="Điện thoại" type="text" />
                          </div>
                        </div>
                      </div>
                      : <div className="old-customer col-9 col-md-10">
                        <div className="row">
                          <div className="col-3 col-md-2 avatar view-avatar-booking" onClick={() => this.setState({openTypeAvatar: true})}>
                            {this.state.avata_url ?
                              <img src={this.state.avata_url} alt="" />
                              : <Avatar name={this.state.full_name} size="60" textSizeRatio={1.75} round="50%" />}
                          </div>
                          <div className="col-9 col-md-10 info-customer">
                            <span className="gender">{this.state.gender === 1 ? "Anh" : "Chị"}</span>
                            <span className="name">{this.state.full_name}</span>
                            {this.props.dataEditBooking.customers?.identifier === 1 ? <span className="identifier-customer"><img src="/icons/verified.png" /></span> : ""}
                            {this.props.dataEditBooking.customers.ranking ?
                              <div className="ranking"><i className="fas fa-trophy"></i>{generalData.optionsRanking[this.props.dataEditBooking.customers.ranking - 1].label}</div>
                              : null}
                            {this.state.mobile ?
                              <div className="phone"><i className="fas fa-phone-square"></i>{this.state.mobile}</div>
                              : null}
                            {this.state.district || this.state.city ?
                              <div className="address"><i className="fas fa-map-marker-alt"></i>
                                {this.state.district}
                                {(this.state.district && this.state.city) ? "-" : null}
                                {this.state.city}
                              </div>
                              : null}
                            <div className="address"><i className="fas fa-birthday-cake"></i>
                              {date.isValid ? `${this.state.day > 9 ? this.state.day : `0${this.state.day}`}/${this.state.month > 9 ? this.state.month : `0${this.state.month}`}/${this.state.year}` : "Ngày sinh chưa đúng"}
                            </div>
                            {this.props.dataEditBooking.customers.note && this.props.dataEditBooking.customers.note != '' ?
                              <div className="note">{this.props.dataEditBooking.customers.note}</div>
                              : null}
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                  {this.state.editOldCustomer === true ?
                    <div className="items-data row">
                      <div className="title col-3 col-md-2">Địa chỉ</div>
                      <div className="value col-9 col-md-10">
                        <div className="row">
                          <div className="col-6 col-md-3" style={{ position: 'relative', zIndex: 2 }}>
                            <Select className="select"
                              isSearchable={true}
                              placeholder="Tỉnh/Thành"
                              onChange={this.onChangeCity}
                              styles={customStylesOption}
                              defaultValue={location[this.state.keyCity]}
                              options={location} />
                          </div>
                          <div className="col-6 col-md-4" style={{ position: 'relative', zIndex: 2 }}>
                            <Select className="select"
                              ref={ref => { this.selectDistrictRef = ref }}
                              isSearchable={true}
                              placeholder="Quận/Huyện"
                              onChange={this.onChangeDistrict}
                              styles={customStylesOption}
                              defaultValue={this.state.dataDistrict[this.state.keyDistrict]}
                              options={this.state.dataDistrict} />
                          </div>
                          <div className="col-12 col-md-5">
                            <input onChange={this.onChangeAddress} defaultValue={this.state.address} placeholder="Địa chỉ" type="text" />
                          </div>
                        </div>
                      </div>
                    </div>
                    : ""}
                  <div className="items-data row border-top pdt-10">
                    <div className="title col-3 col-md-2">Giờ hẹn</div>
                    <div className="value col-9 col-md-10">
                      <div className="row">
                        <div className="col-6 col-md-3 col-lg-2">
                          <Select className="select"
                            isSearchable={false} onChange={this.onChangeHourBooking}
                            styles={customStylesOption} options={this.props.optionsHour}
                            isDisabled={true}
                            defaultValue={this.props.optionsHour[(this.state.book_hour - 9)]} />
                        </div>
                        <div className="col-6 col-md-3 col-lg-2">
                          <Select className="select"
                            isSearchable={false} options={this.props.optionsMinute}
                            onChange={this.onChangeMinuteBooking} styles={customStylesOption}
                            isDisabled={true}
                            defaultValue={this.props.optionsMinute[(this.state.book_minute / 5)]} />
                        </div>
                        <div className="col-12 col-md-4 col-lg-4">
                          <DatePicker
                            style={{ position: "relative", top: "-2px", zIndex: 1 }}
                            onChange={this.onChangeDateBooking}
                            className="custom-date-picker"
                            oneTap
                            format={'dd-MM-yyyy'}
                            placement={'bottomStart'}
                            defaultValue={new Date(this.props.dataEditBooking.book_date)}
                            size="sm"
                            disabled={true}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="items-data row">
                    <div className="title col-3 col-md-2">Thời gian dự kiến</div>
                    <div className="value col-4 col-md-2">
                      <input disabled={true} className="right-text-input none-border-right" pattern="[0-9]*" onInput={this.onChangeEstimatedTime} defaultValue={this.state.total_intend_time} type="text" />
                      <span className="measure">phút</span>
                    </div>
                  </div>
                  <div className="items-data row">
                    <div className="title col-3 col-md-2">Ghi chú khách hàng</div>
                    <div className="value col-9 col-md-10">
                      <textarea disabled={true} onChange={this.onChangeNote} type="text" defaultValue={this.state.description} />
                    </div>
                  </div>
                  <div className="items-data row">
                    <div className="title col-3 col-md-2">Ghi chú nội bộ</div>
                    <div className="value col-9 col-md-10">
                      <textarea disabled={true} onChange={this.onChangeNoteInternal} type="text" defaultValue={this.state.description_internal} />
                    </div>
                  </div>
                  <div className="items-data row">
                    <div className="title col-3 col-md-2">Số lượng khách</div>
                    <div className="value col-9 col-md-2">
                      <div className="row">
                        <div className="col-6">
                          <input disabled={true} className="right-text-input" pattern="[0-9]*" onInput={this.onChangeCountCustomer} defaultValue={this.state.countCustomer} type="text" />
                        </div>
                      </div>
                    </div>
                    <div className="title col-3 col-md-2">Tại chi nhánh</div>
                    <div className="value col-9 col-md-6 col-lg-4">
                      <Select className="select"
                        isSearchable={false}
                        onChange={this.onChangeStore}
                        styles={customStylesOption}
                        defaultValue={this.props.dataStore.map((item, key) => ((this.state.stores === item.value) ? this.props.dataStore[key] : ''))}
                        options={this.props.dataStore}
                        isDisabled={true} />
                    </div>
                  </div>
                  <div className="items-data row border-top">
                    <div className="title col-3 col-md-2 md-10">
                      <div className="bold">Khách hàng</div>
                    </div>
                    <div className="title col-5 d-none d-md-block bold">Dịch vụ</div>
                    <div className="title col-5 d-none d-md-block bold">Nhân viên phục vụ</div>
                  </div>
                  {this.state.booking_item.map((item, key) => (
                    <div className="items-data row item-more-customer" key={key}>
                      <div className="value col-12 col-md-2">KHÁCH {key + 1} <span className={(item.intend_time > 0) ? "count-time active" : "count-time"}>(<i className="far fa-clock"></i> {item.intend_time} phút)</span></div>
                      <div className="title col-3 d-block d-md-none bold md-10">Dịch vụ</div>
                      <div className="value col-9 col-md-6 service md-10">
                        {this.props.dataServiceByCustomer.length > 0 ?
                          this.getCurrentDataService(key, this.props.totalServiceByCustomer, true).map((item, index) => (
                            <p key={index}>- {item}</p>
                          ))
                          : null}
                      </div>
                      <div className="title col-3 d-block d-md-none bold md-10">Nhân viên phục vụ</div>
                      <div className="value col-9 col-md-4 user md-10">
                        {this.getCurrentDataUser(key, this.props.dataOperators, true).map((item, index) => (
                          <p key={index}>- {item}</p>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
                <div className="col-md-12 col-lg-2">
                  <div className="item-sidebar status">
                    <div className="label-sidebar">Trạng thái</div>
                    <div className="value-sidebar edit-popup">
                      <div className={"list-items disable"}>
                        {this.props.optionsStatusBooking.map((data, key) => (
                          <div key={key} className={(this.state.book_status === data.value) ? "item active" : "item"}>
                            <span className={"small-circle status-" + data.value}></span>{data.label}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="item-sidebar source">
                    <div className="label-sidebar">Đặt lịch từ</div>
                    <div className={"value-sidebar disable"}>
                      {this.props.optionsSourceBooking.map((data, key) => (
                        <div key={key} className={(this.state.source_from === data.value) ? "item active" : "item"}>{data.label}</div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
          {this.props.dataUser !== 4 ?
            <div className="bottom-popup border-top">
              {/* {this.props.dataEditBooking.order_status === 1 && this.props.dataEditBooking.book_status != 5 ?
                <div className="button-cent style3" style={{ "float": "left", "margin": "10px" }} onClick={this.deleteData}>
                  <span><i className="fas fa-times"></i></span>Xóa lịch
                </div>
              : null} */}
              <div className="button-container">
                <div className="button-cent style3" onClick={this.props.close}>
                  <span><i className="fas fa-times"></i></span>Đóng
                </div>
                {this.props.dataEditBooking.order_status === 1 && this.props.dataEditBooking.book_status != 5 ?
                  <div className="button-cent" onClick={this.updateData}>
                    <span><i className="fas fa-save"></i></span>Lưu thông tin
                  </div>
                  : null}
              </div>
            </div>
            : null}
        </div>
        {this.state.openTypeAvatar ?
          <div className="popup-cent openTypeAvatar">
            <div className="content-popup fadeInDown">
              <div className="head-popup">
                <div className="title">Chọn cách thức</div>
                <div className="close" onClick={() => this.setState({openTypeAvatar: false})}>×</div>
              </div>
              <div className="body-popup">
                <div className="button-container">
                  <div className="button-cent style" style={{marginBottom: "20px"}} onClick={() => this.setState({showImgCustomer: true, openTypeAvatar: false})}>
                    <span><i className="fas fa-camera"></i></span>Mở camera
                  </div>
                  <div className="button-cent style2" onClick={() => this.setState({listAvatarCustomer: 1, openTypeAvatar: false})}>
                    <span><i className="fas fa-list"></i></span>Danh sách ảnh chụp từ camera Hanet
                  </div>
                </div>
              </div>
              <div className="bottom-popup">
                <div className="button-container">
                  <div className="button-cent style3" onClick={() => this.setState({openTypeAvatar: false})}>
                    <span><i className="fas fa-times"></i></span>Đóng
                  </div>
                </div>
              </div>
            </div>
            <div className="overlay" onClick={() => this.setState({openTypeAvatar: false})}></div>
          </div>
        :null } 
        {this.state.showImgCustomer ?
          <ViewImgCustomer
            show={this.state.showImgCustomer}
            close={() => {this.setState({showImgCustomer: false})}}
            customer={this.props.dataEditBooking.customers}
            changeCurrenAvatar={this.changeCurrenAvatar}
          >
          </ViewImgCustomer>
        : null}
        {this.state.listAvatarCustomer == 1? 
            <PopupSelectAvatarCustomer 
              saveAvatarCustomer={this.saveAvatarCustomer}
              handleShowAvatarList = {this.handleShowAvatarList}
              customer={this.props.dataEditBooking.customers}
              handleAddAvatarSelect= {this.handleAddAvatarSelect}
            /> : null}
          {this.state.selectAvatar ?
          <div className="avatar-view">
            <div  className="title-avatar">Date : {moment(new Date(this.state.selectAvatar.date)).format('YYYY-MM-DD HH:mm')}</div>
            <AvatarCustomer 
              voucher={this.state.selectAvatar}
            /> 
          </div>
          : null }
        <div className="overlay" onClick={this.props.close}></div>
        
      </div>
    );
  }
}
export default Edit;
