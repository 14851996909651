import React, { useState, useEffect } from "react";
import { Pagination } from 'rsuite';
import Select from 'react-select'
import { startOfDay, endOfDay, addDays, subDays } from "date-fns";
import DateRangePicker from "rsuite/DateRangePicker";
import { axiosGet } from "../../utils/axios.service";
import axiosService from "../../utils/axios.service";
import "./scss/orders.scss";
import { orderStatus, orderStatusOject, paymentType, orderStatusClass, OwedType } from './constant/OrderConstant';
import { OrderDetailRight } from "./OrderDetailRight";
import InforUserPopup from "../infor/InforCustomerPopup";
import Loading from "../customer/Loading";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import * as convert from "../constant/Convert.helper"

import { Link } from "react-router-dom";

var _state = {}
var currentStore = localStorage.getItem("currentStore") ?? 1
var userAdmin = JSON.parse(localStorage.getItem("user_info"))
var isLoading = false;
var isLoadingUser = false;
var param = "?";
let _ = require('lodash');

const Orders = () => {
    const [dataOrders, setDataOrders] = useState({})
    const [storeId, setStoreId] = useState('')
    const [detailOrder, setDetailOrder] = useState({})
    const [searchCode, setSearchCode] = useState('')
    const [searchKeyword, setSearchKeyword] = useState('')
    const [searchCreatedBy, setSearchCreatedBy] = useState('')
    const [searchBookingStatus, setSearchBookingStatus] = useState(0)
    const [searchDescription, setSearchDescription] = useState('')
    const [users, setUsers] = useState({})
    const [date, setDate] = useState([])
    const [activePage, setActivePage] = useState(1);
    const [page, setPage] = useState(1)
    const [listItemChecked, setListItemChecked] = useState([])
    const [popupCancel, setPopupCancel] = useState("")
    const [checkedList, setCheckedList] = useState(false)
    const [owedType, setOwedType] = useState(0)
    const [param, setParam] = useState([])
    const [showInforPopup, setShowInforPopup] =  useState(false)
    const [dataInfoCustomer, setDataInfoCustomer] =  useState({customers: {}})
    const [showloading, setShowloading] = useState(false)
    const [loadingShowClickItem, setLoadingShowClickItem] = useState(false)
    const [stores, setStore] = useState([])
    const [storeIds, setStoreIds] = useState([currentStore])
    const [userAdmin, setUserAdmin] = useState(JSON.parse(localStorage.getItem("user_info") ?? null))
    const [listOperator, setListOperator] = useState([]);
    const [isAscOperator, setIsAscOperator] = useState(false);
    const [widthScreen, setWidthScreen] = useState(window.innerWidth);
    const [openDetailMobile, setOpenDetailMobile] = useState(false);
    const [currentNameStore, setCurrentNameStore] = useState(localStorage.getItem("currentNameStore") ?? "Trần Duy Hưng");

    _state.storeId = storeId
    _state.setStoreId = setStoreId
    _state.param = param
    _state.setParam = setParam
    _state.showloading = showloading
    _state.setShowloading = setShowloading
    _state.setLoadingShowClickItem =  setLoadingShowClickItem
    _state.loadingShowClickItem = loadingShowClickItem

    _state.listOperator = listOperator
    _state.setListOperator = setListOperator

    _state.stores = stores
    _state.setStore = setStore

    _state.storeIds = storeIds
    _state.setStoreIds = setStoreIds

    let defaultRangeDate = [new Date(), addDays(new Date(), 1)]
    let ranges = [
        {
            label: "30 ngày trước",
            value: [startOfDay(subDays(new Date(), 30)), endOfDay(new Date())],
        },
        {
            label: "7 ngày trước",
            value: [startOfDay(subDays(new Date(), 7)), endOfDay(new Date())],
        },
        {
            label: "Hôm qua",
            value: [
                startOfDay(addDays(new Date(), -1)),
                endOfDay(addDays(new Date(), -1)),
            ],
        },
        {
            label: "Hôm nay",
            value: [startOfDay(new Date()), endOfDay(new Date())],
        },
        {
            label: "Ngày mai",
            value: [startOfDay(new Date()), endOfDay(addDays(new Date(), 1))],
        },
        {
            label: "3 ngày tới",
            value: [startOfDay(new Date()), endOfDay(addDays(new Date(), 3))],
        },
        {
            label: "7 ngày tới",
            value: [startOfDay(new Date()), endOfDay(addDays(new Date(), 7))],
        },
        {
            label: "30 ngày tới",
            value: [startOfDay(new Date()), endOfDay(addDays(new Date(), 30))],
        },
    ]

    const requestDataUsers = async () => {
        if (setUsers === undefined) return ''
        var selectDataOperators = [{ value: '0', label: 'Tên Nhân viên' },]
        isLoadingUser = true
        axiosService("api/stores/users/" + currentStore, "GET")
            .then((res) => {
                const response = res.data;
                for (const key in response.data) {
                    if (response.data[key].role == 2) {
                        selectDataOperators.push({
                            value: response.data[key].id,
                            label: response.data[key].name,
                        })
                    }
                }
                setUsers(selectDataOperators)
            })
            .catch((error) => console.log(error));
        setStoreId(currentStore)
        isLoadingUser = false
    }

    const apiListOperator = async () => {
        const response = await axiosService("api/users/store/" + currentStore + "?role=" + userAdmin.role, "GET")
        if (response.data.success !== true) return false;
        _state.setListOperator(response.data.data)
    }

    async function requestDataOrder(id = null) {
        if (setDataOrders === undefined) return ''
        isLoading = true;
        let storeIds =  _state.storeIds
        _state.setShowloading(true)
        var url = "api/order-list/" + currentStore + "?store_ids=" + storeIds + "&"
        if (_state.param[0]) {
            _state.param.map((item) => {
              url = url + item.key + "=" + item.value + "&"
            })
          }
        let response = await axiosGet(url);
        let dataOrders = response.data.data;
        _state.setLoadingShowClickItem(false)
        dataOrders.data.map(function (item) {
            if(item.id == id) setDetailOrder(item)
        })
    
        if (response.data.code == 200) {
            _state.setStoreId(currentStore)
            setDataOrders(dataOrders)
            _state.setShowloading(false)
        }
        isLoading = false;
    }
    
    async function requestDataStore(userAdmin = null) {
        let selectDataStore = []
        if (userAdmin && userAdmin.role == 4) {
            for (const key in userAdmin.stores) {
                let isChecked =  false
                if (userAdmin.stores[key].id == currentStore) {
                    isChecked = true
                }
                selectDataStore.push({
                    id: userAdmin.stores[key].id,
                    value: userAdmin.stores[key].name_store,
                    isChecked: isChecked,
                })
            }
        } else {
            let response = await axiosGet("api/stores/all");
            if (response.data.code == 200) {
                if (response.data.data !== null) {
                    for (const key in response.data.data) {
                        let isChecked =  false
                        if (response.data.data[key].id == currentStore) {
                            isChecked = true
                        }
                        selectDataStore.push({
                            id: response.data.data[key].id,
                            value: response.data.data[key].name_store,
                            isChecked: isChecked,
                        })
                    }
                }
            }
        }
        _state.setStore(selectDataStore)
    }
    
    function convertDateTime(event) {
        let date = new Date(event),
            month = date.getMonth() + 1,
            day = date.getDate(),
            hours = date.getHours(),
            minus = date.getMinutes();
        if (day < 10) day = '0' + day;
        if (month < 10) month = '0' + month;
        if (hours < 10) hours = '0' + hours;
        if (minus < 10) minus = '0' + minus;
        let text = day + '-' + month + '-' + date.getFullYear();
        text = text + ' ' + hours + ':' + minus;
        return text
    };
    
    useEffect(() => {
        requestDataStore(userAdmin)
        requestDataOrder()
        apiListOperator()

        const handleResize = () => {
            setWidthScreen(window.innerWidth);
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);

    if (storeId !== currentStore && !isLoadingUser) requestDataUsers()
    
    let orders = dataOrders
    if (orders.data === undefined) return ''
    if (users[0] == undefined) return ''

    function searchCodeEv(event) {
        setSearchCode(event.target.value)
        handleFilter("code", event.target.value)
    }

    function searchKeywordEv(event) {
        setSearchKeyword(event.target.value)
        handleFilter("keyword", event.target.value)
    }

    const updateDataOrders = (data) => {
        let listOrder = dataOrders.data;
        var index = _.findIndex(listOrder, {id: data.id});

        listOrder.splice(index, 1, {...listOrder[index], total_price: data.total_price});
        setDataOrders({
            ...dataOrders,
            data: listOrder
        })
    }

    const handleAllChecked = event => {
        let storesNew = [];
        let storesIds = [] 
        for(let item of stores) {
            item.isChecked = event.target.checked
            storesNew.push(item)
            if (event.target.checked == true) {
                storesIds.push(item.id)
            }
            
        }
        setStoreIds([...storesIds])
        setStore(storesNew);

    };
    
    function showDataFunction(val, key) {
        setDetailOrder(val)
        if (widthScreen <= 1199) setOpenDetailMobile(true)   
    }

    const handleCheckChieldElement = key => {
        stores[key].isChecked = !stores[key].isChecked
        let idStore = []
        stores.map((item, key) => {
            if (item.isChecked == true) {
                idStore.push(item.id)
            }
        })
        setStoreIds([...idStore])
        setStore([...stores]);
    };

    function searchCreatedByEv(event) {
        setSearchCreatedBy(event.value)
        handleFilter("createdBy", event.value)
    }

    function searchBookingStatusEv(event) {
        setSearchBookingStatus(event.value)
        handleFilter("status", event.value)

    }

    function searchDescriptionEv(event) {
        setSearchDescription(event.target.value)
        handleFilter("description", event.target.value)
    }

    function searchOwed(event) {
        setOwedType(event.value)
        handleFilter("owed_type", event.value)
    }

    const handleFilter = (key, val) => {
        var paramNew = param
        var filter = { key: key, value: val }
        var status = false
        paramNew.map((item) => {
          if (item.key == key) {
            item.value = val
            status = true
          }
        })
        if (status == false) {
          paramNew.push(filter)
        }
        setParam(paramNew)
      }

    function searchOrderData(event) {
        setActivePage(1)
        setPage(1)
        handleFilter("page", 1)
        requestDataOrder()
    }

    const showInfor = () => {
        setShowInforPopup(!showInforPopup)
      }
    

    const changeDateRange = (event) => {
        let startDate = ''
        let endDate = ''
        if (event) {
            startDate = [event[0].getFullYear(), ("0" + (event[0].getMonth() + 1)).slice(-2), ("0" + event[0].getDate()).slice(-2)].join('-');
            endDate = [event[1].getFullYear(), ("0" + (event[1].getMonth() + 1)).slice(-2), ("0" + event[1].getDate()).slice(-2)].join('-');
        }
        setDate([startDate, endDate])
        handleFilter("start", startDate)
        handleFilter("end", endDate)
        requestDataOrder()
    }

    const changePage = (event) => {
        setActivePage(event)
        setPage(event)
        handleFilter("page", event)
        requestDataOrder()
    }

    const handleListItem = (val, event) => {
        let newListItem
        const checked = event.target.checked
        if (checked === true) {
            listItemChecked.push(val.Id)
            // ... lấy hêt các phần tử ra
            newListItem = [...listItemChecked]
        } else {
            listItemChecked.slice(1, listItemChecked.indexOf(val.Id))
            newListItem = [...listItemChecked]
        }
        setListItemChecked(newListItem)
    }

    const showPopupCancel = (event) => {
        setPopupCancel("cancel")
    }
    const hiddenPopupCancel = (event) => {
        setPopupCancel("")
    }

    const showPopupDelete = (event) => {
        setPopupCancel("delete")
    }

    const handleCancelListItem = (value) => {

        // let type = value;
        // const data = {
        //     "PosIds": listItemChecked,
        //     "type": type
        // }
        // axiosService("api/pos/cancel", "POST", data)
        //     .then((res) => {
        //         const response = res.data;
        //         if (response.message == "success") {
        //             setPopupCancel("")
        //         }
        //     })
        //     .catch((error) => console.log(error));
        // requestDataOrder()
    }

    // const handleListItemAll = (event) => {
    //     let newListItem
    //     let checked = event.target.checked
    //     if (checked === true) {
    //         setCheckedList(true) 
    //         // for (const key in  dataOrders.data.bookings) {
    //         //     console.log(state.dataOrders.data.bookings)
    //         // }
    //         let bookings = dataOrders.data.bookings
    //         if (bookings) {
    //             for (const key in  bookings) {
    //                 listItemChecked.push(bookings[key].Id)
    //             }
    //         }
    //         newListItem = [...listItemChecked]
    //     }  else {
    //         setCheckedList(false)
    //         newListItem = []
    //     }
    //     setListItemChecked(newListItem)
    // }

    const removerFilter = (event)=> {
        window.location.reload()
    }

    const handleShowInfoCustomer = (data) => {
        dataInfoCustomer.customers = data
        setDataInfoCustomer(dataInfoCustomer)
        setShowInforPopup(true)
    }

    const handleExportExel = (event, type) => {  
        if (_state.loadingShowClickItem) return
        let type_export = 2
        setShowloading(true)
        if (type) {
            type_export = type
        }
        var url = "api/export/order-list/" + currentStore + "?type_export=" + type_export + "&" + "store_ids=" + storeIds + "&"
        if (param[0]) {
            param.map((item) => {
              url = url + item.key + "=" + item.value + "&"
            })
          }
        axiosService(url, "GET")
        .then((res) => {
            if (res.data.code == 200) {
                window.location.href = res.data.data.url
                setShowloading(false)
            }
        }).catch((error) => {
            console.log(error)
            setShowloading(false)
        });
    }

    const handleShowLoading = (e) => {
        setShowloading(false)
    }

    const enterKeyToSubmit = (e) => {
        if (e.key === 'Enter') {
            searchOrderData()
        }
    }

    const handleSortName = async () => {
        setListOperator(listOperator.reverse())
        setIsAscOperator(!isAscOperator)
    }
    

    return (
        <>
        <div className="admin-page orders">
            <div className="page-title">
                <div className="title-page">
                    <i className="fas fas-stack"></i> Danh sách đơn hàng
                </div>
                <div className="header-right">
                    {userAdmin && userAdmin.role != 4 ?
                        <div className="button-cent">
                            <Link to={{ pathname: "/pos" }}>
                                <span>
                                    <i className="fa fa-fas fa-calendar-alt"></i>
                                </span>
                                Đặt lịch
                            </Link>
                        </div>
                    : null}
                    <DateRangePicker
                        placement={"bottomEnd"}
                        size="sm"
                        style={{ height: 32, float: "right" }}
                        defaultValue={defaultRangeDate}
                        format={"dd-MM-yyyy"}
                        ranges={ranges}
                        onChange={changeDateRange}
                        showOneCalendar
                    />
                </div>
            </div>
            <div className="fixed-content-wrapper order-list-container ng-scope">
                <div className="order-advanced-search">
                    <form className="action-form">
                        <ul className="order-filter-block">
                            <li>
                                <span className="ng-binding">Mã HD</span>
                                <input onKeyDown={enterKeyToSubmit} value={searchCode} placeholder="Mã HD" onChange={searchCodeEv} className="form-control input-xs ng-pristine ng-untouched ng-valid ng-empty" />
                            </li>
                            <li>
                                <span className="ng-binding">Khách hàng</span>
                                <input onKeyDown={enterKeyToSubmit} value={searchKeyword} placeholder="Tên, email, điện thoại" onChange={searchKeywordEv} className="form-control input-xs ng-pristine ng-untouched ng-valid ng-empty" />
                            </li>
                            <li>
                                <span className="ng-binding">Nhân viên phục vụ</span>
                                <Select className="select" onChange={searchCreatedByEv} isSearchable={true} styles={users} defaultValue={users[0]} options={users} />
                            </li>
                            <li>
                                <span className="ng-binding">Trạng thái</span>
                                <Select className="select" onChange={searchBookingStatusEv} isSearchable={false} styles={orderStatus} defaultValue={orderStatus[0]} options={orderStatus} />
                            </li>
                            {/* <li>
                                <span className="ng-binding">Nhân viên phục vụ</span>
                                <Select className="select" isSearchable={false} styles={orderStatus} defaultValue={orderStatus[0]} options={orderStatus} />
                            </li> */}
                            {/* <li>
                                <span className="ng-binding">Tình trạng trả tiền</span>
                                <Select className="select" isSearchable={false} styles={orderStatus} defaultValue={orderStatus[0]} options={orderStatus} />
                            </li> */}
                            <li>
                                <span className="ng-binding">Ghi chú</span>
                                <input onKeyDown={enterKeyToSubmit} value={searchDescription} placeholder="Ghi chú" onChange={searchDescriptionEv} className="form-control input-xs ng-pristine ng-untouched ng-valid ng-empty" />
                            </li>
                            <li>
                                <span className="ng-binding">Tình trạng nợ</span>
                                <Select className="select"  onChange={searchOwed} isSearchable={false} styles={OwedType} defaultValue={OwedType[0]} options={OwedType} />
                            </li>
                            {userAdmin && userAdmin.role != 2 ?
                                <li>
                                    <DropdownButton id="dropdown-basic-button" title="Chọn cơ sở hiển thị">
                                        <div className="list-store-select">
                                            <p>
                                                <input
                                                type="checkbox"
                                                onChange={handleAllChecked}
                                                value="checkedall"
                                                /> {" "}Check / Uncheck All
                                            </p>
                                            { stores.length > 0 ? 
                                                stores.map((store, key) => (
                                                    <p key={key}>
                                                        <input
                                                        onChange={() => handleCheckChieldElement(key)}
                                                        type="checkbox"
                                                        value={store.value}
                                                        checked={store.isChecked}
                                                        />{" "}
                                                        {store.value}
                                                    </p>
                                                )) 
                                            : ''}
                                        </div>
                                    </DropdownButton>
                                </li>
                            : null}
                            

                            {/* <li>
                                <span className="ng-binding">Sử dụng voucher</span>
                                <Select className="select" isSearchable={false} styles={orderStatus} defaultValue={orderStatus[0]} options={orderStatus} />
                            </li> */}
                            {/* <li>
                                <span className="ng-binding">Sử dụng thẻ dịch vụ</span>
                                <Select className="select" isSearchable={false} styles={orderStatus} defaultValue={orderStatus[0]} options={orderStatus} />
                            </li> */}
                            {/* <li>
                                <span className="ng-binding">Tiền tip</span>
                                <Select className="select" isSearchable={false} styles={orderStatus} defaultValue={orderStatus[0]} options={orderStatus} />
                            </li> */}
                        </ul>
                        <button onClick={removerFilter} type="button" className="btn-xs btn btn-default pull-left mr-10 button-action"><i className="fas fa-times"></i> Bỏ lọc</button>
                        <button type="button" onClick={searchOrderData} className="btn-xs btn btn-primary pull-left button-action"><i className="fa fa-filter"></i> Tìm kiếm</button>
                    </form>

                </div>
                <div className="row order-list">
                    <div className="fixed-two-col-width-6">
                        <div className="panel title-panel-flat max-height">
                            <div className="panel-heading">
                                <span className="text-bold font-14 ng-binding">Danh sách đơn hàng </span>
                                <span className="text-14 text-primary">
                                    <span className="text-bold ng-binding">{dataOrders.itemCount} đơn hàng </span>
                                    - Tổng: {convert.convertCurrency(dataOrders.totalPrices ?? 0)}
                                    <span className="cash-vnd ng-binding " style={{color: "#259B9A"}}> - Sd tiền cọc: {convert.convertCurrency(dataOrders.depositTotal)} </span>
                                    <span className="cash-vnd ng-binding " style={{color: "rgb(126, 55, 148)"}}> - Sd tích luỹ: {convert.convertCurrency(dataOrders.giftCashTotal)} </span>
                                    <span className="cash-vnd cl-red "> - Còn nợ : {convert.convertCurrency(dataOrders.totalOwed)} </span>
                                </span>
                                <div className="pull-right btn-group" >
                                    <DropdownButton id="dropdown-basic-button" title="Xuất csv">
                                            <Dropdown.Item className="item-dropdown" onClick={(e)=>handleExportExel(e, 1)}>Hoá đơn</Dropdown.Item>
                                            <Dropdown.Item className="item-dropdown"  onClick={(e)=>handleExportExel(e, 2)}>Chi tiết hoá đơn</Dropdown.Item>
                                            <Dropdown.Item className="item-dropdown"  onClick={(e)=>handleExportExel(e, 4)}>Chi tiết đơn hàng thanh toán nợ</Dropdown.Item>
                                            <Dropdown.Item className="item-dropdown"  onClick={(e)=>handleExportExel(e, 3)}>Lịch sử giao dịch</Dropdown.Item>
                                            <Dropdown.Item className="item-dropdown"  onClick={(e)=>handleExportExel(e, 5)}>Chi tiết com sale và tvv</Dropdown.Item>

                                    </DropdownButton>
                                </div>
                            </div>

                        </div>
                        <div className="panel-body">
                            <div className="alert-warning m-10">
                                {/* <label className="checkbox-inline">
                                    <input type="checkbox" onChange={(event) => handleListItemAll(event)}  className="css-checkbox ng-pristine ng-untouched ng-valid ng-empty" />
                                </label>
                                <button className="btn btn-xs ml-10 btn-danger" onClick={showPopupCancel}><i className="fa fa-trash"></i>Huỷ</button>
                                <button className="btn btn-xs ml-10 btn-danger" onClick={showPopupDelete}><i className="fa fa-trash"></i>Xoá</button> */}
                            </div>
                            <div className="DataTables_Table_0_wrapper dataTables_wrapper no-footer">
                                <div className="table-list-table">
                                    <table>
                                        <thead>
                                            <tr role="row">
                                                {/* <th className="sorting" ></th> */}
                                                <th className="sorting" style={{width: "54px"}}>Mã HD</th>
                                                <th className="sorting_asc" style={{width: "150px"}}>Khách hàng</th>
                                                <th className="sorting" style={{width: "76px"}} >Ngày tạo</th>
                                                <th className="has-tooltip-info sorting" style={{width: "84px"}} >Người lập</th>
                                                <th className="sorting" style={{width: "76px"}} >Số tiền</th>
                                                <th className="sorting" style={{width: "76px"}} >Đã thu</th>
                                                <th className="sorting" style={{width: "72px"}}>Trạng thái</th>
                                                <th className="sorting_disabled" style={{width: "59px"}}></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {dataOrders.data ?
                                                dataOrders.data.map((val, key) => (
                                                    <tr key={key} role="row" className="odd selected" onClick={() => showDataFunction(val, key)}>
                                                        {/* <td className="ng-scope">
                                                            <label className="checkbox-inline">
                                                                <input type="checkbox" checked={checkedList} onChange={(event) => handleListItem(val, event)} className="css-checkbox ng-pristine ng-untouched ng-valid ng-not-empty" />
                                                            </label>
                                                        </td> */}
                                                        <td className="ng-scope"><span>{val.order_code}</span></td>
                                                        <td className="ng-scope sorting_1 cl-blue">
                                                            <span onClick={()=>handleShowInfoCustomer(val.customers)}>{val.customers.full_name}</span>{val.customers?.identifier === 1 ? <span className="identifier-customer"><img src="/icons/verified.png" /></span> : ""}<br />
                                                            <span className="font-10 cl-gray"><i className="icon-phone position-left"></i>{val.customers.mobile}</span>
                                                        </td>
                                                        <td className="ng-scope">{convertDateTime(val.order_at)} </td>
                                                        <td className="has-tooltip-info ng-scope cl-blue">{val.User ? val.User.name : val.created_name}</td>
                                                        <td className="has-tooltip-info ng-scope cl-green">{convert.convertCurrency(val.total_price ?? 0)}</td>
                                                        <td className="has-tooltip-info ng-scope cl-blue">{convert.convertCurrency(val.payByOther)}</td>
                                                        <td className="has-tooltip-info ng-scope booking-status"><span className={orderStatusClass[val.status]}>{orderStatusOject[val.status]}</span></td>
                                                        <td className="ng-scope">
                                                            <span className="payment_type_icons">{val.payment_type}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                ))
                                                : <tr></tr>}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="dataTables_info" id="DataTables_Table_0_info" >Hiện {(activePage - 1) * dataOrders.takeRecord + 1} đến {activePage * dataOrders.takeRecord} của {dataOrders.itemCount} dữ liệu</div>
                                {dataOrders.data ?
                                    <div className="dataTables_paginate paging_first_last_numbers" id="DataTables_Table_0_paginate">
                                        <Pagination
                                            prev
                                            last
                                            next
                                            first
                                            size="xs"
                                            total={dataOrders.itemCount}
                                            limit={dataOrders.takeRecord}
                                            activePage={activePage}
                                            maxButtons={5}
                                            onChangePage={changePage}
                                        />
                                    </div>
                                    : ""}
                            </div>
                        </div>
                    </div>
                    {widthScreen >= 1200 || openDetailMobile ?
                        <div className="fixed-two-col-width-4 ng-scope block-ui block-ui-anim-fade">
                            <div className="panel title-panel-flat max-height">
                                <div className="panel-heading">
                                    <span className="text-bold font-14 ng-binding">Chi tiết đơn hàng</span>
                                </div>
                                <div className="status-detail ng-binding">Trạng thái:
                                    <span className={orderStatusClass[detailOrder.status]}>{detailOrder ? orderStatusOject[detailOrder.status] : ""}</span>
                                </div>
                            </div>
                            {detailOrder.id && typeof detailOrder.id != "undefined"?
                                <OrderDetailRight
                                    userAdmin={userAdmin}
                                    requestDataOrder={requestDataOrder}
                                    orderDetail={detailOrder} 
                                    listOperator = {_state.listOperator}
                                    handleSortName= {handleSortName}
                                    isAscOperator={isAscOperator}
                                    currentStore={currentStore}
                                    currentNameStore={currentNameStore}
                                    setDetailOrder={setDetailOrder}
                                    updateDataOrders={updateDataOrders}
                                />
                            : null}
                        </div>
                    : null}
                    {openDetailMobile ? <div className="overlay-detail" onClick={() => setOpenDetailMobile(false)}></div> : null}
                </div>
            </div>
            {popupCancel == "cancel" || popupCancel == "delete" ?
                <div className="order-popup">
                    <div className="popup-cent">
                        <div className="content-popup fadeInDown">
                            <div className="head-popup">
                                <div className="title">Chi tiết đơn hàng </div>
                            </div>
                            <div className="body-popup">
                                <p>Bạn chắc chắn muốn {popupCancel == "cancel" ? "Huỷ" : "Xoá"} tất cả hàng được chọn không?</p>
                            </div>
                            <div className="bottom-popup">
                                <div className="button-container">
                                    <div onClick={hiddenPopupCancel} className="button-cent btn-close">
                                        <span><i className="fas fa-times"></i></span>Đóng
                                    </div>
                                    <div className="button-cent" onClick={() => handleCancelListItem(popupCancel)}>
                                        <span><i className="fa fa-check-square"></i></span>Có
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div onClick={hiddenPopupCancel} className="overlay"></div>
                    </div>
                </div>
            : ""}
        </div>
        {showInforPopup && <InforUserPopup open={showInforPopup} setShow={showInfor}  data={dataInfoCustomer} />}
        <Loading
            show={showloading}
          ></Loading>
        </>
    );
}

export default Orders;
