import React, { useEffect, useState } from "react";
import axiosService from "../../utils/axios.service";
import { Container, Row, Col } from "react-bootstrap";
import ChildOrder from "./ChildOrder"
import styles from "../../styles/popupInfor.module.css"
const Transactin = ({customer}) => {
  const [orders, setOrders] = useState([])
  const getData = async () => {
    const res = await axiosService("api/customer/order/"+customer.id, "GET");
    const { data } = res
    if (data.code === 200) {
      const { dataMadeOrder } = data.data
      setOrders(dataMadeOrder.Data)
    }
  }
  useEffect(() => {
    getData()
  }, [])
  return (
    <>
      <Container className={styles.scrollTransaction}>
        {orders.length > 0
          ? <>
            {orders.map((x, i) => {
              return <ChildOrder key={i} order={x} customer={customer}  />
            })}
          </>
          : "Không có thông tin"
        }
      </Container>
    </>
  )
}
export default Transactin