export default [
    {lable:"Doanh số theo ngày",route:"daily",icon:"fa-solid fa-chart-bar"},
    {lable:"Theo chi nhánh",route:"locations",icon:"fa-solid fa-location-dot"},
    {lable:"Báo cáo tư vấn Online",route:"online",icon:"fa-solid fa-user-large"},
    {lable:"Báo cáo lễ tân",route:"offline-lt",icon:"fa-solid fa-user-group"},
    {lable:"Báo cáo tư vấn & phục vụ offline",route:"offline-ktv-tvv",icon:"fa-solid fa-user-large"},
    {lable:"Tiền thu từng dịch vụ",route:"service-detail",icon:"fa-solid fa-tags"},
    {lable:"Hiệu quả của promotion",route:"promotion",icon:"fa-solid fa-tags"},
    {lable:"Chi tiết promotion",route:"detail-promotion",icon:"fa-solid fa-tags"},



     // {lable:"Theo khách hàng",route:"customers",icon:"fa-solid fa-user-large"},
    // {lable:"Theo nhân viên",route:"users",icon:"fa-solid fa-user-group"},
    // {lable:"Theo thu ngân",route:"cashiers",icon:"fa-solid fa-calculator"},
    // {lable:"Theo sản phẩm/ dịch vụ",route:"products",icon:"fa-solid fa-tags"},
    // {lable:"Theo nhân viên đặt lịch",route:"sales",icon:"fa-solid fa-tags"},
]