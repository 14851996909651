import React, { Component } from "react";
import "./scss/boostrap.min.css"
import "./App.css";
import { Route, BrowserRouter as Router } from "react-router-dom";
import routes from "./routes";
import ProtectedRoute from "./components/ProtectedRoute";
import Admin from "./components/Admin";
import Booking from "./components/booking/Booking";
import Customer from "./components/customer/Customer";
import ReportByKTV from "./components/report-by-day/ReportByKTV";
import '@fortawesome/fontawesome-free/css/all.min.css';
import ReportKtvTvvOfflineDetail from "./components/report/ReportKtvTvvOfflineDetail";
import CustomerCountPackageBooking from "./components/report/CustomerCountPackageBooking";
import ChangePassword from "./components/ChangePassword";
import './utils/i18n';
// import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-notifications/lib/notifications.css';
class App extends Component {
  render() {
    return (
      <Router>
        <Route path={"/"} exact={true} component={Admin} />
        <Route path={"/booking"} exact={false} component={Booking} />
        <Route path={"/report-by-ktv"} exact={false} component={ReportByKTV} />
        <Route path={"/change-password"} exact={false} component={ChangePassword} />
        {/* <Route path={"/customer"} exact={false} component={Customer} /> */}
        <Route path={"/report/detail/ktv-tvv"} exact={false} component={ReportKtvTvvOfflineDetail} />
        <Route path={"/report/count/package-booking"} exact={false} component={CustomerCountPackageBooking} />

        {this.showContent(routes)}
      </Router>
    );
  }

  showContent = (routes) => {
    let result = null;
    if (routes.length > 0) {
      result = routes.map((route, index) => {
        return (
          <ProtectedRoute
            key={index}
            path={route.path}
            exact={route.exact}
            component={route.main}
          />
        );
      });
    }

    return result;
  };
}

export default App;
