import { configureStore } from '@reduxjs/toolkit'
import VoucherSlice from "./reducers/voucher"
import getOptions from "./reducers/options"

const store = configureStore({
    reducer: {
        vouchers: VoucherSlice,
        getOptions: getOptions
    },
})
export default store
