import React, { useEffect, useState, useLayoutEffect } from "react";
import { Row, Col } from "react-bootstrap"
import Header from "./Header"
import { Input} from 'antd';
import styles from "./style.module.css"
import { Tag } from "rsuite"
import { notifications } from "../constant/Notify";
import { format, startOfMonth } from "date-fns"
import axiosService from "../../utils/axios.service";
import { Bar } from '@ant-design/plots';
import "./report.scss"
import { Table, Spin, Tabs } from 'antd';
import async from "async"
import 'antd/dist/antd.min.css'
import StorageService from "../../utils/storage.service";
import Edit from "./Edit";
import TabReport from "./TabReport";

import {
    optionsSourceFrom, optionsSourceFromShow
  } from './constant/constant'
const { TabPane } = Tabs;

const ListDataSaleOnline = () => {
    const [title, setTiltle] = useState("")
    const [startDate, setStartDate] = useState(format(new Date(startOfMonth(new Date())), "yyyy-MM-dd 00:00"))
    const [endDate, setEndDate] = useState(format(new Date(), 'yyyy-MM-dd 23:59'))
    const [status, setStatus] = useState(null)
    const [hour, setHour] = useState('All')
    const [minute, setMinute] = useState('All')
    const [store, setStore] = useState(0)
    const [isExport, setIsExport] = useState(false)
    const [loading, setLoading] = useState(true);
    const [chart, setChart] = useState([])
    const [table, setTable] = useState([])
    const [keyword, setKeyword] = useState([])
    const [dataSaleOnlineId, setDataSaleOnlineId] = useState(null)
    const [dataEditCode, setDataEditCode] = useState(null)
    const [totalWelcome, setTotalWelcome] = useState(0)
    const [showEdit, setShowEdit] = useState(false)
    const [dataEdit, setDataEdit] = useState({})
    const [userAdmin, setUserAdmin] = useState(JSON.parse(StorageService.getItem("user_info")))
    const [listUser, setListUser] = useState([])
    const [aov, setAov] = useState([])
    const [selectUser, setSelectUser] = useState(0)
    useEffect(() => {
        setTiltle("Báo cáo lễ tân")
    }, [])
    const hanldeRangeDate = (sDate, eDate) => {
        setStartDate(sDate)
        setEndDate(eDate)
    }
    const hanldeStore = (x) => {
        setStore(x)
    }
    const fetchData = async (sD, eD, s) => {
        const { users, data } = await async.parallel({
            users: (cb) => {
                axiosService(`api/users/store/1?role=5`, "GET").then(rs => {
                    const { data } = rs
                    cb(null, data)
                })
            },
            data: (cb) => {
                axiosService(`api/sale/online/all?start=${sD}&end=${eD}&store_id=${store}&keyword=${keyword}&status=${status}&hour=${hour}&minute=${minute}&user_id=${selectUser}`, "GET").then(rs => {
                    const { data } = rs
                    cb(null, data)
                })
            }
        })
        if (data.code === 200) {
            setLoading(false)

            const {list, total, aov} = data.data
            // // setChart(dataChart)
            // setTotalBooking(totalBooking)
            // setTotalComBooking(totalComBooking)
            setTotalWelcome(total)
            // setTotalRoseWelcome(totalRoseWelcome)
            setTable([...list])
            setAov([...aov])
        }

        if (users.code === 200) {
            var optionUser = [{value: 0, label:"All"}]
            for (let item of users.data) {
                let row = {
                    value: item.id,
                    label: item.name
                }
                optionUser.push(row)
            }
            setListUser([...optionUser])
        }

    }
    useLayoutEffect(() => {
        setLoading(true)
        fetchData(startDate, endDate, store, status, hour, minute)
    }, [startDate, endDate, store, keyword, status, hour, minute, selectUser])

    const config = {
        data: chart,
        isStack: true,
        xField: 'value',
        yField: 'name',
        seriesField: 'type',
        label: false,
        xAxis: {
            label: {
                formatter: (v) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
            },
        },
        tooltip: {
            formatter: (data) => {
                return {
                    name: data.name,
                    value: convertCurrency(data.value)
                };
            },
        }
    };
    function convertCurrency(data) {
        let res = new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(data)
        return res;
    };

    function convertDate(data) {
        let res = ''
        let date = new Date(data)
        let book_hour = ("0" + date.getHours()).slice(-2),
            book_minute = ("0" + date.getMinutes()).slice(-2);
        if (data) {
            res = format(new Date(data), 'yyyy-MM-dd') + " " + [book_hour, book_minute].join(":")
        }
        return res;
    };
    const columns = [
        {
            title: 'Trạng thái',
            dataIndex: 'report-data-sale-online_status',
            render: (data) => {
                return (
                    <>
                        <p className={data != 'Lead' ? (data == 'Pre order' ? 'text4' : 'text2'): 'text1'}>{data}</p>
                    </>
                )
            },
            // sorter: (a, b) => a.countOrder.length - b.countOrder.length,
        },
        {
            title: 'Ngày lên data',
            dataIndex: 'report-data-sale-online_online_date_start',
            align: "center",
            render: (data) => {
                return (
                    <p className="">{convertDate(data)}</p>
                )
            },
        },
        {
            title: 'Cơ sở',
            dataIndex: 'report-data-sale-online_store_name',
            align: "center",
            render: (data) => {
                return (
                    <p className="text1">{data}</p>
                )
            },
        },
        {
            title: 'Họ và tên',
            dataIndex: 'report-data-sale-online_nick_name',
            align: "center",
            render: (data) => {
                return (
                    <p className="text1">{data}</p>
                )
            },
        },
        {
            title: 'SDT',
            dataIndex: 'report-data-sale-online_customer_mobile',
            align: "center",
            render: (data) => {
                return (
                    <p className="text1">{data}</p>
                )
            },
        },
        {
            title: 'Nhóm dịch vụ',
            dataIndex: 'onlineItem_category_product_name',
            align: "center",
            render: (data) => {
                return (
                    <p className="text1">{data}</p>
                )
            },
        },
        {
            title: 'Dịch vụ',
            dataIndex: 'onlineItem_product_name',
            align: "center",
            render: (data) => {
                return (
                    <p className="">{data}</p>
                )
            },
        },
        {
            title: 'Ngày chốt',
            dataIndex: 'report-data-sale-online_online_date_end',
            align: "center",
            render: (data) => {
                return (
                    <p className="text3">{convertDate(data)}</p>
                )
            },
        },
        {
            title: 'Đã thanh toán',
            dataIndex: 'onlineItem_paid_price',
            align: "center",
            render: (data) => {
                return (
                    <p className="text1">{convertCurrency(Number(data))}</p>
                )
            },
        }, {
            title: 'Doanh số dự kiến',
            dataIndex: 'onlineItem_total_price',
            align: "center",
            render: (data) => {
                return (
                    <p className="text1">{convertCurrency(Number(data))}</p>
                )
            },
        }, {
            title: 'Nguồn',
            dataIndex: 'report-data-sale-online_source_from',
            align: "center",
            render: (data) => {
                return (
                    <p className="text6">{optionsSourceFromShow[data]}</p>
                )
            },
        }, {
            title: 'Tư vấn viên',
            dataIndex: 'report-data-sale-online_sale_by_name',
            align: "center",
            render: (data) => {
                return (
                    <p className="text1">{data}</p>
                )
            },
        }, {
            title: 'Loại khách hàng',
            dataIndex: 'report-data-sale-online_status_customer',
            align: "center",
            render: (data) => {
                return (
                    <p className="text1">{data}</p>
                )
            },
        },{
            title: 'Mã hoá đơn',
            dataIndex: ['report-data-sale-online_order_code', 'report-data-sale-online_order_id', 'report-data-sale-online_id'],
            align: "center",
            render:  (text, record) => {
                 
                    return (
                         <>
                            {dataSaleOnlineId && dataSaleOnlineId == record['report-data-sale-online_id']?
                                 <p className="text4">
                                    <Input onChange={onChangeOrderCode} type="text" value={dataEditCode} defaultValue={dataEditCode} /><i onClick={(e) => saveEditOrderCode(record)} style={{cursor: 'pointer'}} className="fas fa-save"></i>
                                 </p> : 
                                 
                                 <p className="text1" style={{cursor: 'pointer'}} onClick={(e) => onClickOrderCode(record)}><span><i className="fas fa-edit"></i>{record['report-data-sale-online_order_code']}</span></p>
                            }
                         </>
                    )
            },
        },{
            title: 'Action',
            dataIndex: '',
            align: "center",
            render: (text, record) => {
                return (
                    <div className="button-container-list">
                        <div className="button-cent style3" onClick={(e) => clickDeleteLead(record)}>
                            <span><i className="fas fa-times"></i></span>Delete
                        </div>
                        <div className={"button-cent"} onClick={(e) => clickEditLead(record)}>
                            <span><i className="fas fa-save"></i></span>Edit
                        </div>
                    </div>
                )
            },
        }
    ];

    const onChangeOrderCode = (e) => {
        setDataEditCode(e.target.value)
    }

    const clickDeleteLead = async (data) => {
        let res = await axiosService("api/sale/online/delete/" + data['report-data-sale-online_id'], "POST", data)
        if (res.data.code == 200) {
            fetchData(startDate, endDate, store, status, hour, minute)
            notifications({
                type: "success",
                mess: "Xoa thành công",
            });
        } else {
            notifications({
                type: "error",
                mess: "Đã có lỗi xảy ra, vui lòng thử lại sau",
            }) 
        };
    }

    const saveEditOrderCode = async (data) => {
        if (dataEditCode && dataEditCode.length > 5 ) {
            let res = await axiosService("api/sale/online/pre-order/" + data['report-data-sale-online_id'] + '?order_code=' + dataEditCode, "POST", data)
            if (res.data.code == 200) {
                fetchData(startDate, endDate, store, status, hour, minute)
                notifications({
                    type: "success",
                    mess: "Tạo Pre order thành công",
                });
            } else {
                notifications({
                    type: "error",
                    mess: "Đã có lỗi xảy ra, vui lòng thử lại sau",
                });
            }
        }
    }

    const onClickOrderCode = (data) => {
        setDataEditCode(data['report-data-sale-online_order_code'])
        setDataSaleOnlineId(data['report-data-sale-online_id'])
    }

    const hanldeExportData = () => {
        setIsExport(true)
        setTimeout(() => {
            setIsExport(false)
        }, 2000)
    }
    const hanldeCreateDataSale = (data) => {

    }
    const onChangeKeyword = (e) => {
        setKeyword(e)
    }
    const addLead = async (data) => {
        let res = await axiosService("api/sale/online", "POST", data)
        if(res.data.code == 200) {
            notifications({
                type: "success",
                mess: "Tạo Lead thành công",
            });
            fetchData(startDate, endDate, store, status, hour, minute)
        } else {
            notifications({
                type: "error",
                mess: "Đã có lỗi xảy ra, vui lòng thử lại sau",
            });
        }
    }

    const headerChangeStatus = (e) => {
        setStatus(e)
    }

    const headerChangeHour = (e) => {
        setHour(e)
    }

    const headerChangeMinute = (e) => {
        setMinute(e)
    }

    const clickEditLead = (data) => {
        setDataEdit(data)
        setShowEdit(true)
    }

    const handleShowEdit = () => {
        setShowEdit(!showEdit)
    }

    const editLead = async (data) => {
        let res = await axiosService("api/sale/online/update/" + dataEdit['report-data-sale-online_id'], "POST", data)
        if(res.data.code == 200) {
            fetchData(startDate, endDate, store, status, hour, minute)
            notifications({
                type: "success",
                mess: "Edit Lead thành công",
            });
        } else {
            notifications({
                type: "error",
                mess: "Đã có lỗi xảy ra, vui lòng thử lại sau",
            });
        }
    }

    function callback(key) {
        console.log(key);
    }
    return (
        <div className={`p-2 ${styles.background}`}>
            <Header startDate={startDate} endDate={endDate}
                hanldeRangeDate={hanldeRangeDate} store={store}
                hanldeStore={hanldeStore}
                hanldeCreateDataSale={hanldeCreateDataSale}
                onChangeKeyword={onChangeKeyword}
                addLead={addLead}
                headerChangeStatus={headerChangeStatus}
                headerChangeHour={headerChangeHour}
                headerChangeMinute={headerChangeMinute}
                listUser={listUser}
                setSelectUser={setSelectUser}
                hanldeExportData={hanldeExportData}
                sortBy={0}
                title={title} type={"sales"}
                csvData={table} isExport={isExport}
                header={
                    [
                        { label: 'Trạng thái', key: "report-data-sale-online_status" },
                        { label: "Ngày lên data", key: "report-data-sale-online_online_date_start" },
                        { label: "Cơ sở", key: "report-data-sale-online_store_name" },
                        { label: "Nick name", key: `report-data-sale-online_nick_name` },
                        { label: "Tên khách hàng", key: `report-data-sale-online_customer_name` },
                        { label: "Số điện thoại", key: `report-data-sale-online_customer_mobile` },
                        { label: "Nhóm dịch vụ", key: `onlineItem_category_product_name` },
                        { label: "Dịch vụ", key: `onlineItem_product_name` },
                        { label: "Ngày chốt", key: `report-data-sale-online_online_date_end` },
                        { label: "Đã thanh toán", key: `onlineItem_paid_price` },
                        { label: "Doanh số dự kiến", key: `onlineItem_total_price` },
                        { label: "Nguồn", key: `report-data-sale-online_source_from` },
                        { label: "Tư vấn viên", key: `report-data-sale-online_sale_by_name` },
                        { label: "Loại khách hàng", key: `report-data-sale-online_status_customer` },
                        { label: "Mã hoá đơn", key: `report-data-sale-online_sale_by_name` },
                    ]
                }
                userAdmin={userAdmin}
            />
            <Spin spinning={loading} tip="Đang tải...">
                <Row className="p-2">
                    <Col xs={12} md={8}>
                        <Row className="h-100">
                            <Col xs={12} md={3}>
                                <Tag color="blue" className="w-100 p-2 h-100">
                                    <span className={styles.textTag3}>Doanh sô dự kiến: </span>
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <span className={styles.textTag}> </span>
                                            <br />
                                            <br />
                                        </div>
                                        <div className="ml-3">
                                            <span className={styles.textTag}>
                                                {convertCurrency(totalWelcome)}</span>
                                            <br />
                                        </div>
                                    </div>
                                </Tag>
                            </Col>

                        </Row>
                    </Col>
                </Row>
                <Row className="p-2">
                    {/* <Col xs={4} className="p-2">
                        <div style={{ backgroundColor: "white" }} className="p-2">
                            <div className="border-bottom w-100 mb-2">
                                Biểu đồ nhân viên đặt lịch
                            </div>
                            <Bar {...config} />
                        </div>
                    </Col> */}
                    <Col xs={12} className="p-2">
                        <Tabs defaultActiveKey="1" onChange={callback}>
                            <TabPane tab="Bảng số liệu chi tiết" key="1">
                                <div style={{ backgroundColor: "white" }} className="p-2">
                                    <div className="border-bottom w-100 mb-2">
                                        Bảng số liệu chi tiết
                                    </div>
                                    <Table 
                                        columns={columns} 
                                        dataSource={table}
                                        // pagination={{ pageSize: 10 }} 
                                        scroll={{ x: 1000 }}
                                        bordered
                                    />
                                </div>
                            </TabPane>
                            <TabPane tab="Thống kê" key="2">
                                <div style={{ backgroundColor: "white" }} className="p-2">
                                    <TabReport
                                        table = {aov}
                                        startDate={startDate}
                                        endDate={endDate}
                                        userId={1}
                                        userName={'admin'}
                                    >
                                    </TabReport>
                                </div>
                            </TabPane>
                        </Tabs>
                    </Col>
                </Row>
            </Spin>
            {showEdit ?
                 <Edit
                 show={showEdit}
                 close={handleShowEdit}
                 editLead={editLead}
                 dataEdit={dataEdit}
                >
                </Edit>
            : null}
        </div>
    )
}
export default ListDataSaleOnline