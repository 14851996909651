import React, { useState, useLayoutEffect, useRef } from "react";
import { DatePicker } from "rsuite";
import Select from "react-select";
import { optionsDays, optionsMonths, } from "../customer/constant/CustomerBirthday";
import { posCustomer, customStylesSelect } from "./constant/PosCustomer";
import axiosService from "../../utils/axios.service";
import Avatar from 'react-avatar';
import * as convert from "../constant/Convert.helper"
import _ from 'lodash';
import location from "../../context/vietnam.json";
import { Spin, Checkbox } from "antd"
import { isBefore, subDays } from "date-fns";

const fields = posCustomer;

const PosOrderInformation = (props) => {
  const [statusSearchCustomer, setStatusSearchCustomer] = useState(false);
  const [dataSearchCustomer, setDataSearchCustomer] = useState({});
  const [orderData, setOrderData] = useState(props.orderData)
  const [packageCode, setPackageCode] = useState([])
  const [spinLoadCustomer, setSpinLoadCustomer] = useState(false)

  const selectDistrictRef = useRef(null);

  const searchCustomer = (event) => {
    let keyword = event.target.value;

    if (keyword.length >= 2)
      axiosService("api/customer?keyword=" + keyword, "GET")
        .then((res) => {
          const response = res.data;
          setDataSearchCustomer(response.data.data ?? []);
          setStatusSearchCustomer(true);
        })
        .catch((error) => console.log(error));
  };

  const getDetailCustomer = (data) => {
    if (orderData.customers.id !== data.id) {
      let listPackageCode = []
      for (const [key, value] of Object.entries(props.itemSelected)) {
        listPackageCode.push(value.package_code)
      }
      setPackageCode(listPackageCode)
    }
    
    orderData.customers = data;
    orderData.status = '3';
    props.funcUpdateOrder(orderData);
    setStatusSearchCustomer(false);
    setOrderData(orderData)
  };

  const dataHandle = (item, key, actionMeta = "") => {
    if (actionMeta === "customer") {
      orderData.customers[key] = item;
    }
    if (key === "Notes") orderData.description = item;

    props.funcUpdateOrder(orderData);
    setOrderData(orderData)
  };
  const onChangeIdentifier = (e) => {
    orderData.customers.identifier = e.target.checked ? 1 : 0;
    props.funcUpdateOrder(orderData);
    setOrderData(orderData)
  }
  const changeMobile = _.debounce(async (item) => {
    let keyword = item;
    if(keyword && keyword != '' && keyword.length > 0) keyword = keyword.replace(/\s/g, '').toLowerCase();

    if (keyword.length == 10) {
      setSpinLoadCustomer(true)
      let dataCustomer = await axiosService("api/customer?keyword=" + keyword, "GET");
      dataCustomer = dataCustomer.data.data.data
      if (dataCustomer.length == 1) await getDetailCustomer(dataCustomer[0])
      setSpinLoadCustomer(false)
    }

    orderData.customers.mobile = keyword;
    props.funcUpdateOrder(orderData);
    setOrderData(orderData)
  }, 1000);

  const selectDataHandle = (item, key) => {
    if (item) {
      orderData.customers[key] = item.label;
      if (key == "city") {
        orderData.customers.district = '';
        let keyCity = item.value;
        if (keyCity > 0) {
          keyCity = _.findIndex(location, (o) => o.value == keyCity);
        }
        selectDistrictRef.current.clearValue();
      }
  
      if (key == "district") {
        let keyDistrict = item.value;
        if (keyDistrict > 0) {
          keyDistrict = _.findIndex(props.dataDistrict, (o) => o.value == keyDistrict);
        }

      }
  
      props.funcUpdateOrder(orderData);
      setOrderData(orderData)
    }
  }

  const onChangeDateBooking = (event) => {
    orderData.order_at = event;

    props.funcUpdateOrder(orderData);
    setOrderData(orderData)
  };

  const saveDataCustomer = () => {
    let dataShowHideForm = { target: { name: "currentPage", value: "index" } };
    orderData.status = '4';
    orderData.customers.birthday = '';
    if (orderData.customers.year_birthday && orderData.customers.month_birthday && orderData.customers.day_birthday)
      orderData.customers.birthday = [orderData.customers.year_birthday, ("0" + orderData.customers.month_birthday).slice(-2), ("0" + orderData.customers.day_birthday).slice(-2)].join('-');

    setOrderData(orderData)
    props.showHideForm(dataShowHideForm);

    let itemSelected = props.itemSelected;
    if (packageCode.length > 0) {
      packageCode.map((item) => { if (itemSelected[item]) itemSelected[item].quantity = 0 })
    }

    props.getDataTransaction(orderData.customers.id)
    props.onUpdateItems(itemSelected);
    // props.actionOrder((orderData.id) ? "update" : "create")
  };

  const changeStatusCustomer = (e) => {
    orderData.status = e;
    if (e === '3') orderData.customers = { gender: 2 };
    props.funcUpdateOrder(orderData);
    setOrderData(orderData)
  };

  useLayoutEffect(() => {
    if (props.data != "") {
      getDetailCustomer(props.data)
    }
  }, [props.data, props.currentPage])

  return (
    <div
      className="information-customer-order"
      onClick={() => setStatusSearchCustomer(false)}
    >
      <div className="top-form">
        {fields.map((field, key) => {
          if (field.type === "datepicker")
            return (
              <div className="row item-group-info" key={key}>
                <div className="title col-3">{field.title}</div>
                <div className="value col-9 col-md-4">
                  {[1].includes(Number(props.userAdmin.role)) ? 
                    <DatePicker
                      onChange={onChangeDateBooking}
                      className="custom-date-picker"
                      oneTap
                      format={"dd-MM-yyyy HH:mm"}
                      placement={"bottomStart"}
                      defaultValue={orderData.id !== '' ? new Date(orderData.order_at) : new Date()}
                      size="sm"
                    />
                  : 
                    <DatePicker
                      disabledDate={date => isBefore(date, subDays(new Date(), 4))} 
                      onChange={onChangeDateBooking}
                      className="custom-date-picker"
                      oneTap
                      format={"dd-MM-yyyy HH:mm"}
                      placement={"bottomStart"}
                      defaultValue={orderData.id !== '' ? new Date(orderData.order_at) : new Date()}
                      size="sm"
                    />
                  }
                  
                </div>
              </div>
            );

          if (field.type === "text")
            return (
              <div className="row item-group-info" key={key}>
                <div className="title col-3">{field.title}</div>
                <div className="value col-9 col-md-4">
                  <input
                    type="text"
                    placeholder={field.placeholder}
                    defaultValue={orderData.id !== '' ? orderData[field.id] : null}
                    onChange={(e) => dataHandle(e.target.value, field.id)}
                  />
                </div>
              </div>
            );

          if (field.type === "hr") return <hr key={key} />;

          if (field.type === "label")
            return (
              <div className="row item-group-info" key={key}>
                <label className="col-12 control-label text-primary">
                  {field.title}
                </label>
              </div>
            );

          if (field.type === "textarea")
            return (
              <div className="row item-group-info" key={key}>
                <div className="title col-3">{field.title}</div>
                <div className="value col-9 col-md-8">
                  <textarea
                    defaultValue={orderData.id !== '' ? orderData.description : null}
                    placeholder={field.placeholder}
                    onChange={(e) => dataHandle(e.target.value, field.id)}
                    type="text"
                  />
                </div>
              </div>
            );
          if (field.type === "customer")
            return (
              <Spin spinning={spinLoadCustomer} key={key}>
                <div className="information-customer">
                  <div className="main-info">
                    <div className="row item-group-info">
                      <div className="title col-3">Tìm khách hàng</div>
                      <div className="value col-9 col-md-8">
                        <input
                          placeholder="Tìm khách hàng theo tên hoặc số điện thoại"
                          type="text"
                          onChange={searchCustomer}
                        />
                        {statusSearchCustomer === true ? (
                          <div className="result-search">
                            {dataSearchCustomer.length === 0 ? (
                              <div className="empty-customer">
                                Không tìm thấy dữ liệu phù hợp
                              </div>
                            ) : (
                              <div className="items-customer">
                                {dataSearchCustomer.map((data, key) => (
                                  <div
                                    className="item"
                                    key={key}
                                    onClick={() => {
                                      getDetailCustomer(data);
                                    }}
                                  >
                                    <div className="avatar">
                                      {data.avata_url ?
                                        <img src={data.avata_url} alt="" />
                                        : <Avatar name={data.full_name} size="46" textSizeRatio={1.75} round="50%" />}
                                    </div>
                                    <div className="info">
                                      <div className="name">
                                        {data.full_name}
                                        {data?.identifier === 1 ? <span className="identifier-customer"><img src="/icons/verified.png" /></span> : ""}
                                      </div>
                                      {data.deposit_money > 0 ?
                                        <div className="deposit">Tài khoản: {convert.convertCurrency(data.deposit_money)}</div>
                                        : null}
                                      <div className="phone">{data.mobile}</div>
                                      <div className="address">
                                        {data.district} - {data.city}
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        ) : (
                          <div key={key}></div>
                        )}
                      </div>
                    </div>
                    <div className="row item-group-info custom-col">
                      <div className="title col-3">Khách hàng</div>
                      {(orderData.status <= "2") ? (
                          <div className="value col-9 col-md-8">
                            <div className="row">
                              <div className="col-12 col-md-3">
                                <Select
                                  className="select"
                                  isSearchable={false}
                                  styles={props.customStylesOption}
                                  defaultValue={
                                    orderData.customers.gender
                                      ? props.optionsGender[orderData.customers.gender - 1]
                                      : props.optionsGender[0]
                                  }
                                  onChange={(e) =>
                                    dataHandle(e.value, "gender", "customer")
                                  }
                                  options={props.optionsGender}
                                />
                              </div>
                              <div className="col-12 col-md-4">
                                <input
                                  defaultValue={
                                    orderData.customers.full_name
                                      ? orderData.customers.full_name
                                      : ""
                                  }
                                  placeholder="Họ và tên"
                                  className="required"
                                  type="text"
                                  onChange={(e) =>
                                    dataHandle(e.target.value, "full_name", "customer")
                                  }
                                />
                              </div>
                              <div className="col-12 col-md-5">
                                { orderData.customers.mobile ? 
                                  <input
                                    defaultValue={
                                      orderData.customers.mobile
                                        ? orderData.customers.mobile
                                        : ""
                                    }
                                    onChange={(e) =>
                                      changeMobile(e.target.value)
                                    }
                                    disabled
                                    placeholder="Điện thoại"
                                    type="text"
                                  />
                                :<input
                                defaultValue={
                                  orderData.customers.mobile
                                    ? orderData.customers.mobile
                                    : ""
                                }
                                onChange={(e) =>
                                  changeMobile(e.target.value)
                                }
                                placeholder="Điện thoại"
                                type="text"
                              />}
                              </div>
                            </div>
                          </div>
                      ) : (
                        <div className="old-customer col-9 col-md-8">
                          <div className="info-customer">
                            <span className="gender">
                              {orderData.customers.gender === 1 ? "Anh" : "Chị"}
                            </span>
                            <span className="name">{orderData.customers.full_name} {orderData.customers?.identifier === 1 ? <span className="identifier-customer"><img src="/icons/verified.png" /></span> : ""}</span>
                            <span
                              className="button-edit"
                              onClick={() => changeStatusCustomer('2')}
                            >
                              <i className="fas fa-pencil-alt"></i> Sửa
                            </span>
                            <span
                              className="button-create-new"
                              onClick={() => changeStatusCustomer('1')}
                            >
                              <i className="fas fa-user-plus"></i>Tạo khách mới
                            </span>
                            <div className="phone">
                              <i className="fas fa-phone-square"></i>
                              {orderData.customers.mobile}
                            </div>
                            <div className="address">
                              <i className="fas fa-map-marker-alt"></i>
                              {orderData.customers.district} - {orderData.customers.city}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    {(orderData.status <= "2") ? (
                      <div className="row item-group-info custom-col">
                        <div className="title col-3">Địa chỉ</div>
                        <div className="value col-9 col-md-8">
                          <div className="row">
                            <div className="col-12 col-md-3">
                              <Select className="select"
                                isSearchable={true} 
                                placeholder="Tỉnh/Thành" 
                                onChange={(e) => selectDataHandle(e, "city") }
                                styles={props.customStylesOption}
                                defaultValue={location[props.keyCity]}
                                options={location} />
                            </div>
                            <div className="col-12 col-md-4">
                              <Select className="select"
                                ref={selectDistrictRef} 
                                isSearchable={true} 
                                placeholder="Quận/Huyện" 
                                onChange={(e) => selectDataHandle(e, "district") }
                                styles={props.customStylesOption}
                                defaultValue={props.dataDistrict[props.keyDistrict]} 
                                options={props.dataDistrict} />
                            </div>
                            <div className="col-12 col-md-5">
                              <input
                                defaultValue={
                                  orderData.customers.address
                                    ? orderData.customers.address
                                    : ""
                                }
                                placeholder="Địa chỉ"
                                type="text"
                                onChange={(e) =>
                                  dataHandle(e.target.value, "address", "customer")
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {(orderData.status <= "2") ? (
                      <div className="row item-group-info">
                        <div className="title col-3">Định danh</div>
                        <div className="value col-9 col-md-8">
                          <Checkbox checked={orderData.customers.identifier === 1} onChange={onChangeIdentifier}>Có</Checkbox>
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <hr />
                  <div className="row">
                    <label className="col-12 control-label text-primary">
                      THÔNG TIN BỔ SUNG
                    </label>
                  </div>
                  {(orderData.status <= "2") ? (
                    <div className="more-info">
                      <div className="row item-group-info">
                        <div className="title col-3">Email</div>
                        <div className="value col-9 col-md-8">
                          <input
                            type="text"
                            name="Email"
                            placeholder="email@gmail.com"
                            onChange={(e) =>
                              dataHandle(e.target.value, "email", "customer")
                            }
                            defaultValue={
                              orderData.customers.email
                                ? orderData.customers.email
                                : ""
                            }
                          />
                        </div>
                      </div>
                      <div className="row item-group-info custom-col">
                        <div className="title col-3">Sinh nhật</div>
                        <div className="value col-9 col-md-8">
                          <div className="row">
                            <div className="col-12 col-md-4">
                              <Select
                                className="select"
                                placeholder="Ngày"
                                defaultValue={
                                  orderData.customers.birthday
                                    ? optionsDays[
                                    orderData.customers.day_birthday - 1
                                    ]
                                    : ''
                                }
                                onChange={(e) =>
                                  dataHandle(e.value, "day_birthday", "customer")
                                }
                                styles={customStylesSelect}
                                options={optionsDays}
                              />
                            </div>
                            <div className="col-12 col-md-4">
                              <Select
                                className="select"
                                placeholder="Tháng"
                                defaultValue={
                                  orderData.customers.birthday
                                    ? optionsMonths[
                                    orderData.customers.month_birthday - 1
                                    ]
                                    : ''
                                }
                                onChange={(e) =>
                                  dataHandle(e.value, "month_birthday", "customer")
                                }
                                styles={customStylesSelect}
                                options={optionsMonths}
                              />
                            </div>
                            <div className="col-12 col-md-4">
                              <input
                                defaultValue={
                                  orderData.customers.birthday
                                    ? orderData.customers.year_birthday
                                    : ""
                                }
                                placeholder="Năm"
                                onChange={(e) =>
                                  dataHandle(e.target.value, "year_birthday", "customer")
                                }
                                type="text"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row item-group-info">
                        <div className="title col-3">FaceBook</div>
                        <div className="value col-9 col-md-8">
                          <input
                            type="text"
                            name="facebook_id"
                            placeholder="Facebook Id"
                            onChange={(e) =>
                              dataHandle(e.target.value, "facebook_id", "customer")
                            }
                            defaultValue={
                              orderData.customers.facebook_id
                                ? orderData.customers.facebook_id
                                : ""
                            }
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="more-info old-customer">
                      <div className="row item-group-info">
                        <div className="title col-3">Email</div>
                        <div className="value col-9 col-md-8">{orderData.customers.email}</div>
                      </div>
                      <div className="row item-group-info custom-col">
                        <div className="title col-3">Sinh nhật</div>
                        <div className="value col-9 col-md-8">
                          {orderData.customers.birthday
                            ? orderData.customers.birthday.split("-").reverse().join("-")
                            : ""}
                        </div>
                      </div>
                      <div className="row item-group-info">
                        <div className="title col-3">FaceBook</div>
                        <div className="value col-9 col-md-8">{orderData.customers.facebook_id}</div>
                      </div>
                    </div>
                  )}
                </div>
              </Spin>
            )
        })}
      </div>
      <div className="button-container">
        <button
          className="button-cent style3"
          name="currentPage"
          value="index"
          onClick={(e) => props.currentPos ? saveDataCustomer() : props.showHideForm(e, true)}
        >
          <span>
            <i className="fas fa-trash-alt"></i>
          </span>
          Đóng
        </button>
        <button
          name="currentPage"
          className={orderData.customers.full_name ? "button-cent" : "button-cent disable"}
          onClick={orderData.customers.full_name ? saveDataCustomer : () => { }}
          style={{marginLeft: "10px"}}
        >
          <span>
            <i className="fas fa-user-friends"></i>
          </span>
          Lưu thông tin
        </button>
      </div>
    </div>
  );
};

export default PosOrderInformation;
